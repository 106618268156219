
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/analytics';
import { post } from './api';

const datasets = {}

export const initFirebase = (config, options) => {
    if (firebase.apps.length > 0) return;
    firebase.initializeApp(config);
    firebase.analytics();
}

export const signInFirebase = (firebaseToken, userInfo, callback) => {
    if (firebase.apps > 0) return;
    firebase.auth().signInWithCustomToken(firebaseToken)
        .then((userCredential) => {
            const {country, industry } = userInfo;
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
            firebase.analytics().setUserProperties({country, industry});
            callback();
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log({ errorCode, errorMessage });
        });
}

export const initFCM = (hashkey) => {
    const messaging = firebase.messaging();
    messaging
        .getToken({ vapidKey: 'BExl48JDdBu__rYQRdrAMdu7tdA5VZc4O4jKETVpyvJ8GNsr7rbNP0wMEWYTR8sV3ybniWiQaejXajlfKlTb1Es' })
        .then((token) => {
            if (token) {
                post("set-notification-token", { token, hashkey });
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((error) => {
            console.log("FCM Error ", error);
        });
}

export const registerFirestoreDataset = (formula, callback) => {
    const { dataset, address, orderBy, oneDoc } = formula;

    if (datasets[dataset]) {
        if (datasets[dataset].address === address) return datasets[dataset].isEmpty;
        datasets[dataset].unsubscribe();
    }
    const db = firebase.firestore();
    const { hostname } = window.location;

    if (oneDoc) {
        let ref = db.doc(`projects/${hostname}/${address}`);
        const unsubscribe = ref.onSnapshot((doc) => {
            datasets[dataset].isEmpty = false;
            callback([{ id: doc.id, ...doc.data() }]);
        })
        datasets[dataset] = { address, isEmpty: true, unsubscribe }
    }
    else {
        let ref = db.collection(`projects/${hostname}/${address}`);

        if (orderBy) {
            const orderFields = orderBy.split(" ");
            orderFields.filter(f => f.length > 0).forEach((f => {
                if (f[0] === "-") ref = ref.orderBy(f.substring(1), 'desc')
                else ref = ref.orderBy(f)
            }))
        }

        const unsubscribe = ref.onSnapshot((querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            datasets[dataset].isEmpty = false;
            callback(data);
        })
        datasets[dataset] = { address, isEmpty: true, unsubscribe }
    }
    return datasets[dataset].isEmpty;
}

export const registerFbState = (dataset, address, callback) => {
    if (firebase.apps.length === 0)return;

    if (datasets[dataset])return;

    const db = firebase.firestore();

    let ref = db.doc(address);
    ref.onSnapshot((doc) => {
        callback(doc.data());
    })
    datasets[dataset] = { address }
}

export const loadFirebaseForPosidonia = (userCode, setState, setStateObject, getSelectedChatAtd) => {
    const chatRef = firebase.database().ref(`chat/${userCode}`);

    chatRef.on('value', (snapshot) => {
        const chat = Object.entries(snapshot.val() || {}).map(([key, val]) => ({ key, ...val }));
        const chatNewNotif = chat
            .filter(c => c.new_msg && c.from !== getSelectedChatAtd())
            .reduce((prev, cur) => (
                {
                    ...prev,
                    all: prev.all + 1,
                    [cur.from]: (prev[cur.from] || 0) + 1
                }), { all: 0 });

        const chatLastMsg = chat
        .reduce((prev, cur) => (
            {
                ...prev,
                [cur.from]: Math.max((prev[cur.from] || 0), cur.ts)
            }), {});
    
        setStateObject("ozState", { chatNewNotif, chatLastMsg })        
        setState({ chat });
    }, (error) => {
        console.log("Error ", error);
    });

    const userRef = firebase.database().ref('users');
    userRef.on('value', (snapshot) => {
        const chatUsers = Object.values(snapshot.val());
        setStateObject("pageDatasets", { chatUsers });
    });

    const contactRef = firebase.database().ref(`contacts/${userCode}`);
    contactRef.on('value', (snapshot) => {
        const myContacts = {};
        snapshot.forEach(n => {
            myContacts[n.key] = true;
        })
        setStateObject("pageDatasets", { myContacts });
    });

    const videoCallRef = firebase.database().ref(`videoCalls/${userCode}`);
    videoCallRef.on('value', (snapshot) => {
        const videoCall = snapshot.val();
        setStateObject("pageDatasets", { videoCall });
    });
}

export const requestNotificationPermission = (hashkey) => {
    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
    }
    else if (Notification.permission === "granted") {
        initFCM(hashkey);
    }
    else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
            if (permission === "granted") {
                initFCM(hashkey);
            }
        });
    }
}
