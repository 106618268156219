import React, { useState } from "react";
import { Container, Paper, Grid, Stepper, Step, StepButton, Typography, StepContent, Button, TextField, Box, LinearProgress, CircularProgress, Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useParams } from "react-router-dom";
import { getWizardData } from "./utils";

export default function ServerAdminWizard() {
    const [props, setProps] = useState({});
    const [logs, setLogs] = useState(null);

    const { code } = useParams();
    const { title, wizardData } = getWizardData(code, props, (key, val) => setProps(prevProps => ({ ...prevProps, [key]: val })), setLogs);
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);

    const clickBtn = async (prc) => {
        setLoading(true);
        const moveToNext = await prc();
        if (moveToNext) setActiveStep(activeStep + 1);
        setLoading(false);
    }
    return <Container>
        <Paper style={{ margin: "30px auto", maxWidth: "600px", minHeight: "100px", padding: "20px" }}>
            <Grid container>
                <Grid item xs={6} style={{ textAlign: "left", padding: "20px" }}>
                    <Typography variant="h5">{title}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                    <img src="/img/onezero.svg" alt="" style={{ maxWidth: "200px", width: "100%", height: "100%", marginBottom: "10px" }} />
                </Grid>
            </Grid>
            <Box textAlign="right">
                <TextField value={props.psw || ""} onChange={e => setProps({ ...props, psw: e.target.value })} label="Password" type="password" />
            </Box>
            {props.psw &&
                <Stepper activeStep={activeStep} orientation="vertical" style={{ textAlign: "left" }}>
                    {wizardData.map((step, stepIdx) => (
                        <Step key={stepIdx}>
                            <StepButton disabled={false} onClick={() => setActiveStep(stepIdx)}>
                                {step.title}
                            </StepButton>
                            <StepContent>
                                <p>{step.descr}</p>
                                {
                                    step.button && (
                                        loading ?
                                            (
                                                <LinearProgress />
                                            )
                                            : (
                                                <Button {...(step.link ? { component: "a", target: "_blank", href: step.link } : {})} variant="contained" onClick={() => clickBtn(step.onClick)} color="primary">
                                                    {step.button}
                                                </Button>)
                                    )
                                }
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            }
        </Paper>

        <Dialog open={Boolean(logs)}>
            <DialogTitle>
                Server Logs
                <Box position="absolute" right="8px" top="8px" >
                    {loading ?
                        <CircularProgress size={30} style={{margin:10}}/>
                        :
                        <IconButton
                            onClick={() => setLogs(null)}
                            style={{
                                color: "#888"
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    }
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <pre>
                    {logs}
                </pre>
            </DialogContent>

        </Dialog>
    </Container>;
}