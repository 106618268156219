import React from "react";
import { TextField } from "@material-ui/core";
import { post } from "../../lib/api";

async function execUpdate(props, setLogs) {
    let errorMsg = "";
    if (!props.ip) errorMsg += "Invalid Domain or IP Address.\n";
    if (errorMsg) {
        alert(errorMsg);
        return false;
    }
    let allLogs = "";
    for(let step = 1; step < 6; step++){
        props.step = step;
        try {
            const res = await post(`server/hetzner/gitpull`, props);
            allLogs += res.logs;
            setLogs(allLogs);
        }
        catch (e) {
            alert(e);
            return false;
        }    
    }

    return true;
}

export default function updateApp(props, setProp, setLogs) {
    const title = "Update Application (git)"
    const wizardData = [
        {
            title: "Update Application", descr: (
                <TextField value={props.ip || ""} onChange={(e) => setProp("ip", e.target.value)} label="Domain or IP Address" fullWidth />
            ), button: "EXECUTE", onClick: () => execUpdate(props, setLogs)
        }
    ]

    return { title, wizardData };
}
