import React from 'react';
import OzCountDigit from './OzCountDigit';
import "./OzCountDown.css";

export default function OzCountBox({ dtPart, val }) {
    const style = {
        left: `${26 * dtPart}%`
    }
    const title = ["ΗΜΕΡΕΣ", "ΩΡΕΣ", "ΛΕΠΤΑ", "ΔΕΥΤΕΡΟΛΕΠΤΑ"][dtPart]
    const val2 = val % 10;
    const val1 = (val - val2) / 10;

    return <div className="oz-countdown-box" style={style}>
        <div className="oz-countdown-boxtitle">
            <svg
                style={{ position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px' }}
                width="100%"
                height="100%"
                viewBox="0 0 90 40"
            >
                <text className="oz-countdown-title-text" x="45" y="10" dominantBaseline="middle" textAnchor="middle" fontSize="18" >                
                {title}
                </text>
            </svg>

        </div>
        <OzCountDigit pos={0} val={val1} />
        <OzCountDigit pos={1} val={val2} />
    </div>;
}