import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { post } from "../../lib/api";

async function createServer(props, setProp) {
    let errorMsg = "";
    if (!props.serverName) errorMsg += "Invalid Server Name.\n";
    if (errorMsg) {
        alert(errorMsg);
        return false;
    }
    try {
        const res = await post("server/hetzner/create", props);
        setProp("ip", res.ip);
        setProp("initPsw", res.root_password);
    }
    catch (e) {
        alert(e);
        return false;
    }
    window.open("https://console.hetzner.cloud/projects/1097978/servers", "_blank")
    return true;
}

async function setDomain(props, setLogs) {
    let errorMsg = "";
    if (!props.ip) errorMsg += "Invalid IP Address.\n";
    if (!props.psw) errorMsg += "Invalid password.\n";
    if (!props.domain) errorMsg += "Invalid Domain or Subdomain.\n";
    if (errorMsg) {
        alert(errorMsg);
        return false;
    }
    try {
        const res = await post("server/hetzner/setup", props);
        setLogs(res.logs);
    }
    catch (e) {
        alert(e);
        return false;
    }

    return true;
}

export default function addHetznerServer(props, setProp, setLogs) {
    const title = "Add Server on Hetzner"
    const wizardData = [
        {
            title: "Create Server",
            descr: (
                <>
                    <TextField value={props.serverName || ""} onChange={(e) => setProp("serverName", e.target.value)} label="Server Name" fullWidth InputProps={{
                        startAdornment: <InputAdornment position="start">oz-</InputAdornment>,
                    }} />
                </>
            ),
            button: "CREATE SERVER",
            onClick: e => createServer(props, setProp)
        },
        { title: "DNS Records", descr: "Set the DNS records", button: "GO TO DNS CONSOLE", link: "https://dns.hetzner.com/", onClick: () => true },
        {
            title: "Server Setup", descr: (
                <>
                    <TextField value={props.ip || ""} onChange={(e) => setProp("ip", e.target.value)} label="IP Address" fullWidth />
                    <TextField value={props.initPsw || ""} onChange={(e) => setProp("initPsw", e.target.value)} label="Initial Password" fullWidth />
                    <TextField value={props.domain || ""} onChange={(e) => setProp("domain", e.target.value)} label="Domain or Subdomain" fullWidth />
                </>
            ), button: "EXECUTE", onClick: () => setDomain(props, setLogs)
        },
        { title: "Go To OneZero Admin", descr: "Open OneZero Admin Console", button: "OPEN ", link: `https://${props.domain}/ozadmin`, onClick: () => false },
    ]

    return { title, wizardData };
}
