import React from "react";
import { connect } from "react-redux";

import { setState } from "../../../store/actions";
import { post } from "../../../lib/api";
import {
  Paper,
  Grid,
  Switch
} from "@material-ui/core";

class PlayConsoleSwitchPropWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
  }

  setProp(newVal) {
    const { idx } = this.props;
    const structure = [...this.props.consoleData.structure];
    structure[idx] = { ...structure[idx], data: newVal };
    this.props.setState({ consoleData: { ...this.props.consoleData, structure } })
    post("set-console-data", {structure: structure[idx]});
  }

  render() {
    const { row, idx } = this.props;
    return (
      <Paper key={idx} style={{ flexGrow: 1, margin: "20px", padding: "20px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>            
            <h2 style={{ margin: "10px" }}>{row.descr}: 
            <Switch
        checked={row.data === "1"}
        onChange={(event) => this.setProp(event.target.checked ? "1" : "")}
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />            
            </h2>
            
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

PlayConsoleSwitchPropWidget.defaultProps = {
  consoleData: {}
};

const mapStateToProps = state => {
  return Object.keys(PlayConsoleSwitchPropWidget.defaultProps).reduce(
    (obj, key) => ({ ...obj, [key]: state[key] }),
    {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayConsoleSwitchPropWidget);
