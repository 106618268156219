import React from "react";
import { connect } from "react-redux";

import { setState } from "../../store/actions";
import { loadData } from "../../lib/api";
import DataTable from "../DataTable";
import { IconButton } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';

class ActionBatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() {
    loadData("data/actionBatches", "actionBatchesList");
  }

  getColumns() {
    const modulesList = this.props.modulesList?.filter(m => m.module_type === "ppl").map(m => ({ id: m.id, descr: m.name }))
    return [
      { id: "module", label: "Module", align: "left", minWidth: "100px", type: "list", list: modulesList },
      { id: "action_code", label: "Action Code", align: "left", minWidth: "100px" },
      { id: "action_descr", label: "Action Description", align: "left", type: "custom", readonly: true },
      { id: "comments", label: "Comments", minWidth: "100px" },
      { id: "status", label: "Status", readonly: true },
      { id: "settings", label: "", type: "custom", readonly: true },

    ];
  }

  getCustomField(row, column) {
    if (column.id === "settings")
      return (
        <IconButton component="a"
          href={`/ozadmin/action-batches/${row["id"]}`}
        >
          <SettingsIcon />
        </IconButton>
      );

    if (column.id === "action_descr") {
      const module = this.props.modulesList.find(m => m.id === row.module);
      return module?.setup.actions?.find(a => a.code === row.action_code)?.title || "(Unknown Action)";
    }

    return null;
  }

  openSettings(row) {
    this.props.setState({ editSettingsRow: row })
  }

  render() {
    const columns = this.getColumns();

    return (
      <>
        <DataTable
          title="Action Batches"
          singular="Batch"
          columns={columns}
          list={this.props.actionBatchesList}
          getCustomField={(row, column) => this.getCustomField(row, column)}
          listName="actionBatchesList"
          crud="actionBatches"
          searchFields={["comments"]}
          deletable

        />
      </>
    );
  }
}
ActionBatches.defaultProps = {
  actionBatchesList: [],
  modulesList: []
};

const mapStateToProps = state => {
  return {
    actionBatchesList: state.actionBatchesList,
    modulesList: state.modulesList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionBatches);
