import React, { useCallback, useEffect, useRef, useState } from 'react';

export default function TwilioVideoChatCamera({ style, participant, muted }) {

    const videoRef = useRef();
    const audioRef = useRef();
    const [videoTrack, setVideoTrack] = useState(null);
    const [audioTrack, setAudioTrack] = useState(null);

    const attachTrack = useCallback((track) => {
        if (!track) return;
        if (track.kind === "video") {
            track.attach(videoRef.current);
            setVideoTrack(track);
        }
        if (track.kind === "audio") {
            track.attach(audioRef.current);
            setAudioTrack(track);
        }

    },[]);

    useEffect(() => {
        const attachTracks = () => {
            const tracks = participant?.tracks;
            if (!tracks) return;
            tracks.forEach(t => attachTrack(t?.track))
        }

        if (participant) {
            attachTracks();
            participant.on("trackSubscribed", t => attachTrack(t));
        }
    }, [participant, attachTrack])


    const disconnect = useCallback(() => {
        if (videoTrack) videoTrack.stop();
        if (audioTrack) audioTrack.stop();
    }, [videoTrack, audioTrack]);

    useEffect(() => {
        return (disconnect);
    }, []);

    return <div className="participant" style={{ position: "relative", overflow: "hidden", ...style }}>
        <div style={{ background: "black", color: "white", paddingBottom: "75%" }}></div>
        <video style={{ position: "absolute", top: "0px", left: "0px", width: "100%" }} ref={videoRef} autoPlay={true} />
        <audio ref={audioRef} autoPlay={true} muted={muted} />
    </div>
}