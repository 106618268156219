
import React, { useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import { initFirebase } from '../../lib/firebase';

export default function OzFileUpload({ cmpProps, detailCmp }) {
    let { firebaseAddress, callbackSetState, sizeLimit } = cmpProps;
    const inputFile = useRef(null)
    const handleClick = () => {
        inputFile.current.click();
    };

    const onChangeFile = (event) => {
        const file = event.target.files[0];
        if(!file)return;
        if(sizeLimit){
            var filesize = ((file.size/1024)/1024); // KB
            if(filesize > Number(sizeLimit)){
                alert(`File is too large (>${sizeLimit}MB)`);
                return;
            }
        }
        
        
        initFirebase(window.ozFirebaseSettings);
        event.stopPropagation();
        event.preventDefault();
        const storage = firebase.storage();
        firebaseAddress = firebaseAddress.replace("{ts}", Math.floor(Date.now() / 1000));
        firebaseAddress = firebaseAddress.replace("{filename}", file.name);
        storage.ref(firebaseAddress).put(file).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((downloadURL) => {
                if (callbackSetState) callbackSetState(downloadURL);
            });
        });
    }

    return <>
        <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={onChangeFile} />
        <div onClick={handleClick}>
            {detailCmp}
        </div>
    </>

}