import React, { useState } from "react";
import { Grid, Button, TextField, FormControlLabel, Switch } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setState } from "../../store/actions";
import { post } from "../../lib/api";
export default function SetupEmail() {
    const dispatch = useDispatch();
    const initSetupData = {
        emailSettings: {
            tls: false,
            host: "",
            port: "",
            user: "",
            password: ""
        }
    }
    const setupData = useSelector(({ setupData }) => setupData) || {};
    const { emailSettings: { tls, host, port, user, password } } = {...initSetupData, ...setupData};
    const [saved, setSaved] = useState(false);
    const setValue = (field, value) => {
        const newSetupData = {
            ...setupData,
            emailSettings: {
                ...setupData.emailSettings,
                [field]: value
            }
        }
        dispatch(setState({ setupData: newSetupData }));
        setSaved(false);
    }

    const handleSave = () => {
        const {emailSettings} = setupData;
        post("setup/", {emailSettings})
            .then(json => {
                setSaved(true);
            });
    }

    return (
        <Grid container spacing={2} style={{maxWidth:"700px"}}>
            <Grid item xs={8}>
                <TextField value={host} onChange={(e) => { setValue("host", e.target.value) }} label="Host" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={2}>
                <TextField value={port} onChange={(e) => { setValue("port", e.target.value) }} label="Port" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={2}>
                <FormControlLabel control={<Switch checked={tls} onChange={(e) => { setValue("tls", e.target.checked) }} color="primary" />} label="TLS" />
            </Grid>
            <Grid item xs={4}>
                <TextField value={user} onChange={(e) => { setValue("user", e.target.value) }} label="User" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={4}>
                <TextField value={password} onChange={(e) => { setValue("password", e.target.value) }} type="password" label="Password" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs style={{ textAlign: "right" }}>
                <Button onClick={handleSave} size="large" variant="contained" color="primary">Update</Button>
            </Grid>
            {saved &&
                <Grid item xs={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                    Update Completed Succesfully
                </Grid>
            }
        </Grid>);
}