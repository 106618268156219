import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { post } from '../../lib/api';
import Video from 'twilio-video';
import Participant from "./Participant"
import { Grid } from '@material-ui/core';

export default function TwilioMeeting(props) {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  const participantConnected = participant => {
    setParticipants(prevParticipants => [...prevParticipants, participant]);
  };
  const participantDisconnected = participant => {
    setParticipants(prevParticipants =>
      prevParticipants.filter(p => p !== participant)
    );
  };

  const connect = useCallback((hashkey) => {
    post("twilio-credentials", { hashkey }).then(
      twilioCred => {
        Video.connect(twilioCred.token, {
          name: twilioCred.roomName
        }).then(room => {
          setRoom(room);
          setParticipants([]);
          room.on('participantConnected', participantConnected);
          room.on('participantDisconnected', participantDisconnected);
          room.participants.forEach(participantConnected);
        });

        console.log(twilioCred)
      });
  },[]);

  const handleLogout = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      return null;
    });
  }, []);

  const hashkey = useSelector(({ userInfo }) => userInfo.hashkey) || null;
  useEffect(() => {
    connect(hashkey);
    return (handleLogout)
  }, [hashkey, connect, handleLogout])

  useEffect(() => {
    if (room) {
      const tidyUp = (event) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, handleLogout]);


  if (!room) return "Connecting...";
  const size = participants.length > 1 ? 4 : 6;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={size}>
        <Participant participant={room.localParticipant} myself room={room} />
      </Grid>
      {participants.map((p, pidx) =>
        <Grid item key={pidx} xs={12} md={size}>
          <Participant participant={p} />
        </Grid>
      )}
    </Grid>);
}

