import React from "react";
import { connect } from "react-redux";
import withRouter from  "../lib/withRouter";

import { setState } from "../store/actions";
import AdminDrawer from "./AdminDrawer";
import Pages from "./pages/Pages";
import Modules from "./modules/Modules";
import ModulePage from "./modules/ModulePage";
import Emailing from "./emailing/Emailing";
import EmailStructure from "./emailing/EmailStructure";
import Images from "./images/Images";
import PlayConsoles from "./playConsoles/PlayConsoles";
import ActionBatches from "./actionBatches/ActionBatches";
import ActionBatchProcess from "./actionBatches/ActionBatchProcess";
import Setup from "./setup/Setup";
import Statistics from "./statistics/Statistics"

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() { }

  getPageContent(page, id) {
    // if (page === "speakers") return <Speakers />;
    // if (page === "program") return <Program />;
    // if (page === "companies") return <Companies />;
    // if (page === "attendees") return <Attendees />;
    // if (page === "polls") return <Polls />;
    // if (page === "questions") return <Questions />;
    // if (page === "liveconsole") return <LiveConsole />;
    // if (page === "banners") return <Banners />;
    if (page === "pages") return <Pages />;
    if (page === "emailing" && !id) return <Emailing />;
    if (page === "emailing" && id) return <EmailStructure id={id} />;
    if (page === "images") return <Images />;
    if (page === "modules") return <Modules />;
    if (page === "modulepage") return <ModulePage module={id} />;
    if (page === "play-consoles") return <PlayConsoles />;
    if (page === "action-batches" && !id) return <ActionBatches />;
    if (page === "action-batches" && id) return <ActionBatchProcess id={id} />;
    if (page === "setup") return <Setup />;
    if (page === "statistics") return <Statistics />;

    return (
      <>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ textAlign: "center" }}><img src="/files/logo.svg" style={{ maxWidth: "500px" }} alt="" /></div>
        <div style={{ flexGrow: 1 }}></div>
      </>
    );
  }

  render() {
    const page = this.props.params?.page || this.props.page;
    const id = this.props.params?.id || this.props.id;
    return (
      <div className="apg-parent-div" style={{ display: "flex" }}>
        <AdminDrawer page={page} />
        <main style={{ width: `calc(100% - 250px)` }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh"
            }}
          >
            {this.getPageContent(page, id)}
          </div>
        </main>
      </div>
    );
  }
}
Admin.defaultProps = {};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin));
