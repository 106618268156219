import React from "react";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import UndoIcon from '@material-ui/icons/Undo';

import {
    Grid,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    IconButton,
    Divider,
    ListItemText
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../lib/api";
import { setState } from "../../store/actions";
export default function VMixListPending() {
    const dispatch = useDispatch();
    const vmixCalls = useSelector((state) => state.vmixCalls || []);

    const restoreCall = (id) => {
        post("vmix/calls", { action: "update", id, status: "0" }).then(res => {
            dispatch(setState({ vmixCalls: res }))
        });
    }

    return <Grid item xs style={{ height: "100%" }}>
        <Paper key="pending" style={{ height: "100%", overflow: "auto" }}>
            <List>
                {vmixCalls.filter(call => call.status === "2").map((call) =>
                    <React.Fragment key={call.id}>
                        <ListItem >
                            <ListItemIcon>
                                <IconButton
                                    edge="start"
                                    onClick={() => restoreCall(call.id)}
                                >
                                    <UndoIcon />
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText>
                            {`${call.caller.surname} ${call.caller.name}`}
                            </ListItemText>
                        </ListItem>
                        <Divider component="li" />
                    </React.Fragment>
                )}
            </List>
        </Paper>
    </Grid>;
}