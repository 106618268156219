import React from "react";
import { connect } from "react-redux";
import BuildIcon from '@material-ui/icons/Build';

import { setState } from "../../store/actions";
import { loadData } from "../../lib/api";
import DataTable from "../DataTable";
import { IconButton } from "@material-ui/core";

class Emailing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() {
    loadData("data/emails", "emailsList");
  }

  getColumns() {
    return [
      { id: "code", label: "Code", align: "left", minWidth: "100px" },
      { id: "title", label: "Title", align: "left", minWidth: "100px" },
      { id: "from_name", label: "From (name)", align: "left", minWidth: "100px" },
      { id: "receivers", label: "Receivers", align: "left", minWidth: "100px" },
      { id: "attachment", label: "Attachment", align: "left", minWidth: "100px" },
      // { id: "template", label: "Template", align: "left", minWidth: "100px" },
      // { id: "txt1", label: "Text1", align: "left", minWidth: "100px" },
      // { id: "txt2", label: "Text2", align: "left", minWidth: "100px" },
      // { id: "txt3", label: "Text3", align: "left", minWidth: "100px" },
      // { id: "txt4", label: "Text4", align: "left", minWidth: "100px" },
      { id: "structure", label: "", type: "custom", readonly: true }
    ];
  }

  getCustomField(row, column) {
    if (column.id === "structure")
      return (
        <IconButton
          component="a"
          href={`/ozadmin/emailing/${row["id"]}`}
        >
          <BuildIcon />
        </IconButton>
      );

    return null;
  }

  render() {
    const columns = this.getColumns();

    return (
      <DataTable
        title="Emailing"
        singular="Email"
        columns={columns}
        list={this.props.emailsList}
        getCustomField={(row, column) => this.getCustomField(row, column)}
        listName="emailsList"
        crud="emails"
        searchFields={["code", "template", "title", "from_name", "txt1", "txt2", "txt3", "txt4"]}
        deletable
      />
    );
  }
}
Emailing.defaultProps = {
  emailsList: []
};

const mapStateToProps = state => {
  return {
    emailsList: state.emailsList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Emailing);
