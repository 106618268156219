import React from 'react';
import { InputAdornment, TextField, InputBase } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {countries} from './countryList'

export default function OzInput({ cmpProps, setStateObject, ozState, shrink, fullWidth, disabled, multiline }) {
    const { fieldname, startAdornment, label, variant = "standard", size = "medium", type, emptyValue = "" } = cmpProps;
    const inputProps = {autoComplete: "disabled"};
    const inputLabelProps = {};
    if (startAdornment) inputProps.startAdornment =
        <InputAdornment position="start">{startAdornment}</InputAdornment>

        if (shrink) inputLabelProps.shrink = true;

    const isInputBased = ["filled", "outlined", "standard"].indexOf(variant) < 0;
    const isAutoComplete = ["country", "countryPhoneCode"].indexOf(type) >= 0;
    const value = ozState[fieldname] ?? emptyValue ?? "";

    const onChange = event => setStateObject("ozState", { [fieldname]: event.target.value });

    const inputFieldProps = {
        className: cmpProps.className,
        multiline, fullWidth, disabled,
        ...(isInputBased ? {placeholder: label} : {label, variant, size, InputProps: inputProps, InputLabelProps: inputLabelProps}),
        ...(isAutoComplete ? {} : {value, onChange, type })
    }

    const countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined'
          ? isoCode
              .toUpperCase()
              .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
          : isoCode;
      }


    if(isAutoComplete){
        const getAutoCompleteLabel = (option) => {
            if(!option.phone)return "";
            if(type === "country")return option.label;
            if(type === "countryPhoneCode")return `+${option.phone}`;
        }
    
        const getAutoCompleteValue = () => {
            if(!value)return {};
            if(type === "country")return countries.find(c => c.label  === value);
            if(type === "countryPhoneCode")return countries.find(c =>  `+${c.phone}`  === value);
        }
    
        const autoCompleteOnChange = (event, option) => {
            if(type === "country")setStateObject("ozState", { [fieldname]: option?.label, [`${fieldname}__code`]: (option ? `+${option?.phone}` : null) })
            if(type === "countryPhoneCode")setStateObject("ozState", { [fieldname]: option ? `+${option?.phone}` : null})
        }

        const getOptionTitle = (option) => {
            if(type === "country")return option.label; 
            if(type === "countryPhoneCode")return `+${option?.phone} (${option.label})`
        }        

        return <Autocomplete
            value={getAutoCompleteValue()}
            onChange = {autoCompleteOnChange}
            options = {countries}
            getOptionLabel={getAutoCompleteLabel}
            disableClearable
            autoHighlight
            renderOption={(option) => (
              <React.Fragment>
                <span>{countryToFlag(option.code)}</span>
                 {getOptionTitle(option)}
              </React.Fragment>
            )}
      
            renderInput={(params) => isInputBased ? <TextField  {...inputFieldProps} {...params} inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled', 
              }}/> : <TextField  {...inputFieldProps} {...params} inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled', 
              }} /> }
        />;
                
    }


   if(isInputBased)return  <InputBase {...inputFieldProps} />;
    
   return <TextField {...inputFieldProps} /> 

}