const initialState = {
};
function rootReducer(state = initialState, action) {
    if (action.type === "SET_STATE") {
        state = { ...state, ...action.state }
    }
    if (action.type === "SET_STATE_OBJECT") {
        state = { ...state, [action.objectName]: { ...state[action.objectName], ...action.state } }
    }
    return state;
};
export default rootReducer;