import React from 'react';
import { getOzBoolean } from '../../common/ozCompiler';
import { InputLabel, FormControl, MenuItem, Select } from '@material-ui/core';

export default function OzSelect({ cmpProps, setStateObject, ozState, optionsArray }) {
    let { label, fieldname, className, variant = "standard", size = "normal", optionsVal, optionsDescr, nullDescr, style } = cmpProps;
    const fullWidth = getOzBoolean(cmpProps.fullWidth, ozState);
    const optionsNull = getOzBoolean(cmpProps.optionsNull, ozState);
    const disabled = getOzBoolean(cmpProps.disabled, ozState);

    if (["filled", "outlined", "standard"].indexOf(variant) < 0) variant = "standard";
    const inputProps = { disabled }

    return <FormControl style={style} className={className} variant={variant} fullWidth={fullWidth}>
        {label && <InputLabel>{label}</InputLabel>}
        <Select
            label={label}
            size={size}
            value={ozState[fieldname] || ""}
            inputProps={inputProps}
            onChange={event => setStateObject("ozState", { [fieldname]: event.target.value })}
        >
            {optionsArray.map((op, idx) =>
                <MenuItem key={idx} value={op[optionsVal]}>{op[optionsDescr]}</MenuItem>
            )}
            {optionsNull && <MenuItem value="">{nullDescr}</MenuItem>}
        </Select>
    </FormControl>;
}