import React from "react";
import { connect } from "react-redux";
import firebase from 'firebase/app';
import 'firebase/analytics';
import withRouter from  "../lib/withRouter";

import { setState, setStateObject } from "../store/actions";
import { post } from "../lib/api";
import PageCompiler from "../common/PageCompiler";
import TwilioVideoChat from "../pageComponents/TwilioVideoChat/TwilioVideoChat";
import { initFirebase, signInFirebase, loadFirebaseForPosidonia, requestNotificationPermission } from "../lib/firebase";
import { parseOzText } from "../common/ozCompiler";

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.wsTimer = Math.floor(Date.now() / 1000);
        this.isAdminPage = false;
    }

    componentDidMount() {
        this.loadPage();
        // this.initWebsocket();
        // setInterval(() => this.checkWebSocket(), 5000);
        // const hashkey = this.getHashKey();
        // if (hashkey) {
        //     this.checkPoke(hashkey);
        //     setInterval(() => this.checkPoke(hashkey), 300000);
        // }
    }

    loadPage() {
        const { page: url = "/" } = this.props.params;
        const hashkey = this.getHashKey();
        const keys = document.location.search ? { id: document.location.search.replace("?", "") } : {};
        const getPageCall = this.isAdminPage ? "get-page-admin" :"get-page";
        post(getPageCall, { url, hashkey, keys }).then(res => {
            if (res.redirect) {
                if (res.errorMsg) alert(res.errorMsg);
                window.location.href = res.redirect;
            }
            if(res.needsAdminLogin){
                this.isAdminPage = true;
                this.loadPage();
            }
            initFirebase(window.ozFirebaseSettings);
            if (res.firebaseToken) signInFirebase(res.firebaseToken, res.user_info, () => {
                firebase.analytics().logEvent('open_page', { url });

                if (res.plugins && res.plugins["firebase"] === "psd" && hashkey) {
                    loadFirebaseForPosidonia(
                        res.user_info.code,
                        this.props.setState,
                        this.props.setStateObject,
                        () => this.props.ozState.selectedChatAttendee
                    );
                }
                if (hashkey && res.plugins?.notifications) requestNotificationPermission(hashkey);
            })

            if (hashkey && hashkey !== localStorage.getItem('hashkey')) localStorage.setItem('hashkey', hashkey);

            const newState = JSON.parse(parseOzText(res.oz_state || "{}", this.props.ozState, { userInfo: res.user_info }));
            this.props.setState({
                pageStructure: res.structure,
                pageDatasets: { ...this.props.pageDatasets, ...res.datarows },
                detailStructures: { ...this.props.detailStructures, ...res.detailStructures },
                userInfo: res.user_info,
                ozState: { ...this.props.ozState, ...newState }
            });
        });
    }

    getHashKey() {
        let { hashkey } = this.props.params;
        if (!hashkey) hashkey = localStorage.getItem('hashkey');
        return hashkey;
    }

    // checkPoke(hashkey) {
    //     if (!hashkey) return;
    //     const { ozPokeTag } = this.props.ozState;
    //     if (!ozPokeTag) return;
    //     post("live-poke", { hashkey, ozPokeTag });
    // }


    componentDidUpdate(prevProps, prevState) {
    }

    initWebsocket() {
        const path = `${window.location.origin
            .replace("http", "ws")
            }/ws/`;
        this.ws = new WebSocket(path);
        const parentObj = this;

        console.log("open ws!!!");
        this.ws.onmessage = function (msg) {
            console.log(msg.data);
            if (msg.data.startsWith("refreshPlay:")) {
                parentObj.loadPage();
            }
            if (msg.data.startsWith("checkConnection:")) {
                parentObj.wsTimer = Math.floor(Date.now() / 1000);
            }
        };
    }

    checkWebSocket() {
        if (this.ws && this.ws.readyState !== this.ws.OPEN) {
            this.initWebsocket();
        }
        else {
            this.ws.send("");
        }
        if (!this.ws) this.initWebsocket();

        if (this.wsTimer - Math.floor(Date.now() / 1000) > 180000) {
            this.loadPage();
            this.wsTimer = Math.floor(Date.now() / 1000) - 60000;
        }
    }

    render() {
        return (
            <>
                <PageCompiler />
                <TwilioVideoChat />
            </>
        );
    }
}

Page.defaultProps = {
    ozState: {},
    pageDatasets: {},
    detailStructures: {}
};

const mapStateToProps = state => {
    return Object.keys(Page.defaultProps).reduce(
        (obj, key) => ({ ...obj, [key]: state[key] }),
        {}
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state)),
        setStateObject: (object, state) => dispatch(setStateObject(object, state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Page));
