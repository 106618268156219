import React from 'react';
import "./OzCountDown.css";

export default function OzCountDigitBottom({ val, digit }) {

  let posClass = digit >= val ? "" : "up";
  if(digit === 9 && val < 5)posClass = "up"
  
  return <div className={`oz-countdown-digit-bottom ${posClass} d${digit} v${val}`}>
    <div className="oz-countdown-digit-paper bottom" />
    <svg
      style={{ position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px' }}
      width="100%"
      height="100%"
      viewBox="0 0 180 120"
      preserveAspectRatio="none"
    >
      <text className="oz-countdown-digit-text"  x="90" y="20" dominantBaseline="middle" textAnchor="middle" fontSize="200">    {digit}    </text>
    </svg>

  </div>;
}