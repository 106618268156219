import React from "react";
import { connect } from "react-redux";
import withRouter from  "../../lib/withRouter";

import { setState } from "../../store/actions";
import { post } from "../../lib/api";
import {
  Paper,
  Grid,
} from "@material-ui/core";
import PlayConsoleLiveStreamWidget from "./widgets/PlayConsoleLiveStreamWidget";
import PlayConsoleBtnPropWidget from "./widgets/PlayConsoleBtnPropWidget";
import PlayConsoleLinkWidget from "./widgets/PlayConsoleLinkWidget";
import PlayConsoleTxtPropWidget from "./widgets/PlayConsoleTxtPropWidget";
import PlayConsoleSwitchPropWidget from "./widgets/PlayConsoleSwitchPropWidget";
import PlayConsoleModuleWidget from "./widgets/PlayConsoleModuleWidget";

class PlayConsole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
    const { console: url = "/" } = this.props.params;
    post("get-console", { url }).then(res => {
      this.props.setState({
        consoleData: res.console
      });
    });
  }

  getWidget(row, idx) {
    if (row.type === "livestream") return <PlayConsoleLiveStreamWidget key={idx} row={row} idx={idx} />;
    if (row.type === "btnprop") return <PlayConsoleBtnPropWidget key={idx} row={row} idx={idx} />;
    if (row.type === "txtprop") return <PlayConsoleTxtPropWidget key={idx} row={row} idx={idx} />;
    if (row.type === "link") return <PlayConsoleLinkWidget key={idx} row={row} idx={idx} />;
    if (row.type === "switchprop") return <PlayConsoleSwitchPropWidget key={idx} row={row} idx={idx} />;
    if (row.type === "module") return <PlayConsoleModuleWidget key={idx} row={row} idx={idx} />;
    return null;
  }

  render() {
    const { consoleData } = this.props;
    const { title, structure = [] } = consoleData;
    return (
      <>
        <Paper style={{ flexGrow: 1, margin: "20px", padding: "20px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <img src="/img/onezero.svg" alt="" style={{ float: "right", width: "300px" }} />
              <h1 style={{ margin: "10px" }}>{title}</h1>
            </Grid>
          </Grid>
        </Paper>
        {
          structure.map((s, idx) => this.getWidget(s, idx))
        }
      </>
    )
  }
}

PlayConsole.defaultProps = {
  consoleData: {}
};

const mapStateToProps = state => {
  return Object.keys(PlayConsole.defaultProps).reduce(
    (obj, key) => ({ ...obj, [key]: state[key] }),
    {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlayConsole));
