import React from "react";
import { TextField } from "@material-ui/core";
import { post } from "../../lib/api";

async function restart(props, setLogs) {
    let errorMsg = "";
    if (!props.ip) errorMsg += "Invalid Domain or IP Address.\n";
    if (errorMsg) {
        alert(errorMsg);
        return false;
    }
    try {
        const res = await post("server/hetzner/restart", props);
        setLogs(res.logs);
    }
    catch (e) {
        alert(e);
        return false;
    }

    return true;
}

export default function restartServices(props, setProp, setLogs) {
    const title = "Restart Services"
    const wizardData = [
        {
            title: "Restart Services", descr: (
                <TextField value={props.ip || ""} onChange={(e) => setProp("ip", e.target.value)} label="Domain or IP Address" fullWidth />
            ), button: "RESTART", onClick: () => restart(props, setLogs)
        }
    ]

    return { title, wizardData };
}
