import React from "react";
import { Marker } from 'google-maps-react';

export default function (key, cmpProps) {
  return <Marker
    key={key}
    icon={cmpProps.icon}
    title={cmpProps.title}
    name={cmpProps.title}
    position={{ lat: Number(cmpProps.lat), lng: Number(cmpProps.lng) }} />;

}