import React, { useRef } from "react";
import { GoogleApiWrapper, Map } from 'google-maps-react';

const OzMap = (props) => {
  const mapRef = useRef();

  const { cmpProps, detailCmp, google } = props;

  const mapLoaded = (mapProps, map) => {
    if(cmpProps.mapStyles){
      map.setOptions({
        styles: JSON.parse(cmpProps.mapStyles)
      })      
    }
  }

  return <Map ref={mapRef} google={google} zoom={Number(cmpProps.zoom)} onReady={mapLoaded}
    initialCenter={{
      lat: Number(cmpProps.lat),
      lng: Number(cmpProps.lng)
    }}>

    {detailCmp}

  </Map>;


}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAc8BTX-Ss0yH0G1PlG_Vo8m4XxwGOac9A"
})(OzMap);