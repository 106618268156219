import React, {  useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { post } from '../../lib/api';
import TwilioVideoChatCamera from './TwilioVideoChatCamera';
import { useDispatch } from 'react-redux';
import { setState } from "../../store/actions";

export default function TwilioVideoChatPlay({ userInfo, chatInfo, room }) {

    const [participants, setParticipants] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (room) {
            room.on('participantConnected', () => loadParticipants(room));
            loadParticipants(room);
        }
    }, [room])

    function loadParticipants(room) {
        if (!room) return;
        const newParticipants = Array.from(room.participants.values());
        setParticipants(newParticipants);
    }

    const endVideoCall = async () => {
        const { hashkey } = userInfo;
        const receiver = chatInfo.code;
        post("end-video-call", { hashkey, receiver });
        dispatch(setState({acceptedVideoCallContact: null }))
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <TwilioVideoChatCamera participant={participants[0]} />
            <TwilioVideoChatCamera muted participant={room?.localParticipant} style={{ position: "absolute", width: "25%", bottom: "10px", left: "50px", border: "5px solid white" }} />
        </Grid>
        <Grid item xs={9}>
        </Grid>
        <Grid item xs={3}>
            <Button fullWidth onClick={endVideoCall} variant="contained" >END CALL</Button>
        </Grid>
    </Grid>;
}
