import React from "react";
import { connect } from "react-redux";

import { setState } from "../../../store/actions";
import { post } from "../../../lib/api";
import {
  Paper,
  Grid,
  Button,
  TextField
} from "@material-ui/core";

class PlayConsoleTxtPropWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {enableUpdate: false};
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
  }

  setProp(newVal) {
    const { idx } = this.props;
    const structure = [...this.props.consoleData.structure];
    structure[idx] = { ...structure[idx], data: newVal };
    this.props.setState({ consoleData: { ...this.props.consoleData, structure } })
    this.setState({enableUpdate: true});
  }

  saveProp() {
    const { idx } = this.props;
    const structure = [...this.props.consoleData.structure];
    this.setState({enableUpdate: false});
    post("set-console-data", { structure: structure[idx] });
  }

  render() {
    const { row, idx } = this.props;
    return (
      <Paper key={idx} style={{ flexGrow: 1, margin: "20px", padding: "20px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h2 style={{ margin: "10px" }}>{row.descr}:
            </h2>
            <TextField
              onChange={event =>
                this.setProp(event.target.value)
              }
              fullWidth
              value={this.props.row.data}
              autoComplete="off"
            />
            {this.state.enableUpdate &&
              <Button style={{ marginTop: "5px" }} variant="contained" color="primary" onClick={() => this.saveProp()}>
                UPDATE
              </Button>
            }

          </Grid>
        </Grid>
      </Paper>
    )
  }
}

PlayConsoleTxtPropWidget.defaultProps = {
  consoleData: {}
};

const mapStateToProps = state => {
  return Object.keys(PlayConsoleTxtPropWidget.defaultProps).reduce(
    (obj, key) => ({ ...obj, [key]: state[key] }),
    {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayConsoleTxtPropWidget);
