import React, { useCallback, useEffect, useState } from 'react';
import OzCountBox from './OzCountBox';
import "./OzCountDown.css";

export default function OzCountDown({ cmpProps, style }) {
    let { className, utmTimestamp } = cmpProps;

    const getCurrentTimeDiff = useCallback(() => {
        const ts = Number(utmTimestamp) - Math.round(Date.now() / 1000);
        if(ts < 0)return [0, 0, 0, 0];
        const seconds  = ts % 60;
        let restTs =  (ts - seconds) / 60;
        const minutes  = restTs % 60;
        restTs =  (restTs - minutes) / 60;
        const hours  = restTs % 24;
        restTs =  (restTs - hours) / 24;
        const days  = restTs % 365;
        return [days, hours, minutes, seconds];
    }, [utmTimestamp])

    const [timediff, setTimeDiff] = useState(getCurrentTimeDiff())

    useEffect(
        () => {
            const timer = () => {        
                setTimeDiff(getCurrentTimeDiff())
            }
        
            const id = setInterval(timer, 1000);
            return () => clearInterval(id);
        },
        [getCurrentTimeDiff]
    );
    
    return <div style={style} className={`oz-countdown-outer ${className}`} >
        {timediff.map((val, idx) =>
            <OzCountBox key={idx} dtPart={idx} val={val} />
        )}
    </div>;
}