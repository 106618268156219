import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { setState } from "../../store/actions";
import { Dialog, DialogTitle, DialogContent, IconButton, TextField, Button } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { componentStruct, getChildIDs, getHTML, pasteComponent } from "./copypaste";

export default function PageStructureCodeEditor() {
    const dispatch = useDispatch();
    const showCodeEditor = useSelector(({ showCodeEditor }) => showCodeEditor);
    const pageStructure = useSelector(({ pageStructure }) => pageStructure);
    const selectedComponent = useSelector(({ selectedComponent }) => selectedComponent);
    const [codeTxt, setCodeTxt] = useState("");

    useEffect(() => {
        if (showCodeEditor) {
            const cmp = pageStructure.find(p => p.id === selectedComponent);
            const copiedComponent = JSON.stringify(componentStruct(cmp, pageStructure));
            setCodeTxt(copiedComponent);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCodeEditor])

    const doClose = () => {
        dispatch(setState({ showCodeEditor: false }))
    }

    const doUpdate = () => {
        const cmp = pageStructure.find(p => p.id === selectedComponent);

        const newPasteId = selectedComponent;
        const newStructure = JSON.parse(codeTxt);
        const newComponents = pasteComponent(selectedComponent, newStructure, newPasteId);
        const rootComponent = newComponents.shift();
        rootComponent.parent = cmp.parent;
        rootComponent.id = cmp.id;
        const oldIDs = getChildIDs(cmp, pageStructure);
        oldIDs.shift();
        const oldComponents =
            pageStructure.map(
                s => s.id === selectedComponent ? rootComponent : s
            ).filter(s => oldIDs.indexOf(s.id) < 0);

        const newPageStructure = [...oldComponents, ...newComponents];
        dispatch(setState({ pageStructure: newPageStructure, showCodeEditor: false }))
    }

    const doParseHTML = () => {

        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(codeTxt, 'text/html');
        const id = Math.floor(Date.now() / 1000);
        const newComponents = Array.from(htmlDoc.body.childNodes).filter(elm => [1,3].includes(elm.nodeType)).map((child, idx) => getHTML(child, selectedComponent, idx === 0 ? id : null)).flat();

        const newPageStructure = [...pageStructure, ...newComponents];
        dispatch(setState({ pageStructure: newPageStructure, showCodeEditor: false }))

    }


    return <Dialog
        fullWidth
        maxWidth="md"
        open={showCodeEditor || false}
        onClose={doClose}
    >
        <DialogTitle>
            Edit Code
            <IconButton
                style={{
                    position: 'absolute',
                    right: "10px",
                    top: "10px",
                    color: "#888",
                    zIndex: 999
                }}
                onClick={doClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <TextField value={codeTxt} onChange={el => setCodeTxt(el.target.value)} multiline fullWidth variant="outlined" />
            <div style={{ textAlign: "right", marginTop: "20px" }}>
                <Button variant="contained" color="primary" onClick={doUpdate}>UPDATE</Button>
                <Button variant="contained" color="primary" onClick={doParseHTML} style={{ marginLeft: "10px" }}>PARSE HTML</Button>
            </div>

        </DialogContent>
    </Dialog>


}
