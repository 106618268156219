import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog, DialogContent } from "@material-ui/core";
import { useSearchParams } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const colors = [
  '#878BB6',
  '#4ACAB4',
  '#FF8153',
  '#FFEA88',
  '#8BB687',
  '#CAB44A',
  '#8153FF',
  '#EA88FF'
]

export default function StatisticsPlot() {
  const statGrp = useSelector(({ statGrp }) => statGrp);
  const statTags = useSelector(({ statTags }) => statTags);

  const [searchParams, setSearchParams] = useSearchParams();
  const showPlot = Boolean(searchParams.get("plot"));

  const closePlot = () => {
    searchParams.delete("plot");
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={showPlot} onClose={closePlot}>
      <DialogContent style={{ height: "80vh" }}>
        {showPlot && (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={statGrp}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="label" />
              <YAxis />
              <Tooltip />
              <Legend />
              {(statTags || []).map((tag, idx) => (
                <Bar key={tag} dataKey={tag} stackId="a" fill={colors[idx]} />
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}
      </DialogContent>
    </Dialog>
  );
}
