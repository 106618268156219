import { Box, Button, Dialog, DialogActions, DialogTitle, DialogContent, Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { crud, loadData } from "../../lib/api";
import { setState } from "../../store/actions";
import PlayConsoleStructureList from "./PlayConsoleStructureList"

class PlayConsoleStructure extends React.Component {


    doSave() {
        if (this.saving) return;
        this.saving = true;
        let id = this.props.editSettingsRow.id;
        let data = this.props.editSettingsRow;
        crud("playConsoles", id, data)
            .then(json => {
                this.props.setState({ editSettingsRow: null });
                loadData(`data/playConsoles`, "playConsolesList").then(() => {
                    this.saving = false;
                });
            })
            .catch(res => {
                this.saving = false;
            });

    }

    render() {
        const { title } = this.props.editSettingsRow || {};
        return (
            <Dialog fullWidth maxWidth="md" open={this.props.editSettingsRow}>
                <DialogTitle>{title} Setup</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <PlayConsoleStructureList />
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: "25px" }}>
                    <Box style={{ flexGrow: 1 }}></Box>
                    <Button
                        onClick={() => this.props.setState({ editSettingsRow: null })}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => this.doSave()}
                        variant="contained"
                        color="primary"
                    >
                        Save
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
PlayConsoleStructure.defaultProps = {
    playConsolesList: [],
    editSettingsRow: null
};

const mapStateToProps = state => {
    return {
        editSettingsRow: state.editSettingsRow
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayConsoleStructure);
