import React from "react";
import { TextField } from "@material-ui/core";
import { post } from "../../lib/api";

async function setDomain(props, setLogs) {
    let errorMsg = "";
    if (!props.ip) errorMsg += "Invalid IP Address.\n";
    if (!props.psw) errorMsg += "Invalid password.\n";
    if (!props.domain) errorMsg += "Invalid Domain or Subdomain.\n";
    if (errorMsg) {
        alert(errorMsg);
        return false;
    }
    try {
        const res = await post("server/hetzner/setup", props);
        setLogs(res.logs);
    }
    catch (e) {
        alert(e);
        return false;
    }

    return true;
}

export default function setHetznerDomain(props, setProp, setLogs) {
    const title = "Set Domain on Hetzner"
    const wizardData = [
        { title: "DNS Records", descr: "Set the DNS records", button: "GO TO DNS CONSOLE", link: "https://dns.hetzner.com/", onClick: () => true },
        {
            title: "Server Setup", descr: (
                <>
                    <TextField value={props.ip || ""} onChange={(e) => setProp("ip", e.target.value)} label="IP Address" fullWidth />
                    <TextField value={props.domain || ""} onChange={(e) => setProp("domain", e.target.value)} label="Domain or Subdomain" fullWidth />
                </>
            ), button: "EXECUTE", onClick: () => setDomain(props, setLogs)
        },
        { title: "Go To OneZero Admin", descr: "Open OneZero Admin Console", button: "OPEN ", link: `https://${props.domain}/ozadmin`, onClick: () => false },
    ]

    return { title, wizardData };
}
