import { setState } from "../store/actions";
import store from "../store";
import { format } from "date-fns";

export function formatDate(ts, formula="dd/MM HH:mm") {
    return ts ? format(new Date(ts * 1000), formula) : null;
}

export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

export async function loadData(table, node, id) {
    let url = id ? `/api/${table}/${id}/` : `/api/${table}/`;
    const res = await fetch(url, { method: "GET" });
    if (!res.ok) return res;
    const data = await res.json();
    const state = { dataloading: false };
    if (node) state[node] = data;
    await store.dispatch(setState(state));
    return data;
}

export async function crud(table, id, data) {
    // if data is null then delete row
    let body = JSON.stringify(data || {});
    let url = `/api/data/${table}/`;
    if (id) url += `${id}/`;
    const method = !data ? "DELETE" : id > 0 ? "PUT" : "POST";

    let headers = {
        "Content-Type": "application/json"
        //    Authorization: `Token ${localStorage.getItem("auth_token")}`
    };

    return fetch(url, { headers, body, method })
        .then(res => (res.ok ? res : Promise.reject(res.statusText)))
        .then(res => (res.status === 204 ? {} : res.json()))
        .then(json => {
            if (json.error) return Promise.reject(json.error);
            return Promise.resolve(json);
        })
        .catch(msg => {
            console.log(msg);
            return Promise.reject(msg);
        });
}

export async function post(endpoint, data, method) {
    // if data is null then delete row
    let body = JSON.stringify(data || {});
    let url = `/api/${endpoint}`;

    let headers = {
        "Content-Type": "application/json"
        //    Authorization: `Token ${localStorage.getItem("auth_token")}`
    };
    return fetch(url, { headers, body, method: method || "POST" })
        .then(res => (res.ok ? res : Promise.reject(res.statusText)))
        .then(res => (res.status === 204 ? {} : res.json()))
        .then(json => {
            if (json.error) return Promise.reject(json.error);
            return Promise.resolve(json);
        })
        .catch(msg => {
            console.log(msg);
            return Promise.reject(msg);
        });
}

export function ValidateEmail(mail) {
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return true;
    }
    return false;
}

export function ValidateAFM(taxnum) {
    if (!taxnum) return false;
    if (taxnum.length !== 9) return false;
    if (isNaN(taxnum)) return false;
    return true;
}
