import React from 'react';
import "./MsgBox.css"
import { format } from 'date-fns';
import vCardsJS from 'vcards-js';
import { Button } from '@material-ui/core';
import { saveAs } from 'file-saver';

function getChatBoxClass(chatInfo, msg) {
    if (msg.to === chatInfo.code) return "msgbox-self";
    if (msg.new_msg) return "msgbox-other new";
    return "msgbox-other";
}

export default function MsgBoxInfoCard({ msg, chatInfo }) {
    const senderName = msg.from === chatInfo.code ? `${chatInfo.name} ${chatInfo.surname}` : "You";
    const datetime = format(new Date(msg.ts * 1000), "dd/MM HH:mm");


    const sendVCard = () => {
        var vCard = vCardsJS();
        vCard.firstName = msg.msg.name;
        vCard.lastName = msg.msg.surname;
        vCard.email = msg.msg.email;
        vCard.organization = msg.msg.company;
        vCard.title = msg.msg.jobtitle;
        vCard.workAddress.street = msg.msg.address;
        vCard.workAddress.city = msg.msg.city;
        vCard.workAddress.postalCode = msg.msg.zipcode;
        vCard.workAddress.countryRegion = msg.msg.country;
        vCard.workPhone = msg.msg.telephone;
        if (msg.msg.linkedin)
            vCard.socialUrls['linkedIn'] = 'https://linkedin.com/in/' + msg.msg.linkedin;
        const file = new File([vCard.getFormattedString()], `${msg.msg.name}_${msg.msg.surname}.vcf`, { type: "text/vcard;charset=utf-8" });
        saveAs(file);
    }


    return (
        <div className={getChatBoxClass(chatInfo, msg)} >
            <div className="innerbox">
                <div className="msgbox-header">
                    {senderName}, {datetime}
                </div>
                <p style={{ textAlign: "left", fontSize: "0.8em" }}>
                    <b>{msg.msg.address}, {msg.msg.city} - {msg.msg.zipcode} {msg.msg.country}</b><br />
                    Telephone: <b><a style={{ color: "black" }} href={`tel:${msg.msg.telephone}`}>{msg.msg.telephone}</a></b>
                    <br />
                    E-mail: <b><a style={{ color: "black" }} href={`mailto:${msg.msg.email}`}>{msg.msg.email}</a></b>
                    <br />
                    {msg.msg.linkedin && <>Linkedin: <b><a style={{ color: "black" }} target="_blank" rel="noreferrer" href={`https://linkedin.com/in/${msg.msg.linkedin}`}>{msg.msg.linkedin}</a></b><br /></>}
                </p>
                {msg.to !== chatInfo.code && <Button onClick={sendVCard}>VCard</Button>}

            </div>
        </ div>
    )
}
