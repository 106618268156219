import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider
} from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import SettingsIcon from "@material-ui/icons/Settings";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import WebIcon from "@material-ui/icons/Web";
import FaceIcon from "@material-ui/icons/Face";
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter';
import { loadData } from "../lib/api";
import { moduleTypes } from "../common/const"
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import TimelineIcon from '@material-ui/icons/Timeline';

class AdminDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() {
    loadData("data/modules", "modulesList");
  }

  render() {
    const { page, modulesList } = this.props;
    const drawer = (
      <div style={{ width: "250px" }}>
        <h1 style={{ textAlign: "center" }}>
          <img src="/img/onezero.svg" style={{ width: "80%" }} alt="" />
        </h1>
        <Divider />
        <List>
          {modulesList.map((m, index) => (
            <ListItem
              button
              selected={m.code === page}
              component="a"
              key={index}
              href={`/ozadmin/m/${m.code}`}
            >
              <ListItemIcon>{moduleTypes.find(t => t.id === m.module_type)?.icon}</ListItemIcon>
              <ListItemText primary={m.name} />
            </ListItem>
          ))
          }
        </List>
        <Divider />
        <List>
          {[
            ["Play Consoles", <SubscriptionsIcon />, "play-consoles"], 
            ["Emailing", <MailIcon />, "emailing"],
            ["Images", <PhotoFilterIcon />, "images"],
            ["Action Batches", <MenuOpenIcon />, "action-batches"],
            ["Statistics", <TimelineIcon />, "statistics"]
            ].map((text, index) => (
            <ListItem
              button
              selected={text[2] === page}
              component="a"
              key={text[2]}
              href={`/ozadmin/${text[2]}`}
            >
              <ListItemIcon>{text[1]}</ListItemIcon>
              <ListItemText primary={text[0]} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[
            ["Setup", <SettingsIcon />, "setup"],
            ["Modules", <ViewModuleIcon />, "modules"],
            ["Pages", <WebIcon />, "pages"],
            ["Administrators", <FaceIcon />, "admins"]
          ].map((text, index) => (
            <ListItem
              button
              selected={text[2] === page}
              component="a"
              key={text[2]}
              href={`/ozadmin/${text[2]}`}
            >
              <ListItemIcon>{text[1]}</ListItemIcon>
              <ListItemText primary={text[0]} />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <nav style={{ width: "250px" }}>
        <Drawer variant="permanent" anchor="left" open>
          {drawer}
        </Drawer>
        {/* <Hidden xsDown implementation="css">
          <Drawer variant="permanent" open>
            {drawer}
          </Drawer>
        </Hidden> */}
      </nav>
    );
  }
}
AdminDrawer.defaultProps = {
  mobileOpenMenu: false,
  modulesList: []
};

const mapStateToProps = state => {
  return {
    modulesList: state.modulesList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDrawer);
