import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import SendBar from './SendBar';
import MsgBox from './MsgBox';
import "./ChatBox.css";

export default function ChatBox(props) {
    const messagesEndRef = useRef(null)

    const chat = useSelector(({ userInfo }) => userInfo.chat);
    const userChat = chat ? chat[props.user] : {};
    const chatList = userChat.messages || [];
    const chatInfo = userChat.info || {};

    useEffect(() => {
        console.log(messagesEndRef)
        messagesEndRef.current?.scroll({
            top: 99999999999,
            left: 0,
            behavior: 'smooth'
          })
    }, [chat])

    return <div style={{
        display: "flex",
        height: "100%",
        flexDirection: "column"
    }}>
        <div ref={messagesEndRef} style={{ flexGrow: "1", overflow: "auto", padding: "20px" }}>
            {chatList.map((msg, msgIdx) => (
                <MsgBox msg={msg} chatInfo={chatInfo} key={msgIdx} />
            ))}
        </div>
        <SendBar user={props.user} />
    </div>;

}