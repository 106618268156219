import React, { useEffect } from "react";
import { post } from "../../lib/api";

export default function LiveStream({ cmpProps, style, streamCode, hashkey }) {
  useEffect(() => {
    const livePoke = () => {
      if (!hashkey) return;
      const tag = cmpProps.code;
      post("live-poke", { hashkey, tag });
    };
    const randomDelay = Math.random() * 30 * 1000;
    const timer = setTimeout(() => {
      livePoke();
    }, randomDelay);

    const intvTimer = setInterval(() => {
      livePoke();
    }, 5 * 60 * 1000 + randomDelay);

    return () => {
      clearTimeout(timer);
      clearInterval(intvTimer);
    };
  }, [hashkey, cmpProps.code]);

  const getVideoLink = (streamCode, background) => {
    let [video_provider, video_link] = streamCode.split(">>>");

    if (video_provider === "vimeo")
      if (background)
        return `https://player.vimeo.com/video/${video_link}?autoplay=1&playsinline=1&background=1&transparent=0`;
      else
        return `https://player.vimeo.com/video/${video_link}?autoplay=1&playsinline=1`;

    if (video_provider === "vimeo_event")
      return `https://vimeo.com/event/${video_link}/embed`;

    if (video_provider === "youtube")
      if (background)
        return `https://www.youtube.com/embed/${video_link}?playlist=${video_link}&autoplay=1&playsinline=1&autoplay=1&controls=0&showinfo=0&autohide=1&mute=1&loop=1&rel=0&modestbranding=1`;
      else
        return `https://www.youtube.com/embed/${video_link}?autoplay=1&playsinline=1&rel=0&modestbranding=1`;

    if (video_provider === "dacast")
      return `https://iframe.dacast.com/live/${video_link}`;
    return streamCode;
  };

  return (
    <div
      className={cmpProps.className}
      style={{
        ...style,
        width: "100%",
        background: "transparent",
        paddingTop: "56.25%",
        position: "relative",
        overflow: "hidden"
      }}
    >
      <iframe
        title="ozLiveStream"
        src={getVideoLink(streamCode, cmpProps.background)}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0px",
          left: "0px"
        }}
        frameBorder="0"
        allow={cmpProps.fullScreen ? "autoplay; fullscreen" : "autoplay"}
        allowFullScreen={cmpProps.fullScreen === "1"}
      ></iframe>
    </div>
  );
}
