import React from "react";
import { connect } from "react-redux";

import { setState } from "../../../store/actions";
import { post } from "../../../lib/api";
import {
  Paper,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button
} from "@material-ui/core";

class PlayConsoleLiveStreamWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = { enableUpdate: false };
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
  }

  setProp(newState) {
    const { idx } = this.props;
    const structure = [...this.props.consoleData.structure];
    const data = { ...structure[idx].data || {}, ...newState };
    structure[idx] = { ...structure[idx], data };
    this.props.setState({ consoleData: { ...this.props.consoleData, structure } })
    this.setState({ enableUpdate: true });
  }

  saveProp() {
    const { idx } = this.props;
    const structure = [...this.props.consoleData.structure];
    this.setState({ enableUpdate: false });
    post("set-console-data", { structure: structure[idx] });
  }


  render() {
    const { row, key } = this.props;
    return (
      <Paper key={key} style={{ flexGrow: 1, margin: "20px", padding: "20px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h2 style={{ display: "inline-block" }}>
              {row.descr}:
            <FormControl style={{ marginLeft: "40px" }}>
              <InputLabel>Provider</InputLabel>
              <Select
                style={{ width: "150px" }}
                label={`Provider`}
                onChange={event =>
                  this.setProp({
                    provider: event.target.value
                  })
                }
                value={row.data.provider}
              >
                <MenuItem key="Vimeo" value="vimeo">
                  Vimeo
                </MenuItem>
                <MenuItem key="Vimeo Event" value="vimeo_event">
                  Vimeo Event
                </MenuItem>
                <MenuItem key="Youtube" value="youtube">
                  Youtube
                  </MenuItem>
                <MenuItem key="Dacast" value="dacast">
                  Dacast
                  </MenuItem>
              </Select>
            </FormControl>            
            <TextField
              onChange={event =>
                this.setProp({ videoID: event.target.value })
              }
              label={`Video ID`}
              value={row.data.videoID}
              autoComplete="off"
              style={{ marginLeft: "10px" }}
            />
            </h2>
            <div style={{ display: "inline-block", margin: "0px 0px 0px 10px" }}>
              {this.state.enableUpdate &&
                <Button variant="contained" color="primary" onClick={() => this.saveProp()}>
                  UPDATE
              </Button>
              }
            </div>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

PlayConsoleLiveStreamWidget.defaultProps = {
  consoleData: {}
};

const mapStateToProps = state => {
  return Object.keys(PlayConsoleLiveStreamWidget.defaultProps).reduce(
    (obj, key) => ({ ...obj, [key]: state[key] }),
    {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayConsoleLiveStreamWidget);
