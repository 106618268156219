import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IconButton, InputAdornment, TextField, Grid, Tooltip } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import PersonPinIcon from '@material-ui/icons/PersonPin';

import { post } from '../../lib/api';
import 'firebase/database';
import { setState } from '../../store/actions';

export default function SendBar({ chatInfo, myInfo }) {
    const dispatch = useDispatch();
    const userInfo = useSelector(({ userInfo }) => userInfo) || {};

    const [msg, setMsg] = useState("");

    const handleSetMsg = (event) => {
        const charCode = event.target.value.charCodeAt(event.target.value.length - 1);
        if (charCode !== 10 && charCode !== 13)
            setMsg(event.target.value);
    }

    const startVideoCall = async () => {
        const { hashkey } = userInfo;
        const receiver = chatInfo.code;
        post("start-video-call", { hashkey, receiver });
        dispatch(setState({acceptedVideoCallContact: receiver }))

    }

    const sendMessage = async () => {
        if (msg.trim().length === 0) return;
        const { hashkey } = userInfo;
        const to = chatInfo.code;
        const multilineMsg = msg.replace("\n", "\n\n").trim();
        post("send-firebase-chat-msg", { hashkey, to, msg: multilineMsg });
        setMsg("");
    }

    const sendMyInfo = async () => {
        const { hashkey } = userInfo;
        const to = chatInfo.code;
        post("send-firebase-chat-msg", {
            hashkey, to, msg: {
                type: "card",
                name: userInfo.name,
                surname: userInfo.surname,
                company: userInfo.company,
                jobbtitle: userInfo.jobbtitle,
                telephone: userInfo.telephone,
                email: userInfo.email,
                linkedin: userInfo.linkedin,
                address: userInfo.address,
                city: userInfo.city,
                zipcode: userInfo.zipcode,
                country: userInfo.country
            }
        });
        setMsg("");
    }

    const handleMsgKeyUp = (event) => {
        if (!event.ctrlKey && event.key === 'Enter') {
            sendMessage();
            event.preventDefault();
            event.stopPropagation();
        }
        if (event.ctrlKey && event.key === 'Enter') {
            setMsg(msg + "\n");
        }
    }

    return (
        <Grid container style={{ paddingBottom: "10px" }}>
            <Grid item xs={msg ? 12 : 10}>
                <TextField fullWidth multiline variant="outlined" placeholder="Send Message..."
                    value={msg}
                    onChange={handleSetMsg}
                    onKeyUp={(event) => handleMsgKeyUp(event)}
                    autoFocus
                    InputProps={msg ? {
                        endAdornment:
                            < InputAdornment position="end" >
                                <IconButton
                                    onClick={sendMessage}
                                >
                                    <SendIcon />
                                </IconButton>
                            </InputAdornment>
                    } : {}}
                />
            </Grid>
            {!msg && <>
                <Grid item xs={1}>
                    <Tooltip title={chatInfo.online === "1" ? `Invite to video call` : `Video chat is disabled`} arrow placement="top">
                        <IconButton
                            disabled={chatInfo.online !== "1"}
                            onClick={startVideoCall}
                        >
                            <VoiceChatIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={`Send contact details`} arrow placement="top">
                        <IconButton
                            onClick={sendMyInfo}
                        >
                            <PersonPinIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>

            }
        </Grid >
    )
}
