import { IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { copyTextToClipboard, loadData } from "../../lib/api";
import DataTable from "../DataTable"
import FileCopyIcon from '@material-ui/icons/FileCopy';

export default function SetupBackups() {
    const columns = [{ id: "comments", label: "Comments", align: "left", minWidth: "200px" },
    { id: "ts", label: "Date/Time", align: "left", minWidth: "50px", type: "datetime", readonly: true },
    { id: "copyBtn", label: "", type: "custom", readonly: true }
    ]

    const handleCopyClick = (hashkey) => {
        const copyText = `${document.location.origin}/files/backups/${hashkey}.zip`
        copyTextToClipboard(copyText)
            .then(() => {
                alert("URL copied to clipboard")
                //   setIsCopied(true);
                //   setTimeout(() => {
                //     setIsCopied(false);
                //   }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const getCustomField = (row, column) => {
        if (column.id === "copyBtn")
            return (
                <IconButton>
                    <FileCopyIcon onClick={() => handleCopyClick(row.hashkey)} />
                </IconButton>
            ); return null;
    }

    const backupsList = useSelector(({ backupsList }) => backupsList) || [];

    useEffect(() => {
        loadData("data/backups", "backupsList");
    }, []);

    return <DataTable
        title="Backups"
        singular="Backup"
        columns={columns}
        list={backupsList}
        getCustomField={(row, column) => getCustomField(row, column)}
        listName="backupsList"
        crud="backups"
        searchFields={["comments"]}
        deletable
    />

}