import { IconButton, Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { post } from "../lib/api";
import DataTable from "./DataTable";
import { useDispatch, useSelector } from 'react-redux';
import { setState } from "../store/actions";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

export default function Approval({ match: { params: { module } } }) {
    const dispatch = useDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => loadData(), [])
    const moduleColumns = useSelector(({ moduleColumns }) => moduleColumns) || [];
    const moduleData = useSelector(({ moduleData }) => moduleData) || [];
    const moduleRow = useSelector(({ moduleRow }) => moduleRow);

    const [displayStatus, setDisplayStatus] = useState([true, false, false])

    const loadData = () => {
        post("get-module-data", { module }).then(res => {
            dispatch(setState({ moduleData: res.data, moduleRow: res.module, moduleColumns: res.module.setup?.fields, moduleActions: res.module.setup?.actions })
            )
        })
    };

    const getColumns = () => {
        const fieldColumns = moduleColumns.map(mc => ({
            id: mc.name,
            label: mc.descr,
            align: "left",
            minWidth: "100px",
            searchField: mc.searchField,
            type: mc.type,
            ...(mc.typeProps || {})
        }));
        return [
            { id: "approveButton", type: "custom", readonly: true },
            { id: "declineButton", type: "custom", readonly: true },
            {
                id: "ts",
                label: "Date/Time",
                align: "left",
                minWidth: "10px",
                type: "datetime"
            },
            ...fieldColumns];
    }

    const setApproveStatus = (row, status) => {
        post("set-approval", { id: row.id, status }).then(res => loadData())
    }

    const getCustomField = (row, column) => {
        if (column.id === "approveButton") {
            return (
                <IconButton onClick={() => setApproveStatus(row, 1)}>
                    <CheckCircleIcon style={{ color: row.ozApproveStatus === 1 ? "#0a0" : "#ccc" }} />
                </IconButton>

            );
        }
        if (column.id === "declineButton") {
            return (
                <IconButton onClick={() => setApproveStatus(row, 2)}>
                    <CancelIcon style={{ color: row.ozApproveStatus === 2 ? "#a00" : "#ccc" }} />
                </IconButton>

            );
        }
        return null;
    };

    const filteredModuleData = () => {
        return moduleData.filter(m =>
            (!m.ozApproveStatus && displayStatus[0]) ||
            (m.ozApproveStatus === 1 && displayStatus[1]) ||
            (m.ozApproveStatus === 2 && displayStatus[2])
        );
    }

    const handleDisplayStatusChange = (status, checked) => { setDisplayStatus(displayStatus.map((s, idx) => idx === status ? checked : s)) }
    const columns = getColumns();
    const searchFields = ["comments", ...columns.filter(c => c.searchField).map(c => c.id)];

    if (!moduleRow) return null;
    const filteredData = filteredModuleData();

    return (
        <DataTable
            title={`${moduleRow.name} Approval`}
            listCount = {moduleData.length}
            singular="Row"
            columns={columns}
            list={filteredData}
            getCustomField={(row, column) => getCustomField(row, column)}
            listName="moduleData"
            searchFields={searchFields}
            crud="modulerows"
            extrafields={(_) => ({ module_id: moduleRow.id })}
            onDataUpdate={() => loadData()}
            hideAA
            additionalSearchBox={
                <>
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={displayStatus[0]} onChange={(ev) => handleDisplayStatusChange(0, ev.currentTarget.checked)} />}
                        label="Pending"
                    />
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={displayStatus[1]} onChange={(ev) => handleDisplayStatusChange(1, ev.currentTarget.checked)} />}
                        label="Approved"
                    />
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={displayStatus[2]} onChange={(ev) => handleDisplayStatusChange(2, ev.currentTarget.checked)} />}
                        label="Declined"
                    />
                </>
            }
        />);

}

