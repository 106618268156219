import React from "react";
import { connect } from "react-redux";

import { setState } from "../../store/actions";
import { crud, loadData } from "../../lib/api";
import EmailEditor from 'react-email-editor';
import { IconButton, Paper } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

class EmailStructure extends React.Component {
  constructor(props) {
    super(props);
    this.emailEditorRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() {
    loadData("data/emails", "selectedEmail", this.props.id).then(data => {
      this.loadEmail();
    });
  }

  loadEmail(){
    const {design} = this.props.selectedEmail;
    if(design?.body && this.emailEditorRef.current.editor)
    this.emailEditorRef.current.editor.loadDesign(design);
  }

  saveEmail() {
    const { id } = this.props;

    this.emailEditorRef.current.editor.exportHtml((editorData) => {
      const emailData = { ...this.props.selectedEmail, design: editorData.design, html: editorData.html }
      crud("emails", id, emailData)
        .then(json => {
          console.log("saved");
        })
        .catch(res => {
          this.saving = false;
        });
    });
  }

  exportHtml() {
    this.emailEditorRef.current.editor.exportHtml((data) => {
      const { html } = data;
      console.log('exportHtml', html);
    });
  };

  render() {

    return (
      <Paper style={{ display: "flex", flexDirection: "column", flexGrow: 1, margin: "20px", overflow: "hidden" }}>
        <h2 style={{ padding: "10px 20px 0px" }}>
          {this.props.selectedEmail.title}
          <span style={{ float: "right" }}>
            <IconButton onClick={() => this.saveEmail()}>
              <SaveIcon />
            </IconButton>
          </span>

        </h2>
        <EmailEditor
          ref={this.emailEditorRef}
          onLoad={() => this.loadEmail()}
        />
      </Paper>
    )
  }
}
EmailStructure.defaultProps = {
  selectedEmail: {}
};

const mapStateToProps = state => {
  return {
    selectedEmail: state.selectedEmail
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailStructure);
