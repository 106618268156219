import React from "react";
import { FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup, TextField } from "@material-ui/core";
import { post } from "../../lib/api";

async function rescale(props, setProp) {
    let errorMsg = "";
    if (!props.serverName) errorMsg += "Invalid Server Name.\n";
    if (!props.serverType) errorMsg += "Select Server Type.\n";
    if (errorMsg) {
        alert(errorMsg);
        return false;
    }
    try {
        const res = await post("server/hetzner/rescale", props);
        setProp("cpuCores", res.cores);
        setProp("requests", res.cores * 1000);
        setProp("concurrency", res.cores * 50);
    }
    catch (e) {
        alert(e);
        return false;
    }

    window.open("https://console.hetzner.cloud/projects/1097978/servers", "_blank")
    return true;
}


async function setUWSGISettings(props, setLogs) {
    let errorMsg = "";
    if (!props.domain) errorMsg += "Invalid domain.\n";
    if (!props.psw) errorMsg += "Invalid password.\n";
    if (!props.cpuCores) errorMsg += "Invalid CPU Cores.\n";
    if (errorMsg) {
        alert(errorMsg);
        return false;
    }
    try {
        const res = await post("server/hetzner/uwsgicpus", props);
        setLogs(res.logs);
    }
    catch (e) {
        alert(e);
        return false;
    }

    return true;
}

async function uwsgiCrashTest(props, setLogs) {
    let errorMsg = "";
    if (!props.psw) errorMsg += "Invalid password.\n";
    if (!props.domain) errorMsg += "Invalid domain.\n";
    if (!props.requests) errorMsg += "Invalid number of requests.\n";
    if (!props.concurrency) errorMsg += "Invalid number of concurrency.\n";

    if (errorMsg) {
        alert(errorMsg);
        return false;
    }
    try {
        const res = await post("server/hetzner/uwsgicrashtest", props);
        setLogs(res.logs);
    }
    catch (e) {
        alert(e);
        return false;
    }

    return false;
}

export default function resizeHetznerServer(props, setProp, setLogs) {
    const title = "Resize Hetzner Server"
    const wizardData = [
        {
            title: "Rescale Server", descr: (
                <>
                    <TextField value={props.serverName || ""} onChange={(e) => setProp("serverName", e.target.value)} label="Server Name" fullWidth InputProps={{
                        startAdornment: <InputAdornment position="start">oz-</InputAdornment>,
                    }} />
                    <FormControl component="fieldset" style={{ marginTop: "50px" }}>
                        <FormLabel component="legend">Server Type</FormLabel>
                        <RadioGroup name="gender1" value={props.serverType} onChange={(e) => setProp("serverType", e.target.value)}>
                            <FormControlLabel value="cx21" control={<Radio color="primary" />} label="CX21 (2VCPUS, 4GB RAM) €4.90/mo" />
                            <FormControlLabel value="cpx31" control={<Radio color="primary" />} label="CPX31 (4VCPUS, 8GB RAM) €12.40/mo" />
                            <FormControlLabel value="cpx41" control={<Radio color="primary" />} label="CPX41 (8VCPUS, 16GB RAM) €22.90/mo" />
                            <FormControlLabel value="cpx51" control={<Radio color="primary" />} label="CPX51 (16VCPUS, 32GB RAM) €49.90/mo" />
                        </RadioGroup>
                    </FormControl>                </>
            ), button: "RESCALE", onClick: () => rescale(props, setProp)
        },
        {
            title: "Server Setup", descr: (
                <>
                    <TextField value={props.domain || ""} onChange={(e) => setProp("domain", e.target.value)} label="Domain" fullWidth />
                    <TextField value={props.cpuCores || ""} onChange={(e) => setProp("cpuCores", e.target.value)} label="CPU Cores" fullWidth />
                </>
            ), button: "EXECUTE", onClick: () => setUWSGISettings(props, setLogs)
        },
        {
            title: "Benchmarking", descr: (
                <>
                    <TextField value={props.requests || ""} onChange={(e) => setProp("requests", e.target.value)} label="Requests" fullWidth />
                    <TextField value={props.concurrency || ""} onChange={(e) => setProp("concurrency", e.target.value)} label="Concurrency" fullWidth />
                </>
            ), button: "TEST", onClick: () => uwsgiCrashTest(props, setLogs)
        },
    ]

    return { title, wizardData };
}
