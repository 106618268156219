import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Link
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import EditModal from "./EditModal";
import SaveIcon from "@material-ui/icons/Save";
import { format } from "date-fns";

class DataTable extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.list !== prevProps.list && this.sortColumn && !this.onSort) {
      this.sortData(null, "");
    }
  }

  addRow() {
    this.props.setState({
      showEditModal: true,
      editTitle: `Add ${this.props.singular}`,
      editColumns: this.props.columns,
      editRow: { id: 0 }
    });
  }

  editRow(editRow) {
    this.props.setState({
      showEditModal: true,
      editTitle: `Edit ${this.props.singular}`,
      editColumns: this.props.columns,
      editRow
    });
  }

  getField(row, column, textOnly) {
    if (column.type === "datetime")
      return row[column.id]
        ? format(new Date(row[column.id] * 1000), "dd/MM/yyyy HH:mm")
        : null;
    if (column.type === "checkbox")
      return row[column.id] && !textOnly ? <CheckIcon /> : null;
    if (column.type === "custom")
      return !textOnly ? this.props.getCustomField(row, column) : "";
    if (column.type === "list") {
      const listrow = column.list.filter(l => l.id === row[column.id]);
      return listrow.length > 0 ? listrow[0].descr : null;
    }
    if (column.type === "user") {
      const userRow = row[`${column.id}_data`];
      return `${userRow.name} ${userRow.surname}`;
    }
    let res = String(row[column.id] || "");
    if (res?.length > 100) res = `${res.substr(0, 100)}...`;
    return res;
  }

  exportFile() {
    const { columns, list, title, searchFields, searchText } = this.props;

    const header = `${columns
      .filter(c => c.label !== "")
      .map(c => c.label)
      .join(";")}\n`;

    const txts = list
      .filter(
        a =>
          (searchFields || [])
            .map(f => a[f])
            .join(" ")
            .toUpperCase()
            .indexOf(searchText.trim().toUpperCase()) >= 0
      )
      .map(
        row =>
          `${columns
            .filter(c => c.label !== "")
            .map(
              column =>
                `"${(this.getField(row, column, true) || "").replace(
                  /"/g,
                  '""'
                )}"`
            )
            .join(";")}`
      )
      .join(`\n`);

    const universalBOM = "\uFEFF";
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," +
        encodeURIComponent(universalBOM + header + txts)
    );
    element.setAttribute("download", `${title}.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  getSortOrder(a, b) {
    if (a === 0 && !b) return -1;
    if (b === 0 && !a) return 1;
    if (!a) return -1;
    if (!isNaN(a) && !isNaN(b)) return a - b;
    if (a.localeCompare) return a.localeCompare(b || "");
    return -1;
  }

  sortData(ev, column) {
    if (ev) ev.preventDefault();
    const list = [...this.props.list];
    if (column !== "") {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder * -1;
      } else {
        this.sortColumn = column;
        this.sortOrder = 1;
      }
    }
    this.onSort = true;
    list.sort(
      (a, b) =>
        this.sortOrder *
        this.getSortOrder(a[this.sortColumn], b[this.sortColumn])
    );
    this.props.setState({ [this.props.listName]: list });
    setTimeout(() => {
      this.onSort = false;
    }, 100);
  }

  render() {
    const {
      columns,
      list,
      title,
      readOnly,
      hideAA,
      additionalSearchBox,
      searchText,
      rowLimit,
      listCount
    } = this.props;
    const allRowsCount = listCount || list.length;
    const filteredList = list.filter(
      a =>
        (this.props.searchFields || [])
          .map(f => a[f])
          .join(" ")
          .toUpperCase()
          .indexOf(this.props.searchText.trim().toUpperCase()) >= 0
    );
    const fulltitle = (
      <div>
        {title}
        <br />
        <span
          style={{
            fontWeight: "normal",
            fontSize: "0.7em",
            paddingLeft: "5px"
          }}
        >
          Displayed: {filteredList.length}/{allRowsCount}
        </span>
      </div>
    );
    return (
      <Paper style={{ flexGrow: 1, margin: "20px" }}>
        <h2 style={{ padding: "20px" }}>
          {fulltitle} {this.props.titleButton}
          {
            <span style={{ float: "right" }}>
              {additionalSearchBox}
              <TextField
                size="small"
                style={{ width: "100px", marginRight: "10px" }}
                onChange={event =>
                  this.props.setState({ rowLimit: event.target.value })
                }
                value={rowLimit || ""}
                label="Row Limit"
                variant="outlined"
                autoComplete="off"
              />
              <TextField
                size="small"
                style={{ width: "200px" }}
                onChange={event =>
                  this.props.setState({ searchText: event.target.value })
                }
                value={searchText || ""}
                label="Search"
                variant="outlined"
                autoComplete="off"
              />
              <IconButton
                onClick={() => this.exportFile()}
                style={{ marginTop: "-15px" }}
              >
                <SaveIcon />
              </IconButton>
              {!readOnly && (
                <IconButton
                  onClick={() => this.addRow()}
                  style={{ marginTop: "-15px" }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </span>
          }
        </h2>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {!readOnly && <TableCell />}
                {!hideAA && <TableCell />}
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Link
                      color="inherit"
                      href="#"
                      onClick={ev => this.sortData(ev, column.id)}
                    >
                      {column.label}
                    </Link>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredList
                .filter((a, idx) => idx < rowLimit)
                .map((row, idx) => (
                  <TableRow key={`row${idx}`}>
                    {!readOnly && (
                      <TableCell>
                        <IconButton onClick={() => this.editRow(row)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    {!hideAA && <TableCell>{`${idx + 1}.`}</TableCell>}
                    {columns.map(column => (
                      <TableCell
                        key={`${idx}-${column.id}`}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {this.getField(row, column, false)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditModal
          crud={this.props.crud}
          listName={this.props.listName}
          deletable={this.props.deletable}
          extrafields={this.props.extrafields}
          onDataUpdate={this.props.onDataUpdate}
          singular={this.props.singular}
        />
      </Paper>
    );
  }
}
DataTable.defaultProps = {
  searchText: "",
  rowLimit: 200
};

const mapStateToProps = state => {
  return {
    searchText: state.searchText,
    rowLimit: state.rowLimit
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
