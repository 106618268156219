import {
  Button,
  Grid,
  TableBody,
  TableRow,
  Table,
  TableCell,
  TableHead,
  TextField,
  IconButton,
  Checkbox,
  Select,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { setState } from "../../store/actions";
import ClearIcon from "@material-ui/icons/Clear";

class ModuleSetupActionList extends React.Component {
  addField() {
    const { setup = {} } = this.props.editSettingsRow || {};
    const { setupProps } = this.props;
    const moduleProps = setup[setupProps.name] || [];
    const newModuleProps = [
      ...moduleProps,
      {
        title: "",
        action: "",
        props: "",
        autorun: ""
      }
    ];

    const editSettingsRow = {
      ...this.props.editSettingsRow,
      setup: { ...setup, [setupProps.name]: newModuleProps }
    };

    this.props.setState({ editSettingsRow });
  }

  setFieldVal(fieldName, value, idx) {
    const { setup = {} } = this.props.editSettingsRow || {};
    const { setupProps } = this.props;
    const moduleProps = setup[setupProps.name] || [];
    const newModuleProps = moduleProps.map((mp, mpIdx) =>
      mpIdx === idx ? { ...mp, [fieldName]: value } : mp
    );

    const editSettingsRow = {
      ...this.props.editSettingsRow,
      setup: { ...setup, [setupProps.name]: newModuleProps }
    };

    this.props.setState({ editSettingsRow });
  }

  delField(idx) {
    const { setup = {} } = this.props.editSettingsRow || {};
    const { setupProps } = this.props;
    const moduleProps = setup[setupProps.name] || [];

    const newModuleProps = moduleProps.filter((mp, mpIdx) => mpIdx !== idx);

    const editSettingsRow = {
      ...this.props.editSettingsRow,
      setup: { ...setup, [setupProps.name]: newModuleProps }
    };

    this.props.setState({ editSettingsRow });
  }

  render() {
    const { setup = {} } = this.props.editSettingsRow || {};
    const { setupProps } = this.props;
    const moduleFields = setup[setupProps.name] || [];

    const actionTypes = [
      { code: "open_page", descr: "Open Page" },
      { code: "send_mail", descr: "Send Email" },
      { code: "draw_qr", descr: "Draw QR" },
      { code: "draw_img", descr: "Draw Image" }
    ];
    const propLabels = {
      open_page: "Page",
      send_mail: "Email template",
      draw_qr: "Redirect Page",
      draw_img: "Image template"
    };
    return (
      <Grid container style={{ background: "#eee", border: "1px solid black" }}>
        <Grid
          item
          xs={12}
          style={{ background: "#444", color: "white", padding: "5px" }}
        >
          {setupProps.title}
        </Grid>
        <Grid item xs={12} style={{ padding: "5px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Props</TableCell>
                <TableCell>Autorun</TableCell>
                <TableCell>Unlisted</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {moduleFields.map((mp, mpIdx) => (
                <TableRow key={mpIdx}>
                  <TableCell>
                    <TextField
                      value={mp.code || ""}
                      onChange={e =>
                        this.setFieldVal("code", e.target.value, mpIdx)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={mp.title || ""}
                      onChange={e =>
                        this.setFieldVal("title", e.target.value, mpIdx)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      style={{ width: "150px" }}
                      value={mp.action || ""}
                      onChange={e =>
                        this.setFieldVal("action", e.target.value, mpIdx)
                      }
                    >
                      {actionTypes.map((row, idx) => (
                        <MenuItem key={row.code} value={row.code}>
                          {row.descr}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={mp.props || ""}
                      style={{ marginTop: "-15px", width: "150px" }}
                      label={propLabels[mp.action] || " "}
                      onChange={e =>
                        this.setFieldVal("props", e.target.value, mpIdx)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <div>
                          <h3>command:field=value</h3>
                          <hr />
                          Examples:
                          <ul style={{ marginTop: 0 }}>
                            <li>registration</li>
                            <li>registration:online=0</li>
                            <li>approved</li>
                            <li>declined</li>
                          </ul>
                        </div>
                      }
                    >
                      <TextField
                        value={mp.autorun || ""}
                        onChange={e =>
                          this.setFieldVal("autorun", e.target.value, mpIdx)
                        }
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={mp.unlisted}
                      onChange={e =>
                        this.setFieldVal("unlisted", e.target.checked, mpIdx)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={mp.readonly}
                      onClick={() => this.delField(mpIdx)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} style={{ padding: "5px" }}>
          <Button onClick={() => this.addField()}>
            Add {setupProps.singular}
          </Button>
        </Grid>
      </Grid>
    );
  }
}
ModuleSetupActionList.defaultProps = {
  modulesList: [],
  editSettingsRow: null
};

const mapStateToProps = state => {
  return {
    editSettingsRow: state.editSettingsRow
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModuleSetupActionList);
