import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "typeface-roboto";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Admin from "./admin/Admin";
import Page from "./pages/Page";
import PageStructure from "./admin/pages/PageStructure";
import PlayConsole from "./admin/playConsole/PlayConsole";
import ChatAdmin from "./admin/ChatAdmin";
import { Test } from "./pages/Test/index";
import Approval from "./admin/Approval";
import VMixAdmin from "./pages/VMixAdmin/VMixAdmin";
import ServerAdmin from "./serverAdmin/ServerAdmin";
import ServerAdminWizard from "./serverAdmin/ServerAdminWizard";

render(
  <Provider store={store}>
    <Router>
      <Routes>
        <Route exact path="/ozadmin" element={<Admin/>} />
        <Route exact path="/ozadmin/console" element={<PlayConsole/>} />
        <Route exact path="/ozadmin/console/:console" element={<PlayConsole/>} />
        <Route exact path="/ozadmin/:page" element={<Admin/>} /> 
        <Route exact path="/ozadmin/pages/:id" element={<PageStructure/>} />
        <Route exact path="/ozadmin/emailing/:id" element={<Admin page="emailing" />} />
        <Route exact path="/ozadmin/action-batches/:id" element={<Admin page="action-batches" />} />
        <Route exact path="/ozadmin/m/:id" element={<Admin page="modulepage" />} />
        <Route exact path="/ozadmin/c/:module" element={<ChatAdmin/>} />
        <Route exact path="/ozadmin/approval/:module" element={<Approval/>} />
        <Route exact path="/ozadmin/vmix/admin" element={<VMixAdmin/>} />
        <Route exact path="/ozserver/" element={<ServerAdmin/>} />
        <Route exact path="/ozserver/:code" element={<ServerAdminWizard/>} />


        {/* <Route exact path="/register" component={Register} />
        <Route exact path="/program" component={Program} />
        <Route exact path="/info" component={Info} />
        <Route exact path="/live/:hashkey" component={Live} />
        <Route exact path="/live/:forcePlay/:hashkey" component={Live} /> */}
        {/* <Route exact path="/pollresults" component={PollResults} />
        <Route exact path="/questionsviewer" component={QuestionsViewer} />
        <Route exact path="/attendee-print/:hashkey" component={AttendeePrint} /> */}

        <Route exact path="/:page" element={<Page/>} />
        <Route exact path="/:page/:hashkey" element={<Page/>} />
        <Route exact path="/" element={<Page/>} />
      </Routes>
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
