import React from "react";
import { connect } from "react-redux";

import { setState } from "../../store/actions";
import DataTable from "../DataTable";
import { post } from "../../lib/api";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import ModuleActionLogs from "./ModuleActionLogs";
import ModuleKeyInfo from "./ModuleKeyInfo";

class ModulePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { actionBtnRef: null, actionRow: {} };
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const { module } = this.props;
    post("get-module-data", { module }).then(res => {
      this.props.setState({
        moduleData: res.data,
        moduleRow: res.module,
        moduleColumns: res.module.setup?.fields,
        moduleActions: res.module.setup?.actions,
        linkedModules: res.linkedModules
      })
    });
  }

  getColumns() {
    const { moduleColumns = [], linkedModules } = this.props;
    const fieldColumns = moduleColumns.map(mc => ({
      id: mc.name,
      label: mc.descr,
      align: "left",
      minWidth: "100px",
      searchField: mc.searchField,
      type: mc.type,
      ...(mc.typeProps || {})
    }));

    if (this.props.moduleRow?.link1)
      fieldColumns.unshift(
        { id: "ozLink1", label: this.props.moduleRow?.link1_title, align: "left", minWidth: "100px", type: "list", list: linkedModules.link1 },
      )

    if (this.props.moduleRow?.link2)
      fieldColumns.unshift(
        { id: "ozLink2", label: this.props.moduleRow?.link2_title, align: "left", minWidth: "100px", type: "list", list: linkedModules.link2 },
      )

    fieldColumns.unshift({
      id: "moduleActions", label: "", type: "custom", align: "left", minWidth: "100px", searchField: false, readonly: true
    })

    fieldColumns.unshift({
      id: "id", label: "id", align: "left", searchField: false, readonly: true
    })

    return fieldColumns;
  }

  getCustomField(row, column) {
    if (column.id === "moduleActions") {
      return (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(ev) => this.setState({ actionBtnRef: ev.currentTarget, actionRow: row })}
        >
          <MoreVertIcon />
        </IconButton>
      );
    }
    return null;
  }

  callAction(action) {
    const row = { ...this.state.actionRow };
    this.setState({ actionBtnRef: null, actionRow: {} })
    if (!action) return;

    if (action.action === "open_page") {
      window.open(`/${action.props}/${row.hashkey}`, '_blank');
      return;
    }

    const confirmAction = window.confirm(
      `${action.title} to ${row.name} ${row.surname} (${row.email})?`
    );
    if (!confirmAction) return;

    post(`call-module-action`, { action, hashkey: row.hashkey })
      .then(res => {
        alert(res.response);
      })
      .catch(msg => {
        this.props.setState({ saving: false });
        return alert(msg);
      });
  }

  openActionLogs() {
    this.props.setState({ actionLogRow: this.state.actionRow, actionLogRowData: [] })
  }

  openAccountKeys() {
    this.props.setState({ keyInfoRow: this.state.actionRow })
  }

  render() {
    const columns = this.getColumns();
    const searchFields = ["comments", ...columns.filter(c => c.searchField).map(c => c.id)];
    return (
      <>
        <DataTable
          title={this.props.moduleRow?.name}
          singular="Row"
          columns={columns}
          list={this.props.moduleData}
          getCustomField={(row, column) => this.getCustomField(row, column)}
          listName="moduleData"
          crud="modulerows"
          searchFields={searchFields}
          deletable
          extrafields={(_) => ({ module_id: this.props.moduleRow?.id })}
          onDataUpdate={() => this.loadData()}
        />
        <Menu
          anchorEl={this.state.actionBtnRef}
          keepMounted
          open={Boolean(this.state.actionBtnRef)}
          onClose={() => this.callAction(null, null)}
        >
          {this.props.moduleActions?.filter(a => !a.unlisted).map((action, action_idx) => (
            <MenuItem key={action_idx} onClick={() => this.callAction(action)}>{action.title}</MenuItem>
          ))
          }
          < Divider />
          <MenuItem onClick={() => this.openActionLogs()}>Show Action Logs</MenuItem>
          {this.state.actionRow?.hashkey &&
            <MenuItem onClick={() => this.openAccountKeys()}>Show Account Keys</MenuItem>
          }
        </Menu>
        <ModuleActionLogs />
        <ModuleKeyInfo />
      </>
    );
  }
}
ModulePage.defaultProps = {
  moduleData: [],
  moduleActions: []
};

const mapStateToProps = state => {
  return {
    moduleData: state.moduleData,
    moduleColumns: state.moduleColumns,
    moduleActions: state.moduleActions,
    moduleRow: state.moduleRow,
    linkedModules: state.linkedModules
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModulePage);
