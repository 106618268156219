import { IconButton } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

const Participant = ({ participant, myself, room }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [muted, setMuted] = useState(myself);
  const [selfMuted, setSelfMuted] = useState(true);
  const [hidden, setHidden] = useState(true);
  const [selfHidden, setSelfHidden] = useState(true);
  const videoRef = useRef();
  const audioRef = useRef();

  const toggleMuted = () => {
    setMuted(!muted);
  }

  const toggleHidden = () => {
    setHidden(!hidden);
  }

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  const trackSubscribed = (track) => {
    if (track.kind === "video") {
      setVideoTracks((videoTracks) => [...videoTracks, track]);
    } else if (track.kind === "audio") {
      setAudioTracks((audioTracks) => [...audioTracks, track]);
    }
  };

  const trackUnsubscribed = (track) => {
    if (track.kind === "video") {
      setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
    } else if (track.kind === "audio") {
      setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
    }
  };

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));


    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      setSelfHidden(!videoTrack.isEnabled);
      videoTrack.on('disabled', (track) => setSelfHidden(true));
      videoTrack.on('enabled', (track) => setSelfHidden(false));

      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      setSelfMuted(!audioTrack.isEnabled);
      audioTrack.on('disabled', (track) => setSelfMuted(true));
      audioTrack.on('enabled', (track) => setSelfMuted(false));

      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    if (myself)
      participant.audioTracks.forEach((audioTrack) => {
        audioTrack.muted = muted;
        audioTrack.enabled = !muted;
        if (muted)
          audioTrack.track.disable();
        else
          audioTrack.track.enable();
      })
  }, [muted, myself, participant?.audioTracks]);

  useEffect(() => {
    if (myself) {
      const videoTrack = [...participant.videoTracks.values()][0];
      if (hidden) {
        videoTrack.track.disable();
        //        videoTrack.track.stop();
      }
      else {
        videoTrack.track.enable();
        // if(videoTrack.disabled){
        //   videoTrack.track.enable();

        // videoTrack.track.enable();
        // trackUnsubscribed(videoTrack.track)
        // participant.unpublishTrack(videoTrack.track);        
        // createLocalVideoTrack().then(track => {
        //   track.enable();
        //   participant.publishTrack(track);
        //   trackSubscribed(track)
        // });  
        // }
      }
    }
  }, [hidden, myself, participant?.videoTracks]);

  return (
    <div className="participant" style={{ position: "relative", overflow: "hidden" }}>
      <div style={{ background: "black", paddingBottom: "75%" }}></div>
      <video style={{ opacity: selfHidden ? 0 : 1, position: "absolute", top: "0px", left: "0px", width: "100%" }} ref={videoRef} autoPlay={true} />
      <audio ref={audioRef} autoPlay={true} muted={myself || muted} />
      <h3 style={{ background: "orange", padding: "10px", position: "absolute", bottom: "10px", color: "white" }}>{participant.identity}</h3>
      {myself &&
        <IconButton onClick={toggleHidden} style={{ position: "absolute", top: "10px", right: "50px", color: hidden ? "#f00" : "#0a0" }}>
          {
            hidden ?
              <VideocamOffIcon /> :
              <VideocamIcon />
          }
        </IconButton>
      }
      <IconButton onClick={toggleMuted} style={{ position: "absolute", top: "10px", right: "10px", color: selfMuted ? "orange" : muted ? "#f00" : "#0a0" }}>
        {
          muted || selfMuted ?
            <MicOffIcon /> :
            <MicIcon />
        }
      </IconButton>

      {
        selfHidden &&
        <VideocamOffIcon style={{ color: "#999", width: "50%", height: "50%", position: "absolute", left: "25%", right: "25%", top: "25%", bottom: "25%" }} />
      }
    </div>
  );
};

export default Participant;