let pasteId = 0;

export function getChildIDs(cmp, pageStructure) {
    const detailIDs = pageStructure.filter(p => p.parent === cmp.id).map(c => getChildIDs(c, pageStructure));
    return [cmp.id, ...detailIDs].flat()
}

export function componentStruct(cmp, pageStructure) {
    const copyCmp = { ...cmp };
    delete copyCmp.id;
    delete copyCmp.parent;
    copyCmp.details = pageStructure.filter(p => p.parent === cmp.id).map(c => componentStruct(c, pageStructure));
    return copyCmp;
}

export function pasteComponent(parent, strct, newPasteId) {
    if (newPasteId) pasteId = newPasteId;
    else pasteId++;
    const newCmp = {
        id: pasteId,
        name: strct.name,
        type: strct.type,
        className: strct.className,
        parent,
        props: strct.props,
        style: strct.style
    }
    return [newCmp, ...strct.details.map(d => pasteComponent(newCmp.id, d))].flat()
}

export function convertHTMLElement(elm, id, parent) {
    if (elm.nodeType === 3) return (!elm.textContent.trim() ? null : {
        id,
        name: elm.textContent,
        type: "txt",
        className: "",
        parent,
        props: {
            text: elm.textContent,
        },
        style: []
    })

    if (elm.tagName === "IMG") return ({
        id,
        name: elm.src,
        type: "img",
        className: elm.className,
        parent,
        props: {
            src: elm.src,
        },
        style: []
    })


    return ({
        id,
        name: elm.nodeName,
        type: "html",
        className: elm.className,
        parent,
        props: {
            element: elm.tagName.toLowerCase(),
            props: JSON.stringify(Array.from(elm.attributes).reduce((prev, cur) => ({ ...prev, [cur.name]: cur.value }), {}))
        },
        style: []
    })
}

export function getHTML(elm, parentID, newPasteId) {
    if (newPasteId) pasteId = newPasteId;
    else pasteId++;
    const newCmp = convertHTMLElement(elm, pasteId, parentID);
    if(!newCmp)return [];
    return [newCmp, ...Array.from(elm.childNodes).filter(elm => [1, 3].includes(elm.nodeType)).map(child => getHTML(child, newCmp.id))].flat();
}
