import React, { useState } from "react";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from '@material-ui/icons/Save';
import {
    Grid,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    IconButton,
    ListItemSecondaryAction,
    Divider,
    ListItemText,
    OutlinedInput,
    InputLabel,
    FormControl,
    InputAdornment
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../lib/api";
import { setState } from "../../store/actions";
export default function VMixListLiveAdd() {
    const dispatch = useDispatch();
    const [newKey, setNewKey] = useState("");

    const addKey = () => {
        post("vmix/channels", { action: "insert", key: newKey }).then(res => {
            setNewKey("");
            dispatch(setState({ vmixChannels: res }))
        });
    }

    return <React.Fragment>
        <ListItem >
            <ListItemText>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>Add vMix Key</InputLabel>

                    <OutlinedInput
                        label="Add vMix Key"
                        value={newKey}
                        onChange={event =>
                            setNewKey(event.target.value)
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={!newKey}
                                    color={newKey ? "primary" : "default"}
                                    edge="end"
                                    onClick={() => addKey()}
                                >
                                    <SaveIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </ListItemText>
        </ListItem>
    </React.Fragment >;
}