import { Button, Grid, TableBody, TableRow, Table, TableCell, TableHead, TextField, Checkbox, IconButton } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { setState } from "../../store/actions";
import ClearIcon from '@material-ui/icons/Clear';

class ModuleSetupFieldList extends React.Component {

    addField() {
        const { setup = {} } = this.props.editSettingsRow || {};
        const { setupProps } = this.props;
        const moduleProps = setup[setupProps.name] || [];
        const newModuleProps = [...moduleProps, {
            name: "",
            descr: "",
            type: "text"
        }];

        const editSettingsRow = {
            ...this.props.editSettingsRow,
            setup: { ...setup, [setupProps.name]: newModuleProps }
        }

        this.props.setState({ editSettingsRow })
    }

    setFieldVal(fieldName, value, idx) {
        const { setup = {} } = this.props.editSettingsRow || {};
        const { setupProps } = this.props;
        const moduleProps = setup[setupProps.name] || [];
        const newModuleProps = moduleProps.map((mp, mpIdx) => mpIdx === idx ? { ...mp, [fieldName]: value } : mp);

        const editSettingsRow = {
            ...this.props.editSettingsRow,
            setup: { ...setup, [setupProps.name]: newModuleProps }
        }

        this.props.setState({ editSettingsRow })

    }

    delField(idx) {
        const { setup = {} } = this.props.editSettingsRow || {};
        const { setupProps } = this.props;
        const moduleProps = setup[setupProps.name] || [];

        const newModuleProps = moduleProps.filter((mp, mpIdx) => mpIdx !== idx);

        const editSettingsRow = {
            ...this.props.editSettingsRow,
            setup: { ...setup, [setupProps.name]: newModuleProps }
        }

        this.props.setState({ editSettingsRow })
    }


    render() {
        const { setup = {} } = this.props.editSettingsRow || {};
        const { setupProps } = this.props;
        const moduleFields = setup[setupProps.name] || [];

        return <Grid container style={{ background: "#eee", border: "1px solid black" }}>
            <Grid item xs={12} style={{ background: "#444", color: "white", padding: "5px" }}>
                {setupProps.title}
            </Grid>
            <Grid item xs={12} style={{ padding: "5px" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Search Field</TableCell>
                            <TableCell>Order By</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            moduleFields.map((mp, mpIdx) =>
                                <TableRow key={mpIdx}>
                                    <TableCell><TextField value={mp.name || ""} disabled={mp.readonly} onChange={(e) => this.setFieldVal("name", e.target.value, mpIdx)} /></TableCell>
                                    <TableCell><TextField value={mp.descr || ""} disabled={mp.readonly} onChange={(e) => this.setFieldVal("descr", e.target.value, mpIdx)} /></TableCell>
                                    <TableCell>{mp.text}</TableCell>
                                    <TableCell><Checkbox checked={mp.searchField} disabled={mp.readonly} onChange={(e) => this.setFieldVal("searchField", e.target.checked, mpIdx)} /></TableCell>
                                    <TableCell><Checkbox checked={mp.orderField} onChange={(e) => this.setFieldVal("orderField", e.target.checked, mpIdx)} /></TableCell>
                                    <TableCell><IconButton disabled={mp.readonly} onClick={() => this.delField(mpIdx)} ><ClearIcon /></IconButton></TableCell>
                                </TableRow>)
                        }

                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} style={{ padding: "5px" }}>
                <Button onClick={() => this.addField()}>Add {setupProps.singular}</Button>
            </Grid>
        </Grid >;
    }
}
ModuleSetupFieldList.defaultProps = {
    modulesList: [],
    editSettingsRow: null
};

const mapStateToProps = state => {
    return {
        editSettingsRow: state.editSettingsRow
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleSetupFieldList);
