
import React from 'react';
import { FacebookProvider } from 'react-facebook';

export default function OzFacebookProvider({ cmpProps, detailCmp }) {
    let { appId } = cmpProps;
    if(!appId)return "not found Facebook AppId"
    return <FacebookProvider appId={appId}>
        {detailCmp}
    </FacebookProvider>
}