/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { post } from "../lib/api";
import { Grid, Button, Container, Paper, Checkbox, FormControlLabel, Table, TableBody, TableRow, TableCell, ButtonGroup, Dialog, DialogTitle, DialogContent, IconButton, List, ListItem, ListItemText, Badge, Avatar, ListItemAvatar, InputAdornment } from "@material-ui/core";
import { format } from "date-fns";
import ReactMarkdown from 'react-markdown'
import { cmpTypes } from "./const";
import CookieConsent from "react-cookie-consent";
import CloseIcon from '@material-ui/icons/Close';
import { Test } from "../pages/Test/index";
import ZoomMeeting from "../pages/ZoomMeeting";
import TwilioMeeting from "../pageComponents/TwilioMeeting/TwilioMeeting";
import ChatBox from "../pageComponents/ChatBox/ChatBox";
import FirebaseChatBox from "../pageComponents/FirebaseChatBox/FirebaseChatBox";
import OzSelect from "../pageComponents/OzSelect/OzSelect";
import OzCountDown from "../pageComponents/OzCountDown/OzCountDown";
import AddContactBtn from "../pageComponents/FirebaseChatBox/AddContactBtn";
import { registerFirestoreDataset, registerFbState } from "../lib/firebase";
import OzMenu from "../pageComponents/OzMenu/OzMenu";
import TwitterTweet from "../pageComponents/Twitter/TwitterTweet";
import OzFacebookProvider from "../pageComponents/Facebook/OzFacebookProvider";
import OzFacebookPost from "../pageComponents/Facebook/OzFacebookPost";
import OzFileUpload from "../pageComponents/OzFileUpload/OzFileUpload";
import GoogleAnalytics from "../pageComponents/GoogleAnalytics/GoogleAnalytics";
import VMixCall from "../pageComponents/VMixCall/VMixCall";
import LiveStream from "../pageComponents/LiveStream/LiveStream"
import firebase from 'firebase/app';
import 'firebase/analytics';
import OzSlider from "../pageComponents/OzSlider/OzSlider";
import OzInput from "../pageComponents/OzInput/OzInput";
import OzMap from "../pageComponents/OzMap/OzMap";
import OzMapMarker from "../pageComponents/OzMap/OzMapMarker";

const jsonEscape = (str) => {
    return str.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t").replace(/\\/g, "\\\\");
}

const jsonUnescape = (str) => {
    const enterChar = `

`; // Double Enter For MarkDown
    return str.split("\\\\").map(s =>
        s.split("\\r\\n").join(enterChar)
            .split("\\n").join(enterChar)
            .split("\\r").join(enterChar)
    ).join("\\");

    // `))
    // return str.split("\\"); replace(/\\\\/g, "\\").replace(/\\n/g, "\n").replace(/\\r/g, "\r").replace(/\\t/g, "\t");
}

let loadedSubpages = []

export const getOzBoolean = (val, ozState, props) => {
    try {
        if (!val) return false;
        let ozValue = parseOzText(val, ozState, props);
        const opposite = ozValue.startsWith("!")
        if (opposite) {
            ozValue = ozValue.substr(1);
        }
        if (ozValue.indexOf("=if") === 0) {
            const vals = JSON.parse(ozValue.substr(3));
            return (vals[0] === vals[1]) === !opposite;
        }
        return Boolean(ozValue) === !opposite;
    }
    catch (err) {
        return false;
    }
}

export const getOzRepeaterArray = (repeaterFormula, ozState, props) => {
    if (!repeaterFormula) return [];
    let repeatArray = [];
    try {
        const formula = JSON.parse(repeaterFormula);
        if (formula.type === "dataset") {
            repeatArray = props.pageDatasets[formula.dataset] || [];
        }
        if (formula.type === "custom") {
            repeatArray = runOzCustom(formula.action, ozState, props);
        }
        if (formula.type === "fixed") {
            repeatArray = formula.array;
        }

        if (formula.type === "firestore") {
            const isEmpty = registerFirestoreDataset(formula, (data) => {
                if (!props.pageDatasets[formula.dataset] ||
                    props.pageDatasets[formula.dataset].length + data.length > 0)
                    props.setStateObject("pageDatasets", { [formula.dataset]: data })
            })

            repeatArray = isEmpty ? [] : props.pageDatasets[formula.dataset] || [];
        }
        if (formula.type === "nested") {
            const [table, field] = formula.field.split(".");
            repeatArray = ozState[table][field];
        }
    }
    catch (e) {
        console.log(e.message, repeaterFormula);
        return [];
    }
    return repeatArray;
}


export const getOzAction = (action, ozState, props) => {
    try {
        const act = JSON.parse(jsonEscape(parseOzText(action, ozState, props)));
        if (act.type === "ozState") {
            return () => {
                props.setStateObject("ozState", act.ozState);
                if (act.callback) {
                    const callbackAction = JSON.stringify(act.callback);
                    getOzAction(callbackAction, ozState, props)();
                }
            }
        }
        if (act.type === "post") {
            return () => {
                post(act.url, act.props, act.method).then(res => {
                    if (act.callback) {
                        let callbackAction = JSON.stringify(act.callback);
                        if (act.callback.responseKey) callbackAction = callbackAction.replace(new RegExp(act.callback.responseKey, 'g'), res.res);
                        getOzAction(callbackAction, ozState, props)();
                    }
                })
                    .catch(msg => {
                        console.log({ error: msg });
                        let callbackAction = JSON.stringify(act.callback);
                        if (act.callback.responseKey) callbackAction = callbackAction.replace(new RegExp(act.callback.responseKey, 'g'), msg);
                        getOzAction(callbackAction, ozState, props)();
                    })
            }
        }
        if (act.type === "custom_js") {
            return () => {
                const res = runOzCustom(act.action, ozState, props);
                if (act.callback) {
                    let callbackAction = JSON.stringify(act.callback);
                    if (act.callback.responseKey) callbackAction = callbackAction.replace(new RegExp(act.callback.responseKey, 'g'), res);
                    getOzAction(callbackAction, ozState, props)();
                }
                // if (res.ozCallback) {
                //     const callbackAction = JSON.stringify(res.ozCallback);
                //     getOzAction(callbackAction, ozState, props)();
                // }
            }
        }
        if (act.type === "ga") {
            return () => {
                firebase.analytics().logEvent(act.log, act.options);
                if (act.callback) {
                    let callbackAction = JSON.stringify(act.callback);
                    getOzAction(callbackAction, ozState, props)();
                }
            }
        }

        return () => { }
    }
    catch (err) {
        console.log({ action, ozState })
        console.log(jsonEscape(parseOzText(action, ozState, props)))
        return () => alert(err.message + " (getOzAction)")
    }
}


export const runOzCustom = (action, ozState, props) => {
    const { userInfo, pageDatasets } = props;
    const res = window.ozCustomJs(action, { ...ozState, userInfo, pageDatasets });
    if (res?.action) {
        getOzAction(res.action, ozState, props);
    }
    if (res?.ozState) {
        props.setStateObject("ozState", res.ozState)
    }
    if (res?.val?.ozCallback) {
        const callbackAction = JSON.stringify(res.val.ozCallback);
        getOzAction(callbackAction, ozState, props)();
    }

    return res?.val;
}

export const parseOzText = (txt, ozState, props) => {
    if (!txt) return "";
    let i = 0;
    while (txt.indexOf("$[ozjs-") >= 0 && i < 100) {
        i++;
        const action = txt.split("$[ozjs-")[1]?.split("]")[0];
        if (!action) return txt;
        const actionVal = String(runOzCustom(action, ozState, props));
        txt = txt.replace(`$[ozjs-${action}]`, actionVal?.trim());
    }

    i = 0;
    while (txt.indexOf("$[ozuser-") >= 0 && i < 100) {
        i++;
        const userField = txt.split("$[ozuser-")[1]?.split("]")[0];
        if (!userField) return txt;
        const userVal = String(props.userInfo[userField] || "");
        txt = txt.replace(`$[ozuser-${userField}]`, userVal?.trim());
    }


    i = 0;
    while (txt.indexOf("$[oz-") >= 0 && i < 100) {
        i++;
        const rule = txt.split("$[oz-")[1]?.split("]")[0];
        if (!rule) return txt;
        let ruleVal = ozState;
        rule.split(".").forEach(r => { ruleVal = ruleVal[r] ?? "" });
        if (typeof ruleVal === 'object') {
            ruleVal = JSON.stringify(ruleVal);
        }
        txt = txt.replace(`$[oz-${rule}]`, String(ruleVal)?.trim().replace('"', '″'));
    }

    i = 0;
    while (txt.indexOf("$[ozfb-") >= 0 && i < 100) {
        const { hostname } = window.location;
        if (!ozState.fbState) registerFbState("fbState", `projects/${hostname}`, (fbState) => {
            props.setStateObject("ozState", { fbState })
        })

        i++;
        const rule = txt.split("$[ozfb-")[1]?.split("]")[0];
        if (!rule) return txt;
        let ruleVal = ozState.fbState || {};
        rule.split(".").forEach(r => { ruleVal = ruleVal[r] ?? "" });
        if (typeof ruleVal === 'object') {
            ruleVal = JSON.stringify(ruleVal);
        }

        txt = txt.replace(`$[ozfb-${rule}]`, String(ruleVal)?.trim());
    }

    while (txt.indexOf("$[ozfbuser-") >= 0 && i < 100) {
        const { hostname } = window.location;
        const { userInfo } = props;
        if (userInfo?.hashkey && !ozState.fbState) registerFbState("fbUserState", `projects/${hostname}/userData/${userInfo.hashkey}`, (fbUserState) => {
            props.setStateObject("ozState", { fbUserState })
        })

        i++;
        const rule = txt.split("$[ozfbuser-")[1]?.split("]")[0];
        if (!rule) return txt;
        let ruleVal = ozState.fbUserState || {};
        rule.split(".").forEach(r => { ruleVal = ruleVal[r] ?? "" });
        if (typeof ruleVal === 'object') {
            ruleVal = JSON.stringify(ruleVal);
        }

        txt = txt.replace(`$[ozfbuser-${rule}]`, String(ruleVal)?.trim());
    }

    if (txt.indexOf("$[ozpage-") >= 0) {
        const pagename = txt.split("$[ozpage-")[1]?.split("]")[0];
        return buildComponent({ type: "page", props: { pagename }, style: [] }, 0, [], ozState, props)
    }

    return txt;
}


const getPropVal = (val, prop, ozState, props) => {
    if (prop) {
        if (prop[1] === "number") return Number(val);
    }
    if (prop) {
        if (prop[1] === "boolean") return val;
    }
    return parseOzText(val, ozState, props);
}

export const buildStyle = (array, ozState, props) => {
    return (array || []).filter(([key, val]) => isNaN(key)).reduce((prevVal, [key, val]) => ({ ...prevVal, [parseOzText(key, ozState, props)]: parseOzText(val, ozState, props) }), {})
}

export const closeFullscreen = () => {
    const elm = window.document;

    if (elm.fullscreenElement && elm.exitFullscreen) {
        elm.exitFullscreen();
        return;
    }

    if (elm.webkitFullscreenElement && elm.webkitExitFullscreen) { /* Safari */
        elm.webkitExitFullscreen();
        return;
    }

    if (elm.msFullscreenElement && elm.msExitFullscreen) { /* IE11 */
        elm.msExitFullscreen();
        return;
    }
    if (!elm.exitFullscreen && !elm.webkitExitFullscreen && !elm.msExitFullscreen && document.activeElement.tagName === "IFRAME") window.location.reload();

}

export const buildComponent = (cmp, idx, pageStructure, ozState, props) => {
    if (!cmp) return null;
    const { props: propsArray = {}, style: styleArray = {} } = cmp;
    const components = pageStructure.filter(p => p.parent === cmp.id);
    const ctype = cmpTypes.find(t => t.code === cmp.type)
    const style = buildStyle(styleArray, ozState, props);

    const cmpProps = {};
    if (ctype) ctype.props.forEach(ct => {
        if (propsArray[ct[0]] && propsArray[ct[0]].length > 0) cmpProps[ct[0]] = getPropVal(propsArray[ct[0]], ct, ozState, props)
    })

    if (cmp.className && cmp.className.length > 0) cmpProps.className = parseOzText(cmp.className, ozState, props);

    if (cmp.type === "div") {
        const { clickAction, ...othProps } = cmpProps;
        if (clickAction) othProps.onClick = getOzAction(clickAction, ozState, props);
        return <div key={idx} {...othProps} style={style}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</div>;
    }

    if (cmp.type === "html") {
        let elmProps = {};
        try {
            elmProps = JSON.parse(cmpProps.props || "{}");
        }
        catch (err) {
            return null;
        }
        elmProps.key = idx;
        elmProps.style = style;
        if (cmpProps.clickAction) elmProps.onClick = getOzAction(cmpProps.clickAction, ozState, props);
        return React.createElement(cmpProps.element || "div", elmProps,
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        );
    }

    if (cmp.type === "paper")
        return <Paper key={idx} {...cmpProps} style={style}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</Paper>;

    if (cmp.type === "img")
        return <img key={idx} {...cmpProps} style={style} alt="" />;

    if (cmp.type === "grid-container")
        return <Grid container key={idx} {...cmpProps} style={style}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</Grid>;

    if (cmp.type === "grid-item")
        return <Grid item key={idx} {...cmpProps} style={style}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</Grid>;

    if (cmp.type === "list") {
        return <List key={idx} style={style}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</List>;
    }

    if (cmp.type === "list-item") {
        const listProps = {};
        if (cmpProps.clickAction) {
            listProps.onClick = getOzAction(cmpProps.clickAction, ozState, props);
            listProps.button = true;
        }
        const withoutItem = getOzBoolean(cmpProps.withoutItem, ozState, props);

        listProps.className = cmpProps.className;
        return <ListItem key={idx} style={style} {...listProps}>
            {!withoutItem ?
                components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
                :
                <ListItemText>{
                    components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
                }</ListItemText>
            }
        </ListItem>;
    }

    if (cmp.type === "list-item-text") {
        const listProps = {};
        listProps.primary = cmpProps.primary;
        listProps.secondary = cmpProps.secondary;
        listProps.className = cmpProps.className;

        return <ListItemText key={idx} style={style} {...listProps} />;
    }

    if (cmp.type === "list-item-avatar") {
        const listProps = {};
        listProps.className = cmpProps.className;

        return <ListItemAvatar key={idx} style={style} {...listProps}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</ListItemAvatar>;
    }

    if (cmp.type === "link") {
        const { href, target, className } = cmpProps;
        const listProps = { href, target, className }
        if (cmpProps.clickAction)
            listProps.onClick = getOzAction(cmpProps.clickAction, ozState, props);
        return <a key={idx}  {...listProps} style={style}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</a>;
    }

    if (cmp.type === "avatar") {
        const firstLetter = cmpProps.firstLetter?.substr(0, 1);
        const secondLetter = cmpProps.secondLetter?.substr(0, 1);
        return <Avatar key={idx} style={{ fontSize: "1em", fontWeight: "bold", ...style }} className={cmpProps.className}>{
            `${firstLetter}${secondLetter}`}{
                components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
            }</Avatar>;

    }


    if (cmp.type === "txt")
        return parseOzText(cmpProps.text, ozState, props);


    if (cmp.type === "md-txt") {
        const hrefBlank = getOzBoolean(cmpProps.hrefBlank, ozState, props);
        const renderers = hrefBlank ? { link: props => <a href={props.href} target="_blank" rel="noreferrer">{props.children}</a> } : {};
        return <div key={idx} className={cmpProps.className} style={style}><ReactMarkdown
            renderers={renderers}
        >{
                jsonUnescape(parseOzText(cmpProps.text, ozState, props))
            }</ReactMarkdown></div>;
    }

    if (cmp.type === "ts") {
        const ts = parseOzText(cmpProps.ts, ozState, props);
        const dateFormat = parseOzText(cmpProps.format, ozState, props);
        let strdate = "????";
        try {
            strdate = format(new Date(ts * 1000), dateFormat) //"dd/MM/yyyy HH:mm")     
        } catch { }
        return strdate;
    }

    if (cmp.type === "container")
        return <Container key={idx} {...cmpProps} style={style}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</Container>;

    if (cmp.type === "page") {
        const pagename = parseOzText(cmpProps.pagename, ozState, props);
        if (props.detailStructures[pagename]) {
            const topCmp = props.detailStructures[pagename].find(p => p.parent === -1);
            return buildComponent(topCmp, `${idx}-0`, props.detailStructures[pagename], ozState, props);
        }
        else {
            if (loadedSubpages.indexOf(pagename) < 0) {
                props.setDetailStructure(pagename, [], {});
                loadedSubpages.push(pagename);
                post("get-page", { url: pagename, sbpg: true }).then(res => {
                    props.setDetailStructure(pagename, res.structure, res.datarows);
                });
            }
        }
        return null;
    }

    if (cmp.type === "repeater") {
        let repeatArray = getOzRepeaterArray(cmpProps.formula, ozState, props);

        if (!repeatArray.map) return null;
        if (cmpProps.filter)
            repeatArray = repeatArray.filter((arrayRow, ridx) => getOzBoolean(
                cmpProps.filter,
                { ...ozState, [cmpProps.alias]: { ...arrayRow, ozRowNum: ridx } },
                props
            ))
        return repeatArray.map((arrayRow, ridx) =>
            components.map((c, cidx) => buildComponent(c, `${ridx}-${cidx}`, pageStructure, { ...ozState, [cmpProps.alias]: { ...arrayRow, ozRowNum: ridx } }, props))
        )
    }

    if (cmp.type === "alias") {
        const val = parseOzText(cmpProps.value, ozState, props);
        return components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, { ...ozState, [cmpProps.alias]: val }, props));
    }


    if (cmp.type === "if") {
        const val1 = parseOzText(cmpProps.value1, ozState, props);
        const val2 = parseOzText(cmpProps.value2, ozState, props);
        const isNotEqual = getOzBoolean(cmpProps.isNotEqual, ozState, props);

        if ((val1 === val2 && !isNotEqual) || (val1 !== val2 && isNotEqual)) {
            return components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props));
        }
    }

    if (cmp.type === "button") {
        const buttonProps = {}

        if (cmpProps.clickAction) buttonProps.onClick = getOzAction(cmpProps.clickAction, ozState, props);
        buttonProps.fullWidth = getOzBoolean(cmpProps.fullWidth, ozState, props);
        if (getOzBoolean(cmpProps.primary, ozState, props)) buttonProps.color = "primary";
        if (getOzBoolean(cmpProps.secondary, ozState, props)) buttonProps.color = "secondary";
        buttonProps.disabled = getOzBoolean(cmpProps.disabled, ozState, props);
        if (cmpProps.href) buttonProps.href = cmpProps.href
        if (cmpProps.target) buttonProps.target = cmpProps.target
        if (cmpProps.variant) buttonProps.variant = cmpProps.variant
        return <Button className={cmpProps.className} key={idx} variant="contained" {...buttonProps} style={style}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</Button>;
    }

    if (cmp.type === "button-group") {
        return <ButtonGroup key={idx} {...cmpProps} style={style}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</ButtonGroup>;
    }

    if (cmp.type === "input") {
        const fullWidth = getOzBoolean(cmpProps.fullWidth, ozState, props);
        const disabled = getOzBoolean(cmpProps.disabled, ozState, props);
        const shrink = getOzBoolean(cmpProps.shrink, ozState, props);
        const multiline = getOzBoolean(cmpProps.multiline, ozState, props)        
        return <OzInput cmpProps={cmpProps} setStateObject={props.setStateObject} ozState={ozState} key={idx} fullWidth ={fullWidth} disabled={disabled} shrink={shrink} multiline={multiline} />
    }

    if (cmp.type === "select") {
        const optionsArray = getOzRepeaterArray(cmpProps.options, ozState, props) || [];
        return <OzSelect cmpProps={cmpProps} setStateObject={props.setStateObject} ozState={ozState} key={idx} optionsArray={optionsArray} />
    }

    if (cmp.type === "slider") {
        const optionsArray = getOzRepeaterArray(cmpProps.options, ozState, props) || [];
        return <OzSlider cmpProps={cmpProps} setStateObject={props.setStateObject} ozState={ozState} key={idx} optionsArray={optionsArray} />
    }

    if (cmp.type === "menu") {
        const optionsArray = getOzRepeaterArray(cmpProps.options, ozState, props) || [];
        const detailCmp = components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props));
        const callback = (action) => getOzAction(action, ozState, props)()
        return <OzMenu cmpProps={cmpProps} key={idx} optionsArray={optionsArray} detailCmp={detailCmp} callback={callback} />
    }

    if (cmp.type === "checkbox") {
        const { fieldname, color, trueValue } = cmpProps;
        return (
            <FormControlLabel
                key={idx}
                className={cmpProps.className}
                control={<Checkbox
                    color={color}
                    checked={props.ozState[fieldname] || false}
                    onChange={event => props.setStateObject("ozState", { [fieldname]: event.target.checked && trueValue ? trueValue : event.target.checked })}
                />}
                label={(
                    <>
                        {components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))}
                    </>)}
            />);
    }

    if (cmp.type === "badge") {
        const invisible = getOzBoolean(cmpProps.invisible, ozState, props);

        return <Badge key={idx} className={cmpProps.className} style={style} badgeContent={cmpProps.value} invisible={!cmpProps.value || invisible} color="secondary">{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</Badge>;
    }


    if (cmp.type === "table")
        return <Table key={idx} {...cmpProps} style={style}><TableBody>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</TableBody></Table>;

    if (cmp.type === "table-row")
        return <TableRow key={idx} {...cmpProps} style={style}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</TableRow>;

    if (cmp.type === "table-cell")
        return <TableCell key={idx} {...cmpProps} style={style}>{
            components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
        }</TableCell>;

    if (cmp.type === "cookies-banner")
        return <CookieConsent
            location="bottom"
            buttonText="Αποδοχή cookies"
            cookieName="ozCookie"
            style={{ backgroundColor: "rgba(0,0,0,0.75)", ...style }}
            buttonStyle={{ color: "#4e503b", borderRadius: "10px", fontSize: "15px", padding: "15px" }}
            expires={365}
            key={idx} {...cmpProps}>{
                components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
            }</CookieConsent>;

    if (cmp.type === "live-stream") {
        let streamCode = parseOzText(cmp.props.code, ozState, props);
        if (streamCode.indexOf(">>>") < 0) streamCode = ozState[streamCode] || ">>>";
        const hashkey = props.userInfo?.hashkey;
        console.log(ozState)
        return <LiveStream key={idx} cmpProps={cmpProps} style = {style} streamCode = {streamCode} hashkey = {hashkey} />        
    }

    if (cmp.type === "dialog") {
        const fullWidth = getOzBoolean(cmpProps.fullWidth, ozState, props);
        const maxWidth = parseOzText(cmpProps.maxWidth, ozState, props) || false;
        const open = ozState[cmpProps.openField] || false;
        if (open) closeFullscreen();

        const doClose = () => {

            props.setStateObject("ozState", { [cmpProps.openField]: false })
        }

        const onClose = () => {
            if (getOzBoolean(cmpProps.autoClose, ozState, props)) {
                doClose();
            }
        }

        const dialogTitle = cmpProps.title &&
            <DialogTitle>{cmpProps.title}
                <IconButton
                    style={{
                        position: 'absolute',
                        right: "10px",
                        top: "10px",
                        color: "#888",
                        zIndex: 999
                    }}
                    onClick={doClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>;

        return <Dialog
            key={idx}
            className={cmpProps.className}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
        >
            {dialogTitle}
            <DialogContent>{
                components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props))
            }</DialogContent>
        </Dialog>
    }


    if (cmp.type === "cake") {
        return <Test key={idx} />;
    }

    if (cmp.type === "zoom-meeting") {
        return <ZoomMeeting meetingNumber={cmpProps.meetingNumber} key={idx} />;
    }

    if (cmp.type === "twilio-meeting") {
        return <TwilioMeeting key={idx} />;
    }

    if (cmp.type === "chat-box") {
        return <ChatBox user={cmpProps.user} key={idx} />;
    }

    if (cmp.type === "fb-chat-box") {
        return <FirebaseChatBox user={cmpProps.user} key={idx} />;
    }
    if (cmp.type === "fb-chat-add-contact-btn") {
        return <AddContactBtn key={idx} />;
    }

    if (cmp.type === "countdown") {
        return <OzCountDown style={style} key={idx} cmpProps={cmpProps} />;
    }

    if (cmp.type === "twitter-tweet") {
        return <TwitterTweet style={style} key={idx} cmpProps={cmpProps} />;
    }
 
    if (cmp.type === "facebook-provider") {
        const detailCmp = components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props));
        return <OzFacebookProvider detailCmp={detailCmp} key={idx} cmpProps={cmpProps} />;
    }

    if (cmp.type === "facebook-post") {
        return <OzFacebookPost style={style} key={idx} cmpProps={cmpProps} />;
    }

    if (cmp.type === "file-upload") {

        if (cmpProps.filenameProp)
            cmpProps.callbackSetState = filename => props.setStateObject("ozState", { [cmpProps.filenameProp]: filename })

        const detailCmp = components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props));
        return <OzFileUpload key={idx} cmpProps={cmpProps} detailCmp={detailCmp} />;
    }

    if (cmp.type === "google-analytics") {
        return <GoogleAnalytics key={idx} cmpProps={cmpProps} />
    }

    if (cmp.type === "vmix-call") {
        return <VMixCall key={idx} cmpProps={cmpProps} />
    }

    if (cmp.type === "map") {
        const detailCmp = components.map((c, cidx) => buildComponent(c, `${idx}-${cidx}`, pageStructure, ozState, props));
        return <OzMap key={idx} cmpProps={cmpProps} detailCmp={detailCmp} />;
    }

    if (cmp.type === "map-marker") {
        return OzMapMarker(idx, cmpProps);
    }

    return null;
}
