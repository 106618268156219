import React from "react";
import { connect } from "react-redux";

import { setState } from "../../store/actions";
import { loadData, post } from "../../lib/api";
import { Box, Button, Paper } from "@material-ui/core";
import ActionBatchPrepare from "./ActionBatchPrepare";
import ActionBatchResults from "./ActionBatchResults";

class ActionBatchProcess extends React.Component {


  componentDidMount() {
    loadData("data/actionBatches", "actionBatch", this.props.id).then(res => {
      post("get-module-data", { module: res.module_code }).then(res => {
        this.props.setState({ moduleData: res.data, moduleRow: res.module })
      });
      post("get-action-logs", { batch: this.props.id }).then(res => {
        this.props.setState({ actionLogs: res, loaded: true })
      });
    });
  }

  async runProcess() {
    const module = this.props.modulesList.find(m => m.id === this.props.actionBatch.module);
    const actionTitle = module?.setup.actions?.find(a => a.code === this.props.actionBatch.action_code)?.title || "Execute unknown action";
    const moduleName = this.props.moduleRow.name || "";
    const cnt = this.props.actionLogs.filter(al => !al.ts).length;
    const confirmAction = window.confirm(
      `${actionTitle} to ${cnt} ${moduleName}?`
    );
    if (!confirmAction) return;

    await this.props.setState({executing: true})
    this.runNextRow()
  }

  async runNextRow(){
    if(this.props.execID)return;
    if(!this.props.executing)return;
    const nextRow = this.props.actionLogs.find(al => !al.ts);
    if(!nextRow){
      await this.props.setState({executing: false})
      return;
    }
    await this.props.setState({execID: nextRow.id})
    const moduleData = this.props.moduleData.find(md => md.id === nextRow.row);
    const module = this.props.modulesList.find(m => m.id === this.props.actionBatch.module);
    const action = module?.setup.actions?.find(a => a.code === this.props.actionBatch.action_code) || {};

    post(`call-module-action`, { action, hashkey: moduleData.hashkey, batch_id: nextRow.batch })
      .then(res => {
        console.log(res);
        const actionLogs = this.props.actionLogs.map(al => al.id === nextRow.id ? res : al);
        this.props.setState({actionLogs, execID: null});
        this.runNextRow()
      })
      .catch(msg => {
        this.props.setState({ saving: false });
        console.log(msg);
        return alert(msg);
      });
  }

  stopProcess() {
    this.props.setState({executing: false})
  }

  render() {
    let enableExecute = false;
    let status = "init";
    if (this.props.loaded) {
      status = "prepare";
      if (this.props.actionLogs.filter(al => al.ts).length > 0) status = "executed";
      if (this.props.execID) status = "executed";
      enableExecute = this.props.actionLogs.filter(al => !al.ts).length > 0 && !this.props.executing && !this.props.execID
    }
    return (
      <Box style={{ flexGrow: 1, margin: "20px", display: "flex", flexDirection: "column" }}>

        <Paper>
          <h2 style={{ padding: "0px 20px" }}>
            Action Batch ({this.props.actionBatch.comments})
              {enableExecute && <Button onClick={() => this.runProcess()} style={{ float: "right" }}>Execute</Button>}
              {this.props.executing && <Button onClick={() => this.stopProcess()} style={{ float: "right" }}>Stop</Button>}
          </h2>
        </Paper>
        {status === "prepare" && <ActionBatchPrepare />}
        {status === "executed" && <ActionBatchResults />}
      </Box >

    );
  }
}
ActionBatchProcess.defaultProps = {
  actionBatch: {},
  moduleData: [],
  actionLogs: [],
  modulesList: [],
  loaded: false,
  executing: false,
  moduleRow: {}
};

const mapStateToProps = state => {
  return {
    actionBatch: state.actionBatch,
    moduleData: state.moduleData,
    actionLogs: state.actionLogs,
    loaded: state.loaded,
    executing: state.executing,
    modulesList: state.modulesList,
    execID: state.execID,
    moduleRow: state.moduleRow
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionBatchProcess);
