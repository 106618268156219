import React from 'react';
import { getOzBoolean } from '../../common/ozCompiler';
import { Slider } from '@material-ui/core';

export default function OzSelect({ cmpProps, setStateObject, ozState }) {
    const { fieldnameFrom, fieldnameTo, valueLabelDisplay="auto", min, max, labels, className, size = "normal", style } = cmpProps;
    const disabled = getOzBoolean(cmpProps.disabled, ozState);

    const marks =  labels ? (ozState[labels] || []).map((label,idx) => ({ value: idx + Number(min), label})) : [];
    const value = fieldnameTo ? [ozState[fieldnameFrom], ozState[fieldnameTo]] : ozState[fieldnameFrom];
    const handleOnChange = (event, val) => {
        if (fieldnameTo) setStateObject("ozState", { [fieldnameFrom]: val[0], [fieldnameTo]: val[1] });
        else setStateObject("ozState", { [fieldnameFrom]: val });
    };

    return <Slider
        style={style} className={className}
        value={value}
        size={size}
        marks={marks}
        min={Number(min)}
        step={1}
        valueLabelFormat={(v) => marks.find(m => m.value === v)?.label || v}
        max={Number(max)}
        disabled={disabled}
        onChange={handleOnChange}
        valueLabelDisplay={valueLabelDisplay}
    />;
}