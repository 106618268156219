import React, { Fragment } from "react";
import { connect } from "react-redux";
import { setState } from "../../store/actions";
import { List, ListItem } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { cmpTypes } from "../../common/const"

class PageStructureList extends React.Component {

  renderList(node, level) {
    const children = this.props.pageStructure.filter(p => p.parent === node.id);
    const selected = node.id === this.props.selectedComponent;
    const ctype = cmpTypes.find(t => t.code === node.type)

    const name = node.name.length > 0 ? node.name : <span style={{ color: "#888", fontStyle:"italic" }}>({ctype?.name})</span>
    return (
      <Fragment key = {node.id}>
        <ListItem selected={selected} button style={{ paddingLeft: `${level * 10}px` }} onClick={() => this.props.setState({ selectedComponent: node.id })}>
          <ArrowForwardIosIcon />{name}
        </ListItem>
        {children.map(c => this.renderList(c, level + 1))}
      </Fragment>
    )
  }

  render() {
    const node = this.props.pageStructure.find(p => p.parent === -1);
    return <List>
      {this.renderList(node, 0)}
    </List>;
  }

}

PageStructureList.defaultProps = {
  pageStructure: []
};

const mapStateToProps = state => {
  return {
    selectedComponent: state.selectedComponent,
    pageStructure: state.pageStructure,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageStructureList);

