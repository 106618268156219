import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { crud, post, loadData } from "../../lib/api";
import { setState } from "../../store/actions";
import ImageComposerBgr from "./ImageComposerBgr";
import ImageComposerTxt from "./ImageComposerTxt";
import ImageComposerImg from "./ImageComposerImg";

class ImageComposer extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.composedImageRow !== this.props.composedImageRow)
      this.props.setState({ composedImageData: null });
  }

  doPreview() {
    const items = this.props.composedImageRow.design;
    post("image-preview", { items }).then(res => {
      this.props.setState({ composedImageData: res.data });
    });
  }

  doSave() {
    if (this.saving) return;
    this.saving = true;
    let id = this.props.composedImageRow.id;
    let data = this.props.composedImageRow;
    crud("images", id, data)
      .then(json => {
        this.props.setState({ composedImageRow: null });
        loadData(`data/images`, "imagesList").then(() => {
          this.saving = false;
        });
      })
      .catch(res => {
        this.saving = false;
      });
  }

  addItem(type) {
    const design = [...this.props.composedImageRow.design];
    design.push({ type });
    const newRow = { ...this.props.composedImageRow, design };
    this.props.setState({ composedImageRow: newRow });
  }

  removeItem(idx) {
    const design = this.props.composedImageRow.design.filter(
      (_, rowIdx) => rowIdx !== idx
    );
    const newRow = { ...this.props.composedImageRow, design };
    this.props.setState({ composedImageRow: newRow });
  }

  editItem(field, value, idx) {
    const design = this.props.composedImageRow.design.map((row, rowIdx) =>
      idx === rowIdx ? { ...row, [field]: value } : row
    );
    const newRow = { ...this.props.composedImageRow, design };
    this.props.setState({ composedImageRow: newRow });
  }

  renderItemRow(item, idx) {
    if (item.type === "background")
      return (
        <ImageComposerBgr
          key={idx}
          idx={idx}
          item={item}
          editItem={(field, value) => this.editItem(field, value, idx)}
        />
      );
    if (item.type === "text")
      return (
        <ImageComposerTxt
          key={idx}
          idx={idx}
          item={item}
          removeItem={() => this.removeItem(idx)}
          editItem={(field, value) => this.editItem(field, value, idx)}
        />
      );
    if (item.type === "image")
      return (
        <ImageComposerImg
          key={idx}
          idx={idx}
          item={item}
          removeItem={() => this.removeItem(idx)}
          editItem={(field, value) => this.editItem(field, value, idx)}
        />
      );
    return null;
  }

  render() {
    // const { module_type, name } = this.props.composedImageRow || {};
    // const moduleType = moduleTypes.find(m => m.id === module_type) || {};
    const items = this.props.composedImageRow?.design || [];
    return (
      <Dialog fullWidth maxWidth="md" open={this.props.composedImageRow}>
        <DialogTitle>Image Composer</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} container spacing={2}>
              {items.map((item, idx) => this.renderItemRow(item, idx))}
              <Grid item xs={12}>
                {items.length === 0 ? (
                  <Button onClick={() => this.addItem("background")}>
                    Add Background
                  </Button>
                ) : (
                  <>
                    <Button onClick={() => this.addItem("text")}>
                      Add Text
                    </Button>
                    <Button onClick={() => this.addItem("image")}>
                      Add Image
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ position: "relative" }}>
              <img
                alt=""
                style={{ width: "100%" }}
                src={`data:image/png;base64, ${this.props.composedImageData}`}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "25px" }}>
          <Box style={{ flexGrow: 1 }}></Box>
          <Button
            onClick={() => this.props.setState({ composedImageRow: null })}
            variant="contained"
          >
            Cancel
          </Button>
          {this.props.composedImageData ? (
            <Button
              onClick={() => this.doSave()}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          ) : (
            <Button
              onClick={() => this.doPreview()}
              variant="contained"
              color="primary"
            >
              Preview
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}
ImageComposer.defaultProps = {
  imagesList: [],
  composedImageRow: null,
  composedImageData: null
};

const mapStateToProps = state => {
  return {
    imagesList: state.imagesList,
    composedImageRow: state.composedImageRow,
    composedImageData: state.composedImageData
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageComposer);
