import React, { Fragment } from 'react';
import OzCountDigitBottom from './OzCountDigitBottom';
import OzCountDigitTop from './OzCountDigitTop';
import "./OzCountDown.css";

export default function OzCountDigit({ pos, val }) {


    const style = {
        left: `${50 * pos}%`
    }

    return <div className="oz-countdown-digit" style={style}>
        <div className="oz-countdown-digit-paper shadow" />
        <OzCountDigitTop digit={9} val={10} /> 
        <OzCountDigitBottom digit={0} val={-1} />        
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((key) =>
            <Fragment key={key} >
                <OzCountDigitTop digit={key} val={val} /> 
                <OzCountDigitBottom digit={9 - key} val={val} /> 
            </Fragment>
        )
        }
    </div>;
}