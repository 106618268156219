import React, { useState } from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setState } from "../../store/actions";
import { post } from "../../lib/api";
export default function SetupSettings() {
    const dispatch = useDispatch();
    const initSetupData = {
        setupSettings: {
            title: "",
            subtitle: "",
            secret: ""
        }
    }
    const setupData = useSelector(({ setupData }) => setupData) || initSetupData;
    const { setupSettings: { title, subtitle, secret } } = setupData;
    const [saved, setSaved] = useState(false);
    const setValue = (field, value) => {
        const newSetupData = {
            ...setupData,
            setupSettings: {
                ...setupData.setupSettings,
                [field]: value
            }
        }
        dispatch(setState({ setupData: newSetupData }));
        setSaved(false);
    }

    const handleSave = () => {
        const {setupSettings} = setupData;
        post("setup/", {setupSettings})
            .then(json => {
                setSaved(true);
            });
    }

    return (
        <Grid container spacing={2} style={{maxWidth:"700px"}}>
            <Grid item xs={12}>
                <TextField value={title} onChange={(e) => { setValue("title", e.target.value) }} label="Title" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField value={subtitle} onChange={(e) => { setValue("subtitle", e.target.value) }} label="Subtitle" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={8}>
                <TextField value={secret} onChange={(e) => { setValue("secret", e.target.value) }} type="password" label="Secret" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs style={{ textAlign: "right" }}>
                <Button onClick={handleSave} size="large" variant="contained" color="primary">Update</Button>
            </Grid>
            {saved &&
                <Grid item xs={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                    Update Completed Succesfully
                </Grid>
            }
        </Grid>);
}