import { Button, Grid, TableBody, TableRow, Table, TableCell, TableHead, TextField, IconButton, Select, MenuItem } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { setState } from "../../store/actions";
import ClearIcon from '@material-ui/icons/Clear';
import { playConsoleWidgets } from "../../common/const";

class PlayConsoleStructureList extends React.Component {

    addField() {
        const { structure = [] } = this.props.editSettingsRow || {};
        const newStructure = [...structure, {
            descr: "",
            type: "",
            properties: ""
        }];

        const editSettingsRow = {
            ...this.props.editSettingsRow,
            structure: newStructure
        }

        this.props.setState({ editSettingsRow })
    }

    setFieldVal(fieldName, value, idx) {
        const { structure = [] } = this.props.editSettingsRow || {};
        const newStructure = structure.map((mp, mpIdx) => mpIdx === idx ? { ...mp, [fieldName]: value } : mp);

        const editSettingsRow = {
            ...this.props.editSettingsRow,
            structure: newStructure
        }

        this.props.setState({ editSettingsRow })

    }

    delField(idx) {
        const { structure = [] } = this.props.editSettingsRow || {};
        const newStructure = structure.filter((mp, mpIdx) => mpIdx !== idx);

        const editSettingsRow = {
            ...this.props.editSettingsRow,
            structure: newStructure
        }

        this.props.setState({ editSettingsRow })
    }

    getPlaceHolder(type){
        return playConsoleWidgets.find(ph => ph.id === type)?.propshint;
    }

    render() {
        const { structure = [] } = this.props.editSettingsRow || {};

        return <Grid container style={{ background: "#eee", border: "1px solid black" }}>
            <Grid item xs={12} style={{ padding: "5px" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Widget</TableCell>
                            <TableCell>Properties</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            structure.map((mp, mpIdx) =>
                                <TableRow key={mpIdx}>
                                    <TableCell><TextField fullWidth value={mp.descr || ""} onChange={(e) => this.setFieldVal("descr", e.target.value, mpIdx)} /></TableCell>
                                    <TableCell>
                                        <Select
                                            fullWidth
                                            value={mp.type}
                                            onChange={e => this.setFieldVal("type", e.target.value, mpIdx)}
                                        >
                                            {playConsoleWidgets.map((row, idx) => (
                                                <MenuItem key={`listitem_${idx}`} value={row.id}>
                                                    {row.descr}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </TableCell>
                                    <TableCell><TextField fullWidth placeholder={this.getPlaceHolder(mp.type)} value={mp.props || ""} onChange={(e) => this.setFieldVal("props", e.target.value, mpIdx)} /></TableCell>
                                    <TableCell><IconButton disabled={mp.readonly} onClick={() => this.delField(mpIdx)} ><ClearIcon /></IconButton></TableCell>
                                </TableRow>)
                        }

                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} style={{ padding: "5px" }}>
                <Button onClick={() => this.addField()}>Add Widget</Button>
            </Grid>
        </Grid >;
    }
}
PlayConsoleStructureList.defaultProps = {
    modulesList: [],
    editSettingsRow: null
};

const mapStateToProps = state => {
    return {
        editSettingsRow: state.editSettingsRow
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayConsoleStructureList);
