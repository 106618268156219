import React, { useEffect, useReducer } from "react";
import firebase from 'firebase/app';
import 'firebase/analytics';

import {
    Container,
    Grid,
    Typography,
    Button
} from "@material-ui/core";
import VMixListDeleted from "./VMixListDeleted";
import VMixListPending from "./VMixListPending";
import VMixListLive from "./VMixListLive";
import VMixListCompleted from "./VMixListCompleted";
import { post } from "../../lib/api";
import { useDispatch, useSelector } from "react-redux";
import { setState } from "../../store/actions";
import { initFirebase, registerFbState } from "../../lib/firebase";
export default function VMixAdmin() {
    const dispatch = useDispatch()
    const [showDeleted, toggleDeleted] = useReducer(val => !val, false)
    const [showCompleted, toggleCompleted] = useReducer(val => !val, false)
    const vmixUpdate = useSelector((state) => state.vmixUpdate);
    const statuses = ["Pending", "Live", "Completed", "Deleted"];
    const lstat = [];
    if (showDeleted) lstat.push(3);
    lstat.push(0);
    lstat.push(1);
    if (showCompleted) lstat.push(2);

    const registerFirebase = () => {
        initFirebase(window.ozFirebaseSettings);    
        const { hostname } = window.location;
        registerFbState("fbAdminState", `projects/${hostname}/adminData/1`, ({vmixUpdate}) => {
            dispatch(setState({ vmixUpdate }));
        })
    }
    
    const loadCalls = () => {
        post("vmix/calls", {}).then(res => {
            dispatch(setState({vmixCalls:res}))
           });
           post("vmix/channels", {}).then(res => {
            dispatch(setState({vmixChannels:res}))
           });
      }     

    
    useEffect(() => {
        registerFirebase();
    }, [])
    useEffect(() => {
        loadCalls();
    }, [vmixUpdate])
    const getList = (lidx) => {
        if (lidx === 0) return <VMixListPending key={0} />;
        if (lidx === 1) return <VMixListLive key={1} />;
        if (lidx === 2) return <VMixListCompleted key={2} />;
        if (lidx === 3) return <VMixListDeleted key={3} />;
    }
    return <Container
        style={{
            color: "white",
            marginTop: "5vh",
            textAlign: "center",
            height: "90vh"
        }}
    >
        <Grid container spacing={1}>
            {lstat.map((ls, lidx) => (
                <Grid
                    item
                    xs
                    key={lidx}
                    style={{ color: "white", padding: "10px", textAlign: "left" }}
                >
                    <Typography>{statuses[ls]} Calls</Typography>
                </Grid>
            ))}
        </Grid>
        <Grid container style={{ height: "80vh" }} spacing={1}>
            {lstat.map(ls => getList(ls))}
        </Grid>

        <div style={{ height: "60px", padding: "10px" }}>
            <Button
                variant="contained"
                onClick={toggleDeleted}
            >
                {showDeleted ? "Hide " : "Show "}Deleted
            </Button>
            <Button
                style={{ marginLeft: "10px" }}
                variant="contained"
                onClick={toggleCompleted}
            >
                {showCompleted ? "Hide " : "Show "}Completed
            </Button>
        </div>

    </Container>
};