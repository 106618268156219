import React from "react";
import { connect } from "react-redux";
import { setState } from "../../store/actions";
import { post, formatDate } from "../../lib/api";
import { Dialog, DialogTitle, DialogContent, DialogContentText, TableRow, Table, TableCell, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';


class ModuleActionLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { actionBtnRef: null, actionRow: {} };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.actionLogRow !== prevProps.actionLogRow) this.loadData();
  }

  componentDidMount() {
  }

  loadData() {
    const { hashkey } = this.props.actionLogRow || {};
    post("get-action-logs", { hashkey }).then(res => { 
      this.props.setState({ actionLogRowData: res })
    });
  }

  getStatusIcon(d) {
    if (d.status === "ok") return <CheckIcon style={{ color: "#080" }} />
    if (d.status === "error") return <ErrorIcon style={{ color: "#f00" }} />
    return <MenuOpenIcon />
  }

  getActionDescr(action_code){
    const action = this.props.moduleActions.find(a => a.code === action_code) || {};
    return action.title;
  }

  render() {
    const { name, surname } = this.props.actionLogRow || {};
    return (
      <Dialog fullWidth maxWidth="md" open={Boolean(this.props.actionLogRow)} onClose={() => { this.props.setState({ actionLogRow: null }) }}>
        <DialogTitle>Action Logs
        <IconButton
            style={{
              position: 'absolute',
              right: "10px",
              top: "10px",
              color: "#888",
            }}
            onClick={() => { this.props.setState({ actionLogRow: null }) }}>
            <CloseIcon />
          </IconButton>

        </DialogTitle>
        <DialogContent style={{ minWidth: "300px" }}>
          <DialogContentText>
            {name} {surname} 
          </DialogContentText>
          <Table>
            {this.props.actionLogRowData.map((d, idx) =>
              <TableRow key={idx}>
                <TableCell>{this.getStatusIcon(d)}</TableCell>
                <TableCell>{formatDate(d.ts)}</TableCell>
                <TableCell>{d.trigger}</TableCell>
                <TableCell>{this.getActionDescr(d.action_code)}</TableCell>
                <TableCell>{d.response}</TableCell>
              </TableRow>
            )}
          </Table>
        </DialogContent>
      </Dialog>
    );
  }
}
ModuleActionLogs.defaultProps = {
  actionLogRow: null,
  actionLogRowData: [],
  moduleActions: []
};

const mapStateToProps = state => {
  return {
    actionLogRow: state.actionLogRow,
    actionLogRowData: state.actionLogRowData,
    moduleActions: state.moduleActions
  }
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleActionLogs);
