import React, { useState } from 'react';
import './test.css';
import { ReactSVG } from 'react-svg'


export const Test = () => {

    const rand = Math.random() > 0.5

    const [click, setClick] = useState(0);
    const [win] = useState(rand);
    const [interactive, setInteractive] = useState(false);

    setTimeout(() => setInteractive(true), 5000);

    const handleClick = () => {
        if (!interactive) return;
        if (click >= 4) return;
        setClick(click + 1)
    }

    // const handleWin = (e) => {
    //     setWin(e.target.checked);
    // }

    const getCrumblePos = (c, a) => {
        const pos = [
            [4, 12],
            [6, 15],
            [11, 10],
            [8, 20],
            [15, 30],
            [17, 22],
            [7, 25],
            [15, 40],
            [4, 60],
            [4, 55],
            [11, 72],
            [2, 70],
            [12, 50],
            [9, 67],
            [17, 88],
            [16, 57],
            [9, 85],
            [14,37],
            [8, 90],
            [6, 42],
        ]
        const vals = pos[(c * 5) + a - 6]
        return ({
            top: `${vals[0] / 2 + 75}%`,
            left: `${vals[1]}%`,
            position: "absolute"
        })
    }

    const getMsg = () => {
        if (!interactive) return "";
        if (click < 4) return <img key="click-msg" onClick={handleClick} alt="" className="msg-img"  src="/files/pie/click_msg.png"/>;
        if (win) return <img key="win-msg" onClick={handleClick} alt="" className="msg-img msg-img-win"  src="/files/pie/win_msg.png"/>;
        return ""; //"Δεν πετύχατε το φλουρί"
    }

    return (
        <>
            <div className={`click${click} click${win ? "W" : "L"}`} onClick={handleClick} style={{ width: "80vmin", height: "90vh", marginTop: "10vh", margin: "auto", position: "relative", overflow: "hidden" }}>

                {click < 4 && false && <div style={{ overflow: "hidden" }}>
                    <ReactSVG className="plate small" src="/files/pie/plate.svg" />
                    <ReactSVG className="plate big" src="/files/pie/plate.svg" />
                </div>
                }
                {
                    [1, 2, 3, 4].map(c =>
                        [1, 2, 3, 4, 5].map(a =>
                            <img alt="" style={getCrumblePos(c, a)} className={`crumble t${c} p${a}`} key={`${c}${a}`} src={`/files/pie/crumble${c}.svg`} />
                        ))
                }
                {interactive &&
                    <div>
                        <img alt="" className={`pie pie0`} src={`/files/pie/pie6.svg`} />
                        {["1", "2", "3", "4"].map(v =>
                            <img alt="" className={`pie pie${v} pie${win ? "W" : "L"}`} key={v} src={`/files/pie/komati_${v}.svg`} />
                        )}
                    </div>
                }

                {
                    click === 4 && win && (<>
                        <div className="pyro">
                            <div className="before"></div>
                            <div className="after"></div>
                        </div>

                        <ReactSVG className="coinSvg" src={`/files/pie/coin.svg`} />
                    </>)
                }

                {
                    click === 0 &&
                    ["1", "2", "3", "4", "5", "6"].map(v =>
                        <img alt="" className={`fullpie fullpie0${v}`} key={v} src={`/files/pie/pie${v}.svg`} />
                    )}

                {
                    click === 4 && !win && (<>
                        <img alt="" className="pigeon" src={`/files/pie/pigeon.svg`} />
                    </>)
                }

            </div>
            {/* <div style={{ position: "fixed", top: "10px", left: "10px" }}>
                <Checkbox id="cb" checked={win} onChange={handleWin} label="Win" />
            </div> */}
            <div className="pie-msg">
                {getMsg()}
            </div>
        </>
    );
}