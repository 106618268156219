import React from "react";
import { connect } from "react-redux";

import { setState } from "../../store/actions";
import { loadData } from "../../lib/api";
import DataTable from "../DataTable";
import { IconButton } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import PlayConsoleStructure from "./PlayConsoleStructure";
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

class PlayConsoles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() {
    loadData("data/playConsoles", "playConsolesList");
  }

  getColumns() {
    return [
      { id: "url", label: "Address", align: "left", minWidth: "100px" },
      { id: "title", label: "Title", align: "left", minWidth: "100px" },
      { id: "settings", label: "", type: "custom", readonly: true },
      { id: "open", label: "", type: "custom", readonly: true },
    ];
  }

  getCustomField(row, column) {
    if (column.id === "settings")
      return (
        <IconButton onClick={() => this.openSettings(row)}>
          <SettingsIcon />
        </IconButton>
      );

    if (column.id === "open")
      return (
        <IconButton
          component="a"
          href={`/ozadmin/console/${row["url"] === "/" ? "" : row["url"]}`}
          target="_blank"
          rel="noreferrer">
          <OndemandVideoIcon />
        </IconButton>
      );

    return null;
  }

  openSettings(row) {
    this.props.setState({ editSettingsRow: row })
  }

  render() {
    const columns = this.getColumns();

    return (
      <>
        <DataTable
          title="Play Consoles"
          singular="Console"
          columns={columns}
          list={this.props.playConsolesList}
          getCustomField={(row, column) => this.getCustomField(row, column)}
          listName="playConsolesList"
          crud="playConsoles"
          searchFields={["url", "title"]}
          deletable
        />
        <PlayConsoleStructure />
      </>
    );
  }
}
PlayConsoles.defaultProps = {
  playConsolesList: []
};

const mapStateToProps = state => {
  return {
    playConsolesList: state.playConsolesList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayConsoles);
