import React from 'react';
import "./OzCountDown.css";

export default function OzCountDigitTop({ val, digit }) {
  let posClass = digit > val ? "down" : "";
  if(digit === 0 && val !== 0)posClass = "down"
  return <div className={`oz-countdown-digit-top ${posClass} d${digit} v${val}`}>
    <div className="oz-countdown-digit-paper top" />
    <svg
      style={{ position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px' }}
      width="100%"
      height="100%"
      viewBox="0 0 180 120"
      preserveAspectRatio="none"
    >
      <text className="oz-countdown-digit-text"  x="90" y="140" dominantBaseline="middle" textAnchor="middle" fontSize="200">    {digit}    </text>
    </svg>

  </div>;
}