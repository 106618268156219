import React from "react";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import {
    Grid,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    IconButton,
    ListItemSecondaryAction,
    Divider,
    ListItemText
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../lib/api";
import { setState } from "../../store/actions";
export default function VMixListPending() {
    const dispatch = useDispatch();
    const vmixCalls = useSelector((state) => state.vmixCalls || []);
    const vmixChannels = useSelector((state) => state.vmixChannels || []);

    const deleteCall = (id) => {
        post("vmix/calls", { action: "update", id, status: "-1" }).then(res => {
            dispatch(setState({ vmixCalls: res }))
        });
    }

    const firstOpenChannel = vmixChannels.find(channel => !vmixCalls.find(call => call.vmixKey === channel.vmixKey))

    const enableCall = (id) => {
        if(!firstOpenChannel){
            alert("Not found available channel");
            return;
        }
        post("vmix/calls", { action: "update", id, status: "1", key: firstOpenChannel.vmixKey}).then(res => {
            dispatch(setState({ vmixCalls: res }))
        });
    }




    return <Grid item xs style={{ height: "100%" }}>
        <Paper key="pending" style={{ height: "100%", overflow: "auto" }}>
            <List>
                {vmixCalls.filter(call => call.status === "0").map((call) =>
                    <React.Fragment key={call.id}>
                        <ListItem >
                            <ListItemIcon>
                                <IconButton
                                    edge="start"
                                    onClick={() => deleteCall(call.id)}
                                >
                                    <DeleteTwoToneIcon />
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText>
                            {`${call.caller.surname} ${call.caller.name}`}
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    disabled={!firstOpenChannel}
                                    onClick={() => enableCall(call.id)}
                                >
                                    <PlayCircleOutlineIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider component="li" />
                    </React.Fragment>
                )}
            </List>
        </Paper>
    </Grid>;
}