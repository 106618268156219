import { Grid, TextField, Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { post } from "../../lib/api";

export default function SetupLoadBackup() {

    const [url, setUrl] = useState("");
    const [saved, setSaved] = useState(false);
    const loadBackup = () => {
        post("backup/load/", {url})
            .then(json => {
                setSaved(true);
            });         
    }
    if (saved){
        return <Grid container spacing={2} style={{ maxWidth: "700px" }}>
        <Grid item xs={12} ><Typography  style={{margin:"20px 0px", fontWeight:"bold", textAlign:"center"}}>
                Restore Completed Succesfully
            </Typography>
        </Grid>
        </Grid>
    }

    return <Grid container spacing={2} style={{ maxWidth: "700px" }}>
        <Grid item xs={12} ><Typography  style={{margin:"20px 0px", fontWeight:"bold", textAlign:"center"}}>
                Project is empty. Do you want to restore a backup? 
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <TextField value={url} onChange={(e) => { setUrl(e.target.value) }} label="Backup URL" variant="outlined" fullWidth />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={loadBackup} size="large" variant="contained" color="primary">Upload</Button>
        </Grid>
    </Grid>;

}