import React from "react";
import { connect } from "react-redux";

import { setState } from "../../../store/actions";
import { post } from "../../../lib/api";
import {
  Paper,
  Grid,
  IconButton,
} from "@material-ui/core";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import RefreshIcon from '@material-ui/icons/Refresh';

class PlayConsoleBtnPropWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
  }

  setProp(incr){
    const { row, idx } = this.props;
    const props = JSON.parse(row.props)
    const curIdx = props.values.findIndex(p => p[0] === this.props.row.data)
    let newIdx = curIdx + incr;
    if(newIdx < 0)newIdx = 0;
    if(newIdx >= props.values.length)newIdx = props.values.length - 1;
    const newVal = props.values[newIdx][0];
    const structure = [...this.props.consoleData.structure];
    structure[idx] = {...structure[idx], data:newVal};
    this.props.setState({consoleData: {...this.props.consoleData, structure}})
    post("set-console-data", {structure: structure[idx]});
  }

  getDescr(){
    const props = JSON.parse(this.props.row.props)
    const curIdx = props.values.findIndex(p => p[0] === this.props.row.data)
    if(curIdx < 0)return "";
    return(props.values[curIdx][1])
  }


  render() {
    const { row, idx } = this.props;
    return (
      <Paper key={idx} style={{ flexGrow: 1, margin: "20px", padding: "20px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h2 style={{ margin: "10px" }}>{row.descr}: 
            <IconButton
                style={{ marginLeft: "30px" }}
                // disabled={this.props.editRow.status <= 0}
                onClick={() => this.setProp(-1)}
              >
                <SkipPreviousIcon fontSize="large" />
              </IconButton>
              {this.getDescr()}
              <IconButton
                // disabled={this.props.editRow.status <= 0}
                onClick={() => this.setProp(1)}
              >
                <SkipNextIcon fontSize="large" />
              </IconButton>
              <IconButton
                // disabled={this.props.editRow.status <= 0}
                onClick={() => this.setProp(0)}
              >
                <RefreshIcon fontSize="large" />
              </IconButton>

            </h2>
            
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

PlayConsoleBtnPropWidget.defaultProps = {
  consoleData: {}
};

const mapStateToProps = state => {
  return Object.keys(PlayConsoleBtnPropWidget.defaultProps).reduce(
    (obj, key) => ({ ...obj, [key]: state[key] }),
    {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayConsoleBtnPropWidget);
