import React from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

export default function ImageComposerTxt({ idx, item, editItem, removeItem }) {
  return (
    <Grid
      item
      xs={12}
      key={idx}
      container
      spacing={1}
      style={{ alignItems: "flex-end" }}
    >
      <Grid item xs={3} md={2}>
        <Button onClick={removeItem} size="small">
          <ClearIcon />
        </Button>
      </Grid>
      <Grid item xs={9} md={10}>
        <TextField
          fullWidth
          label="Text"
          value={item.text}
          onChange={e => editItem("text", e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Color"
          value={item.color}
          onChange={e => editItem("color", e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Size"
          value={item.size}
          onChange={e => editItem("size", e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Top"
          value={item.top}
          onChange={e => editItem("top", e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Left"
          value={item.left}
          onChange={e => editItem("left", e.target.value)}
        />
      </Grid>
    </Grid>
  );
}
