import { Box, Button, Dialog, DialogActions, DialogTitle, DialogContent, Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { moduleTypes } from "../../common/const";
import { crud, loadData } from "../../lib/api";
import { setState } from "../../store/actions";
import ModuleSetupActionList from "./ModuleSetupActionList";
import ModuleSetupFieldList from "./ModuleSetupFieldList";

class ModuleSetup extends React.Component {


    doSave() {
        if (this.saving) return;
        this.saving = true;
        let id = this.props.editSettingsRow.id;
        let data = this.props.editSettingsRow;
        crud("modules", id, data)
            .then(json => {
                this.props.setState({ editSettingsRow: null });
                loadData(`data/modules`, "modulesList").then(() => {
                    this.saving = false;
                });
            })
            .catch(res => {
                this.saving = false;
            });

    }

    renderSetupRow(setupProps, idx) {
        if (setupProps.type === "fieldlist") return <ModuleSetupFieldList key={idx} setupProps={setupProps} />
        if (setupProps.type === "actionlist") return <ModuleSetupActionList key={idx} setupProps={setupProps} />
        return null;
    }

    render() {
        const { module_type, name } = this.props.editSettingsRow || {};
        const moduleType = moduleTypes.find(m => m.id === module_type) || {};
        return (
            <Dialog fullWidth maxWidth="md" open={this.props.editSettingsRow}>
                <DialogTitle>{name} Setup</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {(moduleType.setup || []).map((s, idx) => this.renderSetupRow(s, idx))}
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: "25px" }}>
                    <Box style={{ flexGrow: 1 }}></Box>
                    <Button
                        onClick={() => this.props.setState({ editSettingsRow: null })}
                        variant="contained"
                    >
                        Cancel
                  </Button>
                    <Button
                        onClick={() => this.doSave()}
                        variant="contained"
                        color="primary"
                    >
                        Save
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
ModuleSetup.defaultProps = {
    modulesList: [],
    editSettingsRow: null
};

const mapStateToProps = state => {
    return {
        editSettingsRow: state.editSettingsRow
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleSetup);
