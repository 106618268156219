import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { post } from '../../lib/api';
import { useDispatch } from 'react-redux';
import { setState } from "../../store/actions";

export default function TwilioVideoChatAsk({userInfo, chatInfo, videoCall}) {

    const dispatch = useDispatch();

    const declineVideoCall = async () => {
        const { hashkey } = userInfo;
        const receiver = chatInfo.code;
        post("end-video-call", { hashkey,receiver });
        dispatch(setState({acceptedVideoCallContact: null }))
    }

    const acceptVideoCall = async () => {
        const { hashkey } = userInfo;
        post("accept-video-call", { hashkey });
        dispatch(setState({acceptedVideoCallContact: videoCall.contact }))
    }

return <Grid container spacing={2}>
        <Grid item xs={6}>
            <Button fullWidth onClick = {acceptVideoCall} color="primary" variant="contained" >JOIN</Button>
        </Grid>
        <Grid item xs={6}>
            <Button fullWidth onClick = {declineVideoCall} variant="contained" >DECLINE</Button>
        </Grid>
    </Grid>;
}
