import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { post } from '../../lib/api';

export default function AddContactBtn() {

    const userInfo = useSelector(({ userInfo }) => userInfo) || {};
    const myContacts = useSelector(({ pageDatasets }) => pageDatasets.myContacts) || {};

    const chatUsers = useSelector(({ pageDatasets }) => pageDatasets.chatUsers) || [];
    const selectedChatAtd = useSelector(({ ozState }) => ozState.selectedChatAtd);
    const chatInfo = chatUsers.find(u => u.code === selectedChatAtd)
    
    const myContact = myContacts[chatInfo.code];
    const toggleMyContact = async () => {
        const { hashkey } = userInfo;
        const contact = chatInfo.code;
        const enabled = !myContact;
        post("set-firebase-contact", { hashkey, contact, enabled });
    }

    return (
        <Tooltip title=
            {myContact ?
                `Remove from my contacts` :
                `Add to my contacts`
            } arrow placement="top">
            <IconButton
                onClick={toggleMyContact}
            >
                {myContact ?
                    <StarIcon />
                    :
                    <StarBorderIcon />
                }
            </IconButton>
        </Tooltip>
    )

}