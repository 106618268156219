import React from "react";
import { connect } from "react-redux";
import { setState } from "../../store/actions";
import { Grid, IconButton } from "@material-ui/core";
import BorderRightIcon from '@material-ui/icons/BorderRight';
import BorderLeftIcon from '@material-ui/icons/BorderLeft';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import CodeIcon from '@material-ui/icons/Code';

import { crud } from "../../lib/api";
import { cmpTypes } from "../../common/const";
import { pasteComponent, componentStruct } from "./copypaste";

class PageStructureButtons extends React.Component {

    doSave() {
        const { pageStructure, page, page: { id } } = this.props;
        const data = { ...page, structure: pageStructure };
        crud("pages", id, data)
            .then(json => {
                console.log("saved");
            })
            .catch(res => {
                this.saving = false;
            });
    }

    addComponent() {
        const id = Math.floor(Date.now() / 1000);
        const newCmp = {
            id,
            name: "",
            type: "",
            className: "",
            parent: this.props.selectedComponent,
            props: {},
            style: []
        }

        const pageStructure = [...this.props.pageStructure, newCmp];
        this.props.setState({ pageStructure, selectedComponent: id })
    }

    delComponent() {
        const cmp = this.props.pageStructure.find(p => p.id === this.props.selectedComponent);
        const pageStructure = this.props.pageStructure.filter(p => p.id !== cmp.id);
        this.props.setState({ pageStructure, selectedComponent: cmp.parent })
    }

    moveToBottom() {
        const cmp = this.props.pageStructure.find(p => p.id === this.props.selectedComponent);
        const pageStructure = [...this.props.pageStructure.filter(p => p.id !== cmp.id), cmp];
        this.props.setState({ pageStructure })
    }

    moveToTop() {
        const cmp = this.props.pageStructure.find(p => p.id === this.props.selectedComponent);
        const pageStructure = [cmp, ...this.props.pageStructure.filter(p => p.id !== cmp.id)];
        this.props.setState({ pageStructure })
    }

    doCopy() {
        const cmp = this.props.pageStructure.find(p => p.id === this.props.selectedComponent);
        const copiedComponent = JSON.stringify(componentStruct(cmp, this.props.pageStructure));
        this.props.setState({ copiedComponent });
    }

    doPaste() {
        const newPasteId = Math.floor(Date.now());
        const newStructure = JSON.parse(this.props.copiedComponent);
        const newComponents = pasteComponent(this.props.selectedComponent, newStructure, newPasteId);
        const pageStructure = [...this.props.pageStructure, ...newComponents];
        this.props.setState({ pageStructure });
    }

    codeEdit() {
        this.props.setState({ showCodeEditor: true });
    }

    render() {
        const cmp = this.props.pageStructure.find(p => p.id === this.props.selectedComponent);
        const children = this.props.pageStructure.filter(s => s.parent === this.props.selectedComponent);
        const ctype = cmpTypes.find(t => t.code === cmp?.type)

        return <Grid item xs={12}>
            <IconButton onClick={() => this.props.setState({ showRight: !this.props.showRight })}> {
                this.props.showRight ?
                    <BorderLeftIcon />
                    :
                    <BorderRightIcon />
            }
            </IconButton>
            <IconButton onClick={() => this.props.setState({ hideControls: true })}>
                <VisibilityOffIcon />
            </IconButton>

            <IconButton onClick={() => this.doSave()}>
                <SaveIcon />
            </IconButton>


            <IconButton disabled={!ctype || ctype.noDetails} onClick={() => this.addComponent()}>
                <PlaylistAddIcon />
            </IconButton>
            <IconButton disabled={!cmp || cmp.id === 1 || children.length > 0} onClick={() => this.delComponent()}>
                <DeleteIcon />
            </IconButton>
            <IconButton disabled={!cmp || cmp.id === 1} onClick={() => this.moveToBottom()}>
                <LowPriorityIcon />
            </IconButton>
            <IconButton style={{ transform: "rotate(180deg)" }} disabled={!cmp || cmp.id === 1} onClick={() => this.moveToTop()}>
                <LowPriorityIcon />
            </IconButton>
            <IconButton disabled={!cmp} onClick={() => this.doCopy()}>
                <LibraryBooksIcon />
            </IconButton>
            <IconButton disabled={!cmp || !this.props.copiedComponent || !ctype || ctype.noDetails} onClick={() => this.doPaste()}>
                <LibraryAddIcon />
            </IconButton>
            <IconButton onClick={() => this.codeEdit()}>
                <CodeIcon />
            </IconButton>

        </Grid>;
    }

}

PageStructureButtons.defaultProps = {
    pageStructure: []
};


const mapStateToProps = state => {
    return {
        selectedComponent: state.selectedComponent,
        pageStructure: state.pageStructure,
        showRight: state.showRight,
        page: state.page,
        copiedComponent: state.copiedComponent
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageStructureButtons);

