import React from 'react';

export default function VMixCall({ cmpProps, style }) {
    let { vmixKey, vmixName } = cmpProps;
    return <div
        className={cmpProps.className}
        style={{
            ...style,
            width: "100%",
            background: "transparent",
            paddingTop: "56.25%",
            position: "relative",
            overflow: "hidden"
        }}
    >
        <iframe
            src={`https://www.vmixcall.com/call.aspx?Key=${vmixKey}&Name=${vmixName}`}
            title="Vmix Call"
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0px",
                left: "0px"
            }}
            frameBorder="0"
            allow="camera; microphone"
            allowFullScreen={cmpProps.fullScreen === "1"}
        />
    </div>
}