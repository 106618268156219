import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SendBar from './SendBar';
import MsgBox from './MsgBox';
import "./FirebaseChatBox.css";
import { post } from '../../lib/api';

export default function FirebaseChatBox({ user }) {
    const messagesEndRef = useRef(null)

    const chat = useSelector(({ chat }) => chat);
    const chatUsers = useSelector(({ pageDatasets }) => pageDatasets.chatUsers);
    const selectedChatAtd = useSelector(({ ozState }) => ozState.selectedChatAtd);

    const chatInfo = chatUsers.find(u => u.code === selectedChatAtd)
    const selectedUserChat = chat.filter(c => c.from === selectedChatAtd || c.to === selectedChatAtd);
    const [markedAsRead, setMarkedAsRead] = useState([]);
    const hashkey = useSelector(({ userInfo }) => userInfo.hashkey);
    const userCode = useSelector(({ userInfo }) => userInfo.code);

    const myInfo = chatUsers.find(u => u.code === userCode)

    useEffect(() => {
        messagesEndRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
    }, [chat]);

    useEffect(() => {
        const unreadMsg = chat.filter(c =>
            c.new_msg &&
            c.from === user &&
            markedAsRead.indexOf(c.key) < 0
        ).map(c => c.key)
        if (unreadMsg.length > 0) {
            setMarkedAsRead([...markedAsRead, ...unreadMsg]);
            post("set-firebase-msg-read", { hashkey, keys: unreadMsg });
        }
    }, [chat, markedAsRead, setMarkedAsRead, user, hashkey])

    return <div style={{
        display: "flex",
        height: "100%",
        flexDirection: "column"
    }}>
        <div ref={messagesEndRef} style={{ flexGrow: "1", overflow: "auto", padding: "20px" }}>
            {selectedUserChat.map((msg, msgIdx) => (
                <MsgBox msg={msg} chatInfo={chatInfo} key={msgIdx} />
            ))}
        </div>
        <SendBar chatInfo={chatInfo} myInfo={myInfo} />
    </div>;

}