
import React from "react";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    ListItem,
    ListItemIcon,
    IconButton,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { post } from "../../lib/api";
import { setState } from "../../store/actions";
export default function VMixListLiveOpen({channel, isFirstOpen}) {
    const dispatch = useDispatch();

    const removeKey = () => {
        post("vmix/channels", { action: "delete", id: channel.id }).then(res => {
            dispatch(setState({ vmixChannels: res }))
        });
    }

    return (<ListItem >
        <ListItemIcon>
            {isFirstOpen && <DoubleArrowIcon style={{color: "#800"}} />}
        </ListItemIcon>
        <ListItemText>
            {`${channel.vmixKey}`}
        </ListItemText>
        <ListItemSecondaryAction>
            <IconButton
                edge="end"
                onClick={removeKey}
            >
                <DeleteIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>);
}