import setHetznerDomain from "./wizards/setHetznerDomain";
import addHetznerServer from "./wizards/addHetznerServer";
import resizeHetznerServer from "./wizards/resizeHetznerServer";
import restartServices from "./wizards/restartServices";
import updateApp from "./wizards/updateApp";
import renameDomain from "./wizards/renameDomain";

export function getWizardData (code, props, setProp, setLogs){
    if(code === "add-hetzner-server")return addHetznerServer(props, setProp, setLogs);
    if(code === "set-hetzner-domain")return setHetznerDomain(props, setProp, setLogs);
    if(code === "resize-hetzner-server")return resizeHetznerServer(props, setProp, setLogs);
    if(code === "restart-services")return restartServices(props, setProp, setLogs);
    if(code === "update-app")return updateApp(props, setProp, setLogs);
    if(code === "rename-domain")return renameDomain(props, setProp, setLogs);

    return {title: "Under Construction", wizardData: []}
}
