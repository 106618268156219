import React from "react";
import { connect } from "react-redux";
import { setState } from "../../store/actions";
import { Dialog, DialogTitle, DialogContent, TableRow, Table, TableCell, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';


class ModuleKeyInfo extends React.Component {

  render() {
    const { name, surname, hashkey, regcode, code } = this.props.keyInfoRow || {};
    return (
      <Dialog fullWidth maxWidth="md" open={Boolean(this.props.keyInfoRow)} onClose={() => { this.props.setState({ keyInfoRow: null }) }}>
        <DialogTitle>{`${name} ${surname}`}
        <IconButton
            style={{
              position: 'absolute',
              right: "10px",
              top: "10px",
              color: "#888",
            }}
            onClick={() => { this.props.setState({ keyInfoRow: null }) }}>
            <CloseIcon />
          </IconButton>

        </DialogTitle>
        <DialogContent style={{ minWidth: "300px" }}>
          <Table>
              <TableRow>
                <TableCell><b>Hash Key</b></TableCell>
                <TableCell>{hashkey}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Login Key</b></TableCell>
                <TableCell>{regcode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Public Code</b></TableCell>
                <TableCell>{code}</TableCell>
              </TableRow>
          </Table>
        </DialogContent>
      </Dialog>
    );
  }
}
ModuleKeyInfo.defaultProps = {
  keyInfoRow: null,
};

const mapStateToProps = state => {
  return {
    keyInfoRow: state.keyInfoRow
  }
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleKeyInfo);
