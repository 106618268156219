import React from "react";
import {
    Grid,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    IconButton,
    ListItemSecondaryAction,
    Divider,
    ListItemText
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../lib/api";
import { setState } from "../../store/actions";
import VMixListLiveAdd from "./VMixListLiveAdd";
import VMixListLiveOpen from "./VMixListLiveOpen";
import VMixListLiveConnected from "./VMixListLiveConnected";
export default function VMixListPending() {
    const dispatch = useDispatch();
    const vmixCalls = useSelector((state) => state.vmixCalls || []);
    const vmixChannels = useSelector((state) => state.vmixChannels || []);

    const channelList = vmixChannels.map(channel => (
        {...channel, call: vmixCalls.find(call => call.vmixKey === channel.vmixKey)
    }));

    const firstOpen = channelList.find(channel => !channel.call) || {};

    console.log({firstOpen, channelList})
    return <Grid item xs style={{ height: "100%" }}>
        <Paper key="pending" style={{ height: "100%", overflow: "auto" }}>
            <List>
                {channelList.map((channel) =>
                    <React.Fragment key={channel.id}>
                        {channel.call ?
                        <VMixListLiveConnected channel={channel}/>
                        :
                        <VMixListLiveOpen channel={channel} isFirstOpen={firstOpen.id === channel.id } />
                    }
                    <Divider component="li" />
                    </React.Fragment>
                )}
                <VMixListLiveAdd />
            </List>
        </Paper>
    </Grid>;
}