import React from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

export default function ImageComposerImg({ idx, item, editItem, removeItem }) {
  return (
    <Grid item xs={12} key={idx} container spacing={1} style={{alignItems:"flex-end"}}>
      <Grid item xs={3} md={2}>
        <Button onClick={removeItem} size="small">
          <ClearIcon />
        </Button>
      </Grid>
      <Grid item xs={9} md={6}>
        <TextField
          fullWidth
          label="Image File"
          value={item.file}
          onChange={e => editItem("file", e.target.value)}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField
          fullWidth
          label="Top (%)"
          value={item.top}
          onChange={e => editItem("top", e.target.value)}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField
          fullWidth
          label="Left (%)"
          value={item.left}
          onChange={e => editItem("left", e.target.value)}
        />
      </Grid>
    </Grid>
  );
}
