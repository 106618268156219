import React from "react";
import { Grid, TextField } from "@material-ui/core";

export default function ImageComposerBgr({ idx, item, editItem }) {
  return (
    <Grid item xs={12} key={idx}>
        <TextField
          fullWidth
          label="Background Image"
          value={item.filename}
          onChange={e => editItem("filename", e.target.value)}
        />
    </Grid>
  );
}
