import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setState } from "../../store/actions";

import { useSearchParams } from "react-router-dom";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Slider,
  Box,
  Typography
} from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";

import { loadData, formatDate } from "../../lib/api";
import DataTable from "../DataTable";
import StatisticsPlot from "./StatisticsPlot";

function getGrpLabel(grp, groupby) {
  if (groupby === "Tag") return grp;
  return `${grp.substr(6, 2)}/${grp.substr(4, 2)}${grp.substr(8)}`;
}

function groupStatData(pokeData, groupby, selTag, tsStart, tsStop) {
  const now = Math.round(Date.now() / 1000);
  const statData = {
    statUsers: {},
    statTags: new Set(),
    statTS: new Set(),
    statGrp: new Set()
  };
  const grpCnt = {};
  const tsRange = 60 * 15;
  Object.values(pokeData).forEach(d => {
    d.pokes.forEach(p => {
      statData.statTags.add(p.tag);
      let tsOut = p.ts_out || now;
      if(tsOut > now)tsOut = now;
      if(tsOut - p.ts_in > 60 * 60 * 12)tsOut = p.ts_in + 60 * 60 * 12; // no more than 12h
      const tsFrom = Math.floor(p.ts_in / tsRange) * tsRange;
      const tsTo = Math.floor(tsOut / tsRange) * tsRange;
      Array((tsTo - tsFrom) / tsRange + 1)
        .fill()
        .forEach((_, i) => {
          const ts = tsFrom + i * tsRange;
          statData.statTS.add(ts);

          if (p.tag !== selTag && selTag !== "All") return;
          if (tsStart && tsStart > ts) return;
          if (tsStop && tsStop < ts) return;

          let duration = tsRange;
          if (p.ts_in > ts) duration -= p.ts_in - ts;
          if (tsOut < ts + tsRange) duration -= ts + tsRange - tsOut;
          duration = Math.round(duration / 60);
          if (!statData.statUsers[d.id])
            statData.statUsers[d.id] = {
              id: d.id,
              name: d.name,
              surname: d.surname,
              email: d.email,
              pokes: {},
              total: 0
            };

          const tsFormula = {
            Day: "yyyyMMdd",
            Hour: "yyyyMMdd HH:00",
            Quarter: "yyyyMMdd HH:mm",
            Tag: "yyyyMMdd HH:mm"
          }[groupby];

          const grp = groupby === "Tag" ? p.tag : formatDate(ts, tsFormula);
          statData.statGrp.add(grp);

          if (!statData.statUsers[d.id].pokes[grp])
            statData.statUsers[d.id].pokes[grp] = [];
          statData.statUsers[d.id].pokes[grp].push({ ...p, duration });
          statData.statUsers[d.id].total += duration;
          if (!grpCnt[grp]) grpCnt[grp] = {};
          if (!grpCnt[grp][p.tag]) grpCnt[grp][p.tag] = new Set();
          grpCnt[grp][p.tag].add(d.id);
        });
    });
  });

  statData.statTags = new Array(...statData.statTags);
  statData.statTS = new Array(...statData.statTS).sort();
  statData.statUsers = Object.values(statData.statUsers);
  statData.statGrp = new Array(...statData.statGrp).sort().map(code => ({
    code,
    label: getGrpLabel(code, groupby),
    ...statData.statTags.reduce(
      (prev, cur) => ({
        ...prev,
        [cur]: grpCnt[code] ? grpCnt[code][cur]?.size || 0 : 0
      }),
      {}
    )
  }));

  return statData;
}

export default function Statistics() {
  const dispatch = useDispatch();

  const statUsers = useSelector(({ statUsers }) => statUsers);
  const statTags = useSelector(({ statTags }) => statTags);
  const statGrp = useSelector(({ statGrp }) => statGrp);
  const statTS = useSelector(({ statTS }) => statTS);
  const pokeData = useSelector(({ pokeData }) => pokeData);
  const [searchParams, setSearchParams] = useSearchParams();
  const groupby = searchParams.get("groupby") || "Tag";
  const tag = searchParams.get("tag") || "All";
  const tsStart = Number(searchParams.get("tsStart") || 0);
  const tsStop = Number(searchParams.get("tsStop") || statTS?.length || 0);

  const updSearchParams = params => {
    Object.entries(params).forEach(([key, val]) => {
      searchParams.set(key, val);
    });
    setSearchParams(searchParams, { replace: true });
  };

  const updTSParams = tsVal => {
    if (tsVal[0] === 0) searchParams.delete("tsStart");
    else searchParams.set("tsStart", tsVal[0]);
    if (tsVal[1] >= statTS.length) searchParams.delete("tsStop");
    else searchParams.set("tsStop", tsVal[1]);

    setSearchParams(searchParams, { replace: true });
  };

  useEffect(() => {
    loadPokes();
  }, []);

  useEffect(() => {
    if (!pokeData) return;
    let start = statTS && tsStart ? statTS[tsStart] : null;
    let stop = statTS && tsStop < statTS.length ? statTS[tsStop] : null;

    if((tsStart || tsStop) && !statTS){
      const {statTS: cStatTS} = groupStatData(pokeData, "Tag", "All", null, null);
      if(tsStart)start = cStatTS[tsStart]
      if(tsStop)stop = cStatTS[tsStop]
    }

    dispatch(
      setState({ ...groupStatData(pokeData, groupby, tag, start, stop) })
    );
  }, [pokeData, groupby, tag, tsStart, tsStop]);

  const loadPokes = async () => {
    const pokeData = await loadData("poke-stats");
    dispatch(setState({ pokeData }));
  };

  const getTagColumns = () => {
    return statGrp.map(t => ({
      id: `grp_${t.code}`,
      label: t.label,
      type: "custom"
    }));
  };

  const getColumns = () => {
    if (!statUsers) return [];
    return [
      { id: "name", label: "Name", align: "left", minWidth: "50px" },
      { id: "surname", label: "Surname", align: "left", minWidth: "50px" },
      { id: "email", label: "Email", align: "left", minWidth: "50px" },
      ...getTagColumns(),
      { id: `total`, label: "Total", type: "custom" }
    ];
  };

  const getCustomField = (row, column) => {
    if (column.id === "total") {
      const sum = row.total;
      return <b>{sum}</b>;
    }
    if (column.id.startsWith("grp_")) {
      const tag = column.id.substr(4);
      const sum = row.pokes[tag]?.reduce((prev, cur) => prev + cur.duration, 0);
      return(sum);
      // const info =
      //   row.pokes[tag]?.reduce(
      //     (prev, t) => ({...prev, [t.id]:
      //       `${t.tag}: ${formatDate(t.ts_in, "dd/MM HH:mm")} - ${
      //         t.ts_out ? formatDate(t.ts_out, "HH:mm") : "Now"
      //       }`})
      //   , {}) || {};
      // return (
      //   <Link
      //   onClick={() => {
      //     const info =
      //       row.pokes[tag]?.reduce(
      //         (prev, t) => ({...prev, [t.id]:
      //           `${t.tag}: ${formatDate(t.ts_in, "dd/MM HH:mm")} - ${
      //             t.ts_out ? formatDate(t.ts_out, "HH:mm") : "Now"
      //           }`})
      //       , {}) || {};
      //     alert(Object.values(info).join(`\n`));
      //   }}
          // title={Object.values(info).map((i, iIdx) => (
          //   <Box key={iIdx}>{i}</Box>
          // ))}
      //   >
      //     <Box>{sum}</Box>
      //   </Link>
      // );
    }

    // if (column.id === "structure")
    //   return (
    //     <IconButton onClick={() => this.openComposer(row)}>
    //       <PhotoLibraryIcon />
    //     </IconButton>
    //   );

    return null;
  };

  const getTsLabel = ts => {
    if (!statTS) return "";
    if (ts >= statTS.length) return "Now";
    return formatDate(statTS[ts], "dd/MM HH:mm");
  };

  return (
    <>
      <DataTable
        title="Statistics"
        readOnly
        columns={getColumns()}
        list={statUsers || []}
        getCustomField={(row, column) => getCustomField(row, column)}
        listName="statUsers"
        searchFields={["name", "surname", "email", "tag"]}
        additionalSearchBox={
          <>
            <IconButton
              style={{ marginTop: "-15px" }}
              onClick={() => updSearchParams({ plot: true }, { replace: true })}
            >
              <AssessmentIcon />
            </IconButton>

            <Box
              display="inline-block"
              style={{ margin: "10px 20px -10px 20px" }}
              width="150px"
              position="relative"
            >
              <Slider
                value={[tsStart, tsStop]}
                valueLabelDisplay="off"
                max={statTS?.length || 0}
                onChange={(event, newValue) => updTSParams(newValue)}
                // getAriaValueText={valuetext}
              />
              <Typography
                variant="caption"
                style={{ position: "absolute", left: "-5px", top: "-15px" }}
              >
                {getTsLabel(tsStart)}
              </Typography>
              <Typography
                variant="caption"
                style={{ position: "absolute", right: "-5px", top: "-15px" }}
              >
                {getTsLabel(tsStop)}
              </Typography>
            </Box>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "150px", marginRight: "10px" }}
            >
              <InputLabel>Tag</InputLabel>
              <Select
                label="Tag"
                value={tag}
                onChange={event =>
                  updSearchParams(
                    { tag: event.target.value },
                    { replace: true }
                  )
                }
              >
                {["All", ...(statTags || [])].map(tag => (
                  <MenuItem key={`listitem_${tag}`} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "150px", marginRight: "10px" }}
            >
              <InputLabel>Group By</InputLabel>
              <Select
                label="Group By"
                value={groupby}
                onChange={event =>
                  updSearchParams(
                    { groupby: event.target.value },
                    { replace: true }
                  )
                }
              >
                {["Tag", "Day", "Hour", "Quarter"].map(tag => (
                  <MenuItem key={`listitem_${tag}`} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        }
      />
      <StatisticsPlot />
    </>
  );
}
