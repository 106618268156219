import React from "react";
import { setState } from "../store/actions";
import { connect } from "react-redux";
import withRouter from  "../lib/withRouter";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  Button,
  Typography,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  TextField
} from "@material-ui/core";
import { crud, formatDate, post } from "../lib/api";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import RestoreFromTrashTwoToneIcon from "@material-ui/icons/RestoreFromTrashTwoTone";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';

class ChatAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      status: 0,
      lock: "",
      loggedIn: false,
      secretKey: null,
      inputSecretKey: ""
    };
    setInterval(() => {
      if (this.state.loggedIn) this.loadQuestions(this.state.secretKey)
    }, 10000);
  }

  componentDidMount() {
    const secretKey = localStorage.getItem(`ozSecretKey`);
    const openLists = localStorage.getItem(`ozQuestionsOpenLists`);
    if (secretKey) this.loadQuestions(secretKey);
    if (openLists){
      const showOrHidden = JSON.parse(openLists);
      this.props.setState({showOrHidden})
    }
  }

  setStatus(id, status) {
    const question = {
      ...this.props.chatList.find(q => q.id === id),
      status
    };
    delete question.user_data;
    question.module_id = this.props.moduleRow.id;
    crud("modulerows/", id, question).then(res => {
      this.loadQuestions(this.state.secretKey);
    });
  }

  loadQuestions(secretKey) {
    const { module } = this.props.params;
    post("get-questions-data", { module, secretKey }).then(res => {
      if (!res.error) {
        localStorage.setItem(`ozSecretKey`, secretKey);
        this.props.setState({ chatList: res.data, moduleRow: res.module })
        this.setState({ loggedIn: true, secretKey })
      }
      else {
        this.setState({ loggedIn: false })
      }
    });
  }

  toggleShowOrHidden(idx) {
    const showOrHidden = this.props.showOrHidden.map((v, sidx) => sidx === idx ? !v : v);    
    this.props.setState({ showOrHidden });
    localStorage.setItem(`ozQuestionsOpenLists`, JSON.stringify(showOrHidden));
  }

  getChatMessage(msgRow) {
    const msg = window.ozChatMessage ?
      window.ozChatMessage(msgRow) :
      msgRow.message;
    return msg.split("\\n").map(str => <p style={{ margin: "0px" }}>{str}</p>)
  }

  getChatUserDescr(user) {
    if (window.ozChatUserDescr) return (window.ozChatUserDescr(user));
    return `${user.name} ${user.surname}`;
  }

  render() {
    const list = this.props.chatList;

    const statuses = [
      ["Deleted", null, <RestoreFromTrashTwoToneIcon />],
      ["New", <DeleteTwoToneIcon />, <CheckCircleTwoToneIcon />],
      ["Approved", <HighlightOffTwoToneIcon />, <ExitToAppIcon />],
      ["Completed", <AssignmentReturnIcon />, null]
    ];

    if (!this.state.loggedIn) {
      return <Paper style={{ padding: "30px", maxWidth: "200px", margin: "auto", marginTop: "100px" }}>
        <TextField
          fullWidth
          type="password"
          label="Secret Key"
          value={this.state.inputSecretKey}
          onChange={event =>
            this.setState({
              inputSecretKey: event.target.value
            })
          }
        />
        <Button
          onClick={() => this.loadQuestions(this.state.inputSecretKey)}
          size="large"
          fullWidth
        >
          SUBMIT
        </Button>
      </Paper>
    }

    return (
      <div
        className = "ozChatAdminOuter"
        style={{
          padding: "10px",
          marginTop: "5vh",
          textAlign: "center",
          height: "90vh"
        }}
      >
        <Grid container spacing={1}>
          {statuses.map((status, lidx) => (
            this.props.showOrHidden[lidx] &&
            <Grid
              className = "ozChatAdminTitle"
              item
              xs
              key={lidx}
              style={{  padding: "10px", textAlign: "left" }}
            >
              <Typography>{status[0]}  {this.props.moduleRow.name}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid container style={{ height: "80vh" }} spacing={1}>
          {statuses.map((status, lidx) => (
            this.props.showOrHidden[lidx] &&
            <Grid item xs style={{ height: "100%" }}>
              <Paper key={lidx} style={{ height: "100%", overflow: "auto" }}>
                <List>
                  {list
                    .filter(l => l.status === lidx)
                    .map((h, idx) => (
                      <React.Fragment key={idx}>
                        <ListItem>
                          {status[1] && (
                            <ListItemIcon>
                              <IconButton
                                edge="start"
                                onClick={() => this.setStatus(h.id, lidx - 1)}
                              >
                                {status[1]}
                              </IconButton>
                            </ListItemIcon>
                          )}
                          <ListItemText
                            primary={this.getChatMessage(h)}
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {this.getChatUserDescr(h.user_data)}
                                </Typography>
                                {` — ${formatDate(h.datetime)}`}
                              </>
                            }
                          />
                          {status[2] && (
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                onClick={() => this.setStatus(h.id, lidx + 1)}
                              >
                                {status[2]}
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    ))}
                </List>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <div style={{ height: "60px", padding: "10px" }}>
          <span style={{ float: "right" }}>
            {statuses.map((status, lidx) => (
              <Button
                variant="contained"
                style={{ marginLeft: "10px" }}
                color={this.props.showOrHidden[lidx] ? "primary" : "default"}
                onClick={() => this.toggleShowOrHidden(lidx)}
              >
                {this.props.showOrHidden[lidx] ? "Hide " : "Show "} {status[0]}
              </Button>
            ))}
          </span>
        </div>
      </div>
    );
  }
}

ChatAdmin.defaultProps = {
  chatList: [],
  moduleRow: {},
  showOrHidden: [false, false, true, false]
};

const mapStateToProps = state => {
  return Object.keys(ChatAdmin.defaultProps).reduce(
    (obj, key) => ({ ...obj, [key]: state[key] }),
    {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChatAdmin));
