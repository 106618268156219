import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { setState } from "../../store/actions";
import { post } from '../../lib/api';

export default function SendBar(props) {
    const dispatch = useDispatch();
    const userInfo = useSelector(({ userInfo }) => userInfo) || {};

    const [msg, setMsg] = useState("");

    const sendMessage = async () => {
        const newMessage = { msg, myMsg: true }
        const chatInfo = userInfo.chat[props.user] || { messages: [] };
        const messages = [...chatInfo.messages, newMessage];
        const newChatInfo = { ...chatInfo, messages };
        const newUserInfo = { ...userInfo, chat: { ...userInfo.chat, [props.user]: { ...newChatInfo } } };

        const { hashkey } = userInfo;
        const to = props.user;
        post("send-chat-msg", { hashkey, to, msg });
        dispatch(setState({ userInfo: newUserInfo }))
        setMsg("");
    }

    const handleMsgKeyUp = (event) => {
        if (!event.ctrlKey && event.key === 'Enter'){
            sendMessage();
        }
    }

    return (
        <div style={{ padding: "10px" }}>
            <TextField fullWidth multiline variant="outlined" placeholder="Send Message..."
                value={msg}
                onChange={(event) => setMsg(event.target.value)}
                onKeyUp={(event) => handleMsgKeyUp(event)}                
                autoFocus
                InputProps={{
                    endAdornment:
                        < InputAdornment position="end" >
                            <IconButton
                                onClick={sendMessage}
                            >
                                <SendIcon />
                            </IconButton>
                        </InputAdornment>
                }}
            />
            <Button variant="contained" style={{ float: "right", margin: "10px 0px " }}>
                Video Call
            </Button>
            <Button variant="contained" style={{ float: "right", margin: "10px" }}>
                Send Contact Details
            </Button>
        </div >
    )
}
