import React, { Fragment } from "react";
import { connect } from "react-redux";
import withRouter from  "../../lib/withRouter";
import { loadData } from "../../lib/api";
import { setState } from "../../store/actions";
import { Grid, Paper, TextField, FormControl, InputLabel, Select, MenuItem, IconButton } from "@material-ui/core";
import PageCompiler from "../../common/PageCompiler";
import PageStructureList from "./PageStructureList"
import { cmpTypes } from "../../common/const"
import PageStructureButtons from "./PageStructureButtons";
import PageStructureCodeEditor from "./PageStructureCodeEditor"
import VisibilityIcon from '@material-ui/icons/Visibility';
class PageStructure extends React.Component {

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
    loadData("data/pages", "page", this.props.params.id).then(data => {
      const pageStructure = data.structure;
      const pageDatasets = { ...this.props.pageDatasets, ...data.datarows };

      if (pageStructure.length === 0) {
        pageStructure.push({
          id: 1,
          name: "Root",
          className: "",
          type: "div",
          parent: -1,
          ord: 0,
          props: {},
          style: []
        })
      }

      this.props.setState({
        selectedComponent: 1,
        pageStructure,
        pageDatasets,
        userInfo: data.user_info,
        ozState: JSON.parse(data.oz_state || "{}")
      });

    });
  }

  setStyle(key, val, idx) {

    const pageStructure = this.props.pageStructure.map(s => s.id === this.props.selectedComponent ?
      { ...s, style: idx < 0 ? [...s.style, [key, val]] : s.style.map((st, sidx) => sidx === idx ? [key, val] : st) }
      : s)
    this.props.setState({ pageStructure })
  }

  setProp(key, val) {
    const pageStructure = this.props.pageStructure.map(s => s.id === this.props.selectedComponent ?
      { ...s, props: { ...s.props, [key]: val } }
      : s)
    this.props.setState({ pageStructure })
  }

  setValue(state) {
    const pageStructure = this.props.pageStructure.map(s => s.id === this.props.selectedComponent ?
      { ...s, ...state }
      : s)
    this.props.setState({ pageStructure })
  }


  render() {

    if (this.props.hideControls) {
      return (
        <>
          <PageCompiler />
          <IconButton style={{
            top: "1vh",
            [this.props.showRight ? "right" : "left"]: "1vh",
            position: "fixed",
          }}
            onClick={() => this.props.setState({ hideControls: false })}>
            <VisibilityIcon />
          </IconButton>
        </>
      )
    }

    const cmp = this.props.pageStructure.find(s => s.id === this.props.selectedComponent);
    if (!cmp) return null;

    const ctype = cmpTypes.find(t => t.code === cmp.type)

    return (
      <>
        <PageCompiler />
        <Paper style={{
          top: "1vh",
          [this.props.showRight ? "right" : "left"]: "1vh",
          width: "500px",
          position: "fixed",
          maxHeight: "98vh",
          overflow: "auto",
          padding: "10px",
          zIndex: "999"
        }}>
          <Grid container spacing={1}>
          <Grid item xs={12}>
            <h2 style={{margin:"0px 10px"}}>
              {this.props.page.url}
            </h2>
          </Grid>            
            <PageStructureButtons />
            <Grid item xs={6} style={{maxHeight:"80vh", overflow:"auto"}}>
              <PageStructureList />
            </Grid>
            <Grid item xs={6} style={{maxHeight:"80vh", overflow:"auto"}}>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <TextField fullWidth label="Name" value={cmp.name || ""} onChange={event => this.setValue({ name: event.target.value })} />
                </Grid>
                <FormControl fullWidth style={{ marginTop: "20px" }}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={cmp.type}
                    onChange={event => this.setValue({ type: event.target.value })}
                  >
                    {
                      cmpTypes.map((t, idx) => <MenuItem key={t.code} value={t.code}>{t.name}</MenuItem>)
                    }
                  </Select>
                </FormControl>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <TextField fullWidth label="Class" value={cmp.className || ""} onChange={event => this.setValue({ className: event.target.value })} />
                </Grid>
                {
                  ctype?.props.map((key, idx) =>
                    <Grid item xs={12} key={idx}>
                      <TextField multiline fullWidth label={key[0]} value={cmp.props[key[0]] || ""} onChange={event => this.setProp(key[0], event.target.value)} />
                    </Grid>)
                }
              </Grid>
              {ctype && !ctype.noStyle &&
                <Grid container style={{
                  background: "#eee",
                  margin: "20px 0px",
                  padding: "3px"
                }}>
                  <Grid item xs={12} style={{
                    fontWeight: "bold",
                    background: "black",
                    color: "white",
                    padding: "5px",
                    fontSize: "0.8em"
                  }}>
                    Style
            </Grid>
                  {
                    cmp.style.map(([key, val], idx) =>
                      <Fragment key={idx}>
                        <Grid item xs={5}>
                          <TextField value={key} onChange={event => this.setStyle(event.target.value, val, idx)} />
                        </Grid>
                        <Grid item xs={1}>
                          <TextField value={":"} InputProps={{ disabled: true, disableUnderline: true }} />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField value={val} onChange={event => this.setStyle(key, event.target.value, idx)} />
                        </Grid>
                      </Fragment>)
                  }
                  <p style={{ cursor: "pointer" }} onClick={() => this.setStyle("", "", -1)}>Add</p>
                </Grid>
              }
            </Grid>
          </Grid>
        </Paper>
        <PageStructureCodeEditor />
      </>
    );
  }
}
PageStructure.defaultProps = {
  page: {},
  pageStructure: [],
  showRight: false,
  pageDatasets: {}
};

const mapStateToProps = state => {
  return {
    page: state.page,
    selectedComponent: state.selectedComponent,
    pageStructure: state.pageStructure,
    showRight: state.showRight,
    hideControls: state.hideControls,
    pageDatasets: state.pageDatasets
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageStructure));
