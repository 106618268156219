
import React from "react";
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import UndoIcon from '@material-ui/icons/Undo';
import {
    ListItem,
    ListItemIcon,
    IconButton,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { post } from "../../lib/api";
import { setState } from "../../store/actions";
export default function VMixListLiveConnected({ channel }) {
    const dispatch = useDispatch();

    const undoCall = (id) => {
        post("vmix/calls", { action: "update", id, status: "0", key: "" }).then(res => {
            dispatch(setState({ vmixCalls: res }))
        });
    }

    const endCall = (id) => {
        post("vmix/calls", { action: "update", id, status: "2", key: "" }).then(res => {
            dispatch(setState({ vmixCalls: res }))
        });
    }

    return (<ListItem >
        <ListItemIcon>
            <IconButton
                edge="start"
                onClick={() => undoCall(channel.call.id)}
            >
                <UndoIcon />
            </IconButton>
        </ListItemIcon>
        <ListItemText>
            <b style={{color:"#800"}}>
            {`${channel.call.caller.surname} ${channel.call.caller.name}`}
            </b>
            <br/>
            <span style={{fontSize:"0.8em"}}>{channel.vmixKey}</span>
        </ListItemText>
        <ListItemSecondaryAction>
            <IconButton
                edge="end"
                onClick={() => endCall(channel.call.id)}
            >
                <PhoneDisabledIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>);
}

