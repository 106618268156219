import React from "react";
import { connect } from "react-redux";

import { setState } from "../../store/actions";
import { post } from "../../lib/api";
import { List, ListItem, ListItemText, Divider, Grid, Paper, Button } from "@material-ui/core";

class ActionBatchPrepare extends React.Component {

    toggleSelection(ids, selMode) {
        const postData = { batch: this.props.actionBatch.id };
        if (selMode === 0) postData.add_ids = ids;
        if (selMode === 1) postData.del_ids = ids;
        if (selMode === 2) postData.add_all = true;
        if (selMode === 3) postData.del_all = true;
        post("get-action-logs", postData).then(res => {
            this.props.setState({ actionLogs: res })
        });
    }

    render() {
        const moduleName = this.props.moduleRow.name || "";
        const actionLogIds = this.props.actionLogs.map(al => al.row);
        const unselectedRows = this.props.moduleData.filter(md => actionLogIds.indexOf(md.id) < 0);
        const selectedRows = actionLogIds.map(al => this.props.moduleData.find(md => md.id === al) || {});


        const lists = [
            { data: unselectedRows, title: `Unselected ${moduleName}` },
            { data: selectedRows, title: `Selected ${moduleName}` }
        ]
        return (
            <>
                <Grid container style={{ flexGrow: 1, height: "1px", marginTop: "10px" }} spacing={2} alignItems="stretch">
                    {lists.map((l, lidx) => (
                        <Grid key={lidx} item xs={6} style={{ height: "100%" }}><Paper style={{ height: "100%", overflow: "auto" }}>
                            <h2 style={{ padding: "10px" }}>{l.title}</h2>
                            <List>
                                {l.data
                                    .map((md, idx) => (
                                        <React.Fragment key={idx}>
                                            <ListItem button onClick={() => this.toggleSelection([md.id], lidx)}>
                                                <ListItemText
                                                    primary={`${idx + 1}. ${md.name} ${md.surname}`}
                                                    secondary={`${md.email}`}
                                                />
                                            </ListItem>
                                            <Divider component="li" />
                                        </React.Fragment>
                                    ))}
                            </List>
                        </Paper></Grid>
                    ))}
                </Grid>
                <Paper style={{ marginTop: "10px", padding: "10px" }}>
                    <Button onClick={() => this.toggleSelection(null, 2)}>Select All</Button>
                    <Button onClick={() => this.toggleSelection(null, 3)}>Deselect All</Button>
                </Paper>
            </>
        );
    }
}
ActionBatchPrepare.defaultProps = {
    actionBatch: {},
    moduleRow: {},
    moduleData: [],
    actionLogs: []
};

const mapStateToProps = state => {
    return {
        actionBatch: state.actionBatch,
        moduleData: state.moduleData,
        actionLogs: state.actionLogs,
        moduleRow: state.moduleRow
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionBatchPrepare);
