import React from 'react';
import "./MsgBox.css"
import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';
import MsgBoxInfoCard from './MsgBoxInfoCard';

function getChatBoxClass(chatInfo, msg) {
    if (msg.to === chatInfo.code) return "msgbox-self";
    if (msg.new_msg) return "msgbox-other new";
    return "msgbox-other";
}

export default function MsgBox({ msg, chatInfo }) {
    if (msg.msg.type === "card") return <MsgBoxInfoCard msg={msg} chatInfo={chatInfo} />
    const senderName = msg.from === chatInfo.code ? `${chatInfo.name} ${chatInfo.surname}` : "You";
    const datetime = format(new Date(msg.ts * 1000), "dd/MM HH:mm");
    return (
        <div className={getChatBoxClass(chatInfo, msg)} >
            <div className="innerbox">
                <div className="msgbox-header">
                    {senderName}, {datetime}
                </div>
                <ReactMarkdown >{msg.msg}</ReactMarkdown>
            </div>
        </ div>
    )
}
