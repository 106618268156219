/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { connect } from "react-redux";

import { setState } from "../../../store/actions";
import {
  Paper,
  Grid,
} from "@material-ui/core";

class PlayConsoleLinkWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
  }

  render() {
    const { row, key } = this.props;
    return (
      <Paper key={key} style={{ flexGrow: 1, margin: "20px", padding: "20px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h2 style={{ margin: "10px" }}><a target="_blank" rel="noreferrer" href={row.props} style={{color:"black"}}>{row.descr}</a></h2>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

PlayConsoleLinkWidget.defaultProps = {
};

const mapStateToProps = state => {
  return Object.keys(PlayConsoleLinkWidget.defaultProps).reduce(
    (obj, key) => ({ ...obj, [key]: state[key] }),
    {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayConsoleLinkWidget);
