import React from "react";
import { connect } from "react-redux";

import { setState } from "../../../store/actions";
import { post } from "../../../lib/api";
import {
  Paper,
  Grid,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import RefreshIcon from '@material-ui/icons/Refresh';

class PlayConsoleModuleWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = { moduleData: [] };
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const { row } = this.props;
    const props = JSON.parse(row.props);
    const { module, idColumn, descrColumn } = props;
    post("get-module-data", { module }).then(res => {
      console.log({ idColumn, descrColumn, moduleData: res.data })
      const moduleData = res.data.map(row => ({
        id: row[idColumn],
        descr: descrColumn.map(d => row[d]).join(" ")
      }))
      this.setState({ moduleData })
    })


  }

  setProp(incr) {
    const { idx } = this.props;
    const curIdx = this.state.moduleData.findIndex(d => d.id === this.props.row.data)
    let newIdx = curIdx + incr;
    if (newIdx < 0) newIdx = 0;
    if (newIdx >= this.state.moduleData.length) newIdx = this.state.moduleData.length - 1;
    const newVal = this.state.moduleData[newIdx]?.id || 0;
    console.log({ newIdx, newVal, moduleData: this.state.moduleData })
    const structure = [...this.props.consoleData.structure];
    structure[idx] = { ...structure[idx], data: newVal };
    this.props.setState({ consoleData: { ...this.props.consoleData, structure } })
    post("set-console-data", { structure: structure[idx] });

  }

  setValue(newVal) {
    const { idx } = this.props;
    const structure = [...this.props.consoleData.structure];
    structure[idx] = { ...structure[idx], data: newVal };
    this.props.setState({ consoleData: { ...this.props.consoleData, structure } })
    post("set-console-data", { structure: structure[idx] });

  }

  getDescr() {
    const row = this.state.moduleData.find(d => d.id === this.props.row.data)
    if (!row) return "";
    return (row.descr)
  }


  render() {
    const { row, idx } = this.props;
    return (
      <Paper key={idx} style={{ flexGrow: 1, margin: "20px", padding: "20px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h2 style={{ margin: "10px" }}>{row.descr}:
            <IconButton
                style={{ marginLeft: "30px" }}
                // disabled={this.props.editRow.status <= 0}
                onClick={() => this.setProp(-1)}
              >
                <SkipPreviousIcon fontSize="large" />
              </IconButton>
              <Select
                style={{width: "50vw"}}
                onChange={(event) => this.setValue(event.target.value)}
                value={this.props.row.data || ""}
              >
                {this.state.moduleData.map((row, idx) =>
                  <MenuItem key={idx} value={row.id}>{row.descr}</MenuItem>
                )}
              </Select>
            <IconButton
              // disabled={this.props.editRow.status <= 0}
              onClick={() => this.setProp(1)}
            >
              <SkipNextIcon fontSize="large" />
            </IconButton>
            <IconButton
              // disabled={this.props.editRow.status <= 0}
              onClick={() => this.setProp(0)}
            >
              <RefreshIcon fontSize="large" />
            </IconButton>

            </h2>

        </Grid>
        </Grid>
      </Paper >
    )
  }
}

PlayConsoleModuleWidget.defaultProps = {
  consoleData: {}
};

const mapStateToProps = state => {
  return Object.keys(PlayConsoleModuleWidget.defaultProps).reduce(
    (obj, key) => ({ ...obj, [key]: state[key] }),
    {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayConsoleModuleWidget);
