import React from "react";
import { connect } from "react-redux";

import { setState, setStateObject } from "../store/actions";
import { buildComponent } from "./ozCompiler";

class PageCompiler extends React.Component {

    componentDidCatch(error, info) {
        console.log(info.componentStack);
    }

    setDetailStructure(pagename, structure, datarows) {
        const pageDatasets = { ...this.props.pageDatasets, ...datarows };
        const detailStructures = { ...this.props.detailStructures, [pagename]: structure };
        this.props.setState({ detailStructures, pageDatasets });
    }

    render() {
        const topCmp = this.props.pageStructure.find(p => p.parent === -1);
        return buildComponent(topCmp, 0, this.props.pageStructure, this.props.ozState, { ...this.props, setDetailStructure: (pagename, structure, datarows) => this.setDetailStructure(pagename, structure, datarows) });
    }
}

PageCompiler.defaultProps = {
    pageStructure: [],
    detailStructures: {},
    pageDatasets: {},
    ozState: {},
    userInfo: {}
};

const mapStateToProps = state => {
    return Object.keys(PageCompiler.defaultProps).reduce(
        (obj, key) => ({ ...obj, [key]: state[key] }),
        {}
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state)),
        setStateObject: (object, state) => dispatch(setStateObject(object, state)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageCompiler);
