import React from "react";
import { connect } from "react-redux";

import { setState } from "../../store/actions";
import { moduleTypes } from "../../common/const"
import DataTable from "../DataTable";
import { IconButton } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import ModuleSetup from "./ModuleSetup";
class Modules extends React.Component {


    getColumns() {
        return [
            { id: "code", label: "Code", align: "left", minWidth: "100px" },
            { id: "name", label: "Description", align: "left", minWidth: "100px" },
            { id: "module_type", label: "Type", align: "left", minWidth: "100px", type: "list", list: moduleTypes },
            { id: "settings", label: "", type: "custom", readonly: true },
        ];
    }

    openSettings(row) {
        this.props.setState({ editSettingsRow: row })
    }

    getCustomField(row, column) {
        if (column.id === "settings")
            return (
                <IconButton onClick={() => this.openSettings(row)}>
                    <SettingsIcon />
                </IconButton>
            );

        return null;
    }

    getDefaultValues(insert, row) {
        if (!insert) return;
        const moduleType = moduleTypes.find(m => m.id === row.module_type) || {};
        const setup = moduleType.setup.reduce((prev, cur) => ({ ...prev, [cur.name]: cur.default }), {})
        return { setup }
    }

    render() {
        const columns = this.getColumns();

        return (
            <>
                <DataTable
                    title="Modules"
                    singular="Module"
                    columns={columns}
                    list={this.props.modulesList}
                    getCustomField={(row, column) => this.getCustomField(row, column)}
                    listName="modulesList"
                    crud="modules"
                    searchFields={["name", "code"]}
                    extrafields={(insert, row) => this.getDefaultValues(insert, row)}
                    deletable
                />
                <ModuleSetup />
            </>
        );
    }
}
Modules.defaultProps = {
    modulesList: [],
    editSettingsRow: {}
};

const mapStateToProps = state => {
    return {
        modulesList: state.modulesList,
        editSettingsRow: state.editSettingsRow
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Modules);
