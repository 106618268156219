import React from "react";
import { connect } from "react-redux";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";

import { setState } from "../../store/actions";
import { loadData } from "../../lib/api";
import DataTable from "../DataTable";
import { IconButton } from "@material-ui/core";
import ImageComposer from "./ImageComposer";

class Images extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {
    loadData("data/images", "imagesList");
  }

  getColumns() {
    return [
      { id: "code", label: "Code", align: "left", minWidth: "100px" },
      { id: "filename", label: "File Name", align: "left", minWidth: "200px" },
      {
        id: "attachment_name",
        label: "Attachment Name",
        align: "left",
        minWidth: "200px"
      },
      { id: "structure", label: "", type: "custom", readonly: true }
    ];
  }

  openComposer(row) {
    this.props.setState({ composedImageRow: structuredClone(row) });
  }

  getCustomField(row, column) {
    if (column.id === "structure")
      return (
        <IconButton onClick={() => this.openComposer(row)}>
          <PhotoLibraryIcon />
        </IconButton>
      );

    return null;
  }

  render() {
    const columns = this.getColumns();

    return (
      <>
        <DataTable
          title="Images"
          singular="Image"
          columns={columns}
          list={this.props.imagesList}
          getCustomField={(row, column) => this.getCustomField(row, column)}
          listName="imagesList"
          crud="images"
          searchFields={["code", "filename", "attachment_name"]}
          deletable
        />
        <ImageComposer />
      </>
    );
  }
}
Images.defaultProps = {
  imagesList: [],
  composedImageRow: {}
};

const mapStateToProps = state => {
  return {
    imagesList: state.imagesList,
    composedImageRow: state.composedImageRow
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Images);
