import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import LiveTvIcon from '@material-ui/icons/LiveTv';
import PollIcon from "@material-ui/icons/Poll";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ForumIcon from '@material-ui/icons/Forum';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PermCameraMicIcon from '@material-ui/icons/PermCameraMic';

export const cmpTypes = [
    { name: "Subpage", code: "page", props: [["pagename", "txt"]], noStyle: true, noDetails: true },
    { name: "Container", code: "container", props: [] },
    { name: "Div", code: "div", props: [["clickAction", "txt"]] },
    { name: "HTML Element", code: "html", props: [["clickAction", "txt"], ["element", "txt"], ["props", "txt"]] },
    { name: "Paper", code: "paper", props: [] },
    { name: "Grid Container", code: "grid-container", props: [["spacing", "number"]] },
    { name: "Grid Item", code: "grid-item", props: [["xs", "number"], ["sm", "number"], ["md", "number"], ["lg", "number"], ["xl", "number"]] },
    { name: "List", code: "list", props: [] },
    { name: "Select", code: "select", props: [["fieldname", "txt"], ["label", "txt"], ["fullWidth", "boolean"], ["variant", "txt"], ["size", "txt"], ["options", "txt"], ["optionsVal", "txt"], ["optionsDescr", "txt"], ["optionsNull", "boolean"], ["nullDescr", "txt"], ["disabled", "boolean"]] },
    { name: "Slider", code: "slider", props: [["fieldnameFrom", "txt"], ["fieldnameTo", "txt"], ["min", "txt"], ["max", "txt"], ["labels", "txt"], ["size", "txt"],  ["disabled", "boolean"], ["valueLabelDisplay", "txt"]] },
    { name: "List Item", code: "list-item", props: [["clickAction", "txt"], ["withoutItem", "boolean"]] },
    { name: "List Item Text", code: "list-item-text", props: [["primary", "txt"], ["secondary", "txt"]], noDetails: true },
    { name: "List Item Avatar", code: "list-item-avatar", props: [] },
    { name: "Menu", code: "menu", props: [["options", "txt"]] },
    { name: "Image", code: "img", props: [["src", "txt"]], noDetails: true },
    { name: "Text", code: "txt", props: [["text", "txt"]], noStyle: true, noDetails: true },
    { name: "MarkDown Text", code: "md-txt", props: [["text", "txt"], ["hrefBlank", "txt"]], noDetails: true },
    { name: "Date/Time", code: "ts", props: [["ts", "txt"], ["format", "txt"]], noDetails: true },
    { name: "Repeater", code: "repeater", props: [["formula", "txt"], ["alias", "txt"], ["filter", "boolean"]], noStyle: true },
    { name: "Alias", code: "alias", props: [["value", "txt"], ["alias", "txt"]], noStyle: true },
    { name: "If", code: "if", props: [["value1", "txt"], ["value2", "txt"], ["isNotEqual", "boolean"]], noStyle: true },
    { name: "Button Group", code: "button-group", props: [["fullWidth", "boolean"], ["color", "txt"], ["variant", "txt"]] },
    { name: "Button", code: "button", props: [["fullWidth", "boolean"], ["primary", "boolean"], ["secondary", "boolean"], ["disabled", "boolean"], ["clickAction", "txt"], ["href", "txt"], ["target", "txt"], ["variant", "txt"]] },
    { name: "Text Input", code: "input", props: [["fieldname", "txt"], ["label", "txt"], ["fullWidth", "boolean"], ["multiline", "boolean"], ["variant", "txt"], ["size", "txt"],  ["type", "txt"], ["shrink", "txt"], ["disabled", "boolean"], ["startAdornment", "txt"], ["emptyValue", "txt"]], noDetails: true },
    { name: "Checkbox", code: "checkbox", props: [["fieldname", "txt"], ["disabled", "boolean"], ["color", "txt"], ["trueValue", "txt"]] },
    { name: "Link", code: "link", props: [["clickAction", "txt"], ["href", "txt"], ["target", "txt"]] },
    { name: "Badge", code: "badge", props: [["value", "txt"], ["invisible", "boolean"]] },
    { name: "Avatar", code: "avatar", props: [["firstLetter", "txt"], ["secondLetter", "txt"]] },
    { name: "Table", code: "table", props: [] },
    { name: "TableRow", code: "table-row", props: [] },
    { name: "TableCell", code: "table-cell", props: [] },
    { name: "Dialog", code: "dialog", props: [["title", "txt"], ["fullWidth", "boolean"], ["openField", "txt"], ["autoClose", "boolean"], ["maxWidth", "txt"]] },
    { name: "CookiesBanner", code: "cookies-banner", props: [] },
    { name: "LiveStream", code: "live-stream", props: [["code", "txt"], ["fullScreen", "boolean"], ["background", "boolean"]] },
    { name: "Zoom Meeting", code: "zoom-meeting", props: [["meetingNumber", "txt"]] },
    { name: "Twilio Meeting", code: "twilio-meeting", props: [["meetingNumber", "txt"]] },
    { name: "Cake", code: "cake", props: [] },
    { name: "ChatBox", code: "chat-box", props: [["user", "txt"]] },
    { name: "FirebaseChatBox", code: "fb-chat-box", props: [["user", "txt"]] },
    { name: "FirebaseChatAddContactBtn", code: "fb-chat-add-contact-btn", props: [] },
    { name: "CountDown", code: "countdown", props: [["utmTimestamp", "txt"]] },
    { name: "Twitter Tweet", code: "twitter-tweet", props: [["tweetId", "txt"]], noDetails: true },
    { name: "Facebook Provider", code: "facebook-provider", props: [["appId", "txt"]] },
    { name: "Facebook Post", code: "facebook-post", props: [["href", "txt"]], noDetails: true },
    { name: "File Upload", code: "file-upload", props: [["firebaseAddress", "txt"], ["filenameProp", "txt"], ["sizeLimit", "txt"]] },
    { name: "Google Analytics", code: "google-analytics", props: [["log", "txt"], ["options", "txt"]] },
    { name: "vMix Call", code: "vmix-call", props: [["vmixKey", "txt"], ["vmixName", "txt"]] },
    { name: "Map", code: "map", props: [["lat", "txt"], ["lng", "txt"], ["zoom", "txt"], ["mapStyles", "txt"]] },
    { name: "Map Marker", code: "map-marker", props: [["lat", "txt"], ["lng", "txt"], ["title", "txt"], ["icon", "txt"]], noDetails: true }
]

const dttSetup = [
    { name: "fields", title: "Field List", type: "fieldlist", singular: "Field", default: [] }
]

const pplSetup = [
    {
        name: "fields", title: "Field List", type: "fieldlist", singular: "Field", default: [
            { name: "name", descr: "Name", searchField: true, readonly: true },
            { name: "surname", descr: "Surname", searchField: true, readonly: true },
            { name: "email", descr: "Email", searchField: true, readonly: true },
        ]
    },
    { name: "actions", title: "Action List", type: "actionlist", singular: "Action" }
]

const lvsSetup = [
    {
        name: "fields", title: "Field List", type: "fieldlist", singular: "Field", default: [
            { name: "code", descr: "Code", searchField: true, readonly: true },
            {
                name: "provider", descr: "Provider", searchField: false, readonly: true, type: "list", typeProps: {
                    list: [
                        { id: "vimeo", descr: "Vimeo" },
                        { id: "youtube", descr: "Youtube" },
                        { id: "dacast", descr: "Dacast" }
                    ]
                }
            },
            { name: "videoID", descr: "Video ID", searchField: false, readonly: true },
        ]
    }
]

const viwSetup = [
    { name: "fields", title: "Field List", type: "fieldlist", singular: "Field", default: [] }
]


const chtSetup = [
    {
        name: "fields", title: "Field List", type: "fieldlist", singular: "Field", default: [
            { name: "user", descr: "User", type: "user", searchField: true, readonly: true },
            { name: "message", descr: "Message", searchField: true },
            { name: "datetime", descr: "Date/Time", type: "datetime", searchField: false, readonly: true },
            {
                name: "status", descr: "Status", searchField: false, readonly: true, type: "list", typeProps: {
                    list: [
                        { id: 0, descr: "Rejected" },
                        { id: 1, descr: "New" },
                        { id: 2, descr: "Approved" },
                        { id: 3, descr: "Completed" },
                    ]
                }
            },
        ]
    }
]

const zomSetup = [
    {
        name: "fields", title: "Field List", type: "fieldlist", singular: "Field", default: [
            { name: "code", descr: "Code", searchField: true, readonly: true },
            { name: "meetingNumber", descr: "Meeting Number", searchField: true, readonly: true },
            { name: "apiKey", descr: "Api Key", searchField: true, readonly: true },
            { name: "apiSecret", descr: "Api Secret", searchField: true, readonly: true },
            { name: "passWord", descr: "Password", searchField: true, readonly: true }
        ]
    }
]

const twlSetup = [
    {
        name: "fields", title: "Field List", type: "fieldlist", singular: "Field", default: [
            { name: "code", descr: "Code", searchField: true, readonly: true },
            { name: "accountID", descr: "Accound SID", searchField: true, readonly: true },
            { name: "apiKey", descr: "Api Key", searchField: true, readonly: true },
            { name: "apiSecret", descr: "Api Secret", searchField: true, readonly: true }
        ]
    }
]

export const moduleTypes = [
    { descr: "DataTable", id: "dtt", icon: <FormatListBulletedIcon />, setup: dttSetup },
    { descr: "People", id: "ppl", icon: <PeopleIcon />, setup: pplSetup },
    { descr: "Livestream", id: "lvs", icon: <LiveTvIcon />, setup: lvsSetup },
    { descr: "Poll", id: "pll", props: [], icon: <PollIcon />, setup: [] },
    { descr: "Chat", id: "cht", props: [], icon: <ForumIcon />, setup: chtSetup },
    { descr: "DataView", id: "viw", props: [], icon: <ListAltIcon />, setup: viwSetup },
    { descr: "Zoom Meetings", id: "zom", props: [], icon: <PermCameraMicIcon />, setup: zomSetup },
    { descr: "Twilio Meetings", id: "twl", props: [], icon: <PermCameraMicIcon />, setup: twlSetup }
]

export const playConsoleWidgets = [
    { descr: "Live Stream", id: "livestream", propshint: "Stream Code" },
    { descr: "Button Prop", id: "btnprop", propshint: `[{"id":"descr"},...]` },
    { descr: "Text Prop", id: "txtprop", propshint: `Property Name` },
    { descr: "Link", id: "link", propshint: `Link Address` },
    { descr: "Switch Prop", id: "switchprop", propshint: `Property Name` },
    { descr: "Module List", id: "module", propshint: `{dataset,idColumn,descrColumn}` },
]