import React from 'react';
import "./MsgBox.css"
import ReactMarkdown from 'react-markdown';

export default function MsgBox({ msg, chatInfo }) {
    const senderName = msg.myMsg ? "You" : `${chatInfo.name} ${chatInfo.surname}`;
    return (
        <div className={msg.myMsg ? "msgbox-self" : "msgbox-other"} >
            <div className="innerbox">
                <div className="msgbox-header">
                    {senderName} wrote:
            </div>
                <ReactMarkdown >{msg.msg}</ReactMarkdown>
            </div>
        </ div>
    )
}
