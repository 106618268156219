import { Button, Menu, MenuItem } from '@material-ui/core';
import React from 'react';

export default function OzMenu({ cmpProps, optionsArray, detailCmp, callback }) {
    let { className, style } = cmpProps;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (option) => {
        console.log(option);
        if (option.action) callback(JSON.stringify(option.action));
        setAnchorEl(null);
    };

    return <>
        <Button style={style} className={className} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            {detailCmp}
        </Button>
        {optionsArray.length > 0 &&
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    optionsArray.map((option, key) =>
                        <MenuItem component='a' href={option.href} key={key} onClick={() => handleSelect(option)}>{option.title}</MenuItem>
                    )
                }
            </Menu>
        }
    </>;
}