import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TwilioVideoChatAsk from './TwilioVideoChatAsk';
import TwilioVideoChatPlay from './TwilioVideoChatPlay';
import Video from 'twilio-video';
import { setState } from '../../store/actions';

export default function TwilioVideoChat() {
    const dispatch = useDispatch();

    const [room, setRoom] = useState(null);
    const [roomConnected, setRoomConnected] = useState(false);

    const userInfo = useSelector(({ userInfo }) => userInfo) || {};
    const chatUsers = useSelector(({ pageDatasets }) => pageDatasets?.chatUsers) || [];
    const videoCall = useSelector(({ pageDatasets }) => pageDatasets?.videoCall);
    const acceptedVideoCallContact = useSelector(({ acceptedVideoCallContact }) => acceptedVideoCallContact);

    useEffect(() => {
        function connect() {
            Video.connect(videoCall.token, {
                name: videoCall.room_name
            }).then(room => {
                setRoom(room);
            });
        }

        function disconnect() {
            if (room) {
                room.localParticipant.tracks.forEach(t => {
                    t.track.stop();
                    t.track.disable();
                })
                room.disconnect();
                setRoom(null);                
            }
            dispatch(setState({acceptedVideoCallContact: null }))
        }

        const tobeConnected = Boolean(videoCall?.accepted) && acceptedVideoCallContact === videoCall.contact;
        if (tobeConnected !== roomConnected) {
            setRoomConnected(tobeConnected);
            if (tobeConnected)
                connect();
            else
                disconnect();
        }
    }, [videoCall, roomConnected, room, acceptedVideoCallContact, dispatch]);

    const chatInfo = chatUsers.find(u => u.code === videoCall?.contact) || {};

    let title = `${chatInfo.name} ${chatInfo.surname}`;
    if (!videoCall?.accepted) title += " invited you to join a video call";

    return <Dialog fullWidth={videoCall?.accepted} maxWidth="sm" open={Boolean(videoCall)} >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {videoCall &&
                (videoCall?.accepted && acceptedVideoCallContact === videoCall.contact ?
                    <TwilioVideoChatPlay room={room} userInfo={userInfo} chatInfo={chatInfo} /> 
                :
                    <TwilioVideoChatAsk userInfo={userInfo} chatInfo={chatInfo} videoCall={videoCall} />
                )
            }
        </DialogContent>
    </Dialog>
}