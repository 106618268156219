import React from "react";
import { Container, Paper, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import ForwardIcon from '@material-ui/icons/Forward';

export default function ServerAdmin() {
    const menu = [
        { title: "Add Server on Hetzner", code: "add-hetzner-server" },
        { title: "Set Domain on Hetzner", code: "set-hetzner-domain" },
        { title: "Resize Server on Hetzner", code: "resize-hetzner-server" },
        { title: "Restart Services", code: "restart-services" },
        { title: "Update Application (GIT)", code: "update-app" },
        { title: "Rename Domain", code: "rename-domain" },

    ]

    return <Container>
        <Paper style={{ margin: "30px auto", maxWidth: "600px", minHeight: "100px", padding: "20px", textAlign: "center" }}>
        <Grid container>
                <Grid item xs={6} style={{ textAlign: "left", padding:"20px" }}>
                    <Typography variant="h5">Server Setup</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                    <img src="/img/onezero.svg" alt="" style={{ maxWidth: "200px", width: "100%", height: "100%", marginBottom: "10px" }} />
                </Grid>
            </Grid>
            <List>
                {menu.map((m, idx) =>
                    <ListItem component="a" href={`/ozserver/${m.code}`} button key={idx}>
                        <ListItemIcon>
                            <ForwardIcon />
                        </ListItemIcon>
                        <ListItemText>
                            {m.title}
                        </ListItemText>
                    </ListItem>)}
            </List>
        </Paper>
    </Container>;
}