import React from "react";
import { connect } from "react-redux";
import { formatDate } from "../../lib/api";

import { setState } from "../../store/actions";
import { CircularProgress, Table, TableRow, TableCell, Paper } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

class ActionBatchResults extends React.Component {


    getStatusIcon(log) {
        if (log.id === this.props.execID) return <CircularProgress size={24} />
        if (log.status === "ok") return <CheckIcon style={{ color: "#080" }} />
        if (log.status === "error") return <ErrorIcon style={{ color: "#f00" }} />
        return <MenuOpenIcon />

    }

    getResultDescr() {
        const resultCnt = this.props.actionLogs.reduce((prev, log) => {
            let res = 0;
            if (log.status === "ok") res = 1;
            if (log.status === "error") res = 2;
            prev[res]++;
            return prev;
        }, ([0, 0, 0]));
        const resultLabels = ["Pending", "Completed", "Errors"];
        return resultCnt
            .map((cnt, idx) => ({ cnt, lbl: resultLabels[idx] }))
            .filter(r => r.cnt > 0)
            .map(r => `${r.cnt} ${r.lbl}`)
            .join(", ")
    }

    render() {
        const selectedRows = this.props.actionLogs.map(log => ({ ...log, row: this.props.moduleData.find(md => md.id === log.row) || {} }));


        return (
            <Paper style={{ flexGrow: 1, marginTop: "10px", overflow: "auto" }}>
                <h3 style={{ paddingLeft: "30px" }}>{this.getResultDescr()}</h3>
                <Table>
                    {selectedRows
                        .map((log, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{this.getStatusIcon(log)}</TableCell>
                                <TableCell>{log.row.name}</TableCell>
                                <TableCell>{log.row.surname}</TableCell>
                                <TableCell>{log.row.email}</TableCell>
                                <TableCell>{formatDate(log.ts)}</TableCell>
                                <TableCell>{log.response}</TableCell>
                            </TableRow>
                        ))}
                </Table>
            </Paper>
        );
    }
}
ActionBatchResults.defaultProps = {
    moduleRow: {},
    moduleData: [],
    actionLogs: []
};

const mapStateToProps = state => {
    return {
        moduleData: state.moduleData,
        actionLogs: state.actionLogs,
        moduleRow: state.moduleRow,
        execID: state.execID
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionBatchResults);
