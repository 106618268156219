import React from "react";
import { connect } from "react-redux";
import { loadData } from "../../lib/api";
import BuildIcon from '@material-ui/icons/Build';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { setState } from "../../store/actions";
import DataTable from "../DataTable";
import { IconButton } from "@material-ui/core";

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() {
    loadData("data/pages", "pagesList");
  }

  getColumns() {
    const pageTypes = [
      { id: "public", descr: "Public Page" },
      { id: "private", descr: "Private Page" },
      { id: "admin", descr: "Admin Page" },
      { id: "subpage", descr: "Subpage" }
    ]
    return [
      { id: "url", label: "Address", align: "left", minWidth: "100px" },
      { id: "pagetype", label: "Page Type", align: "left", minWidth: "100px", type: "list", list: pageTypes },
      { id: "datasets", label: "Datasets", align: "left", minWidth: "100px" },
      { id: "oz_state", label: "Default State", align: "left", minWidth: "100px" },
      { id: "test_user", label: "Test User", align: "left", minWidth: "100px" },
      { id: "structure", label: "", type: "custom", readonly: true },
      { id: "open", label: "", type: "custom", readonly: true }
    ];
  }

  getCustomField(row, column) {
    if (column.id === "structure")
      return (
        <IconButton
          component="a"
          href={`/ozadmin/pages/${row["id"]}`}
        >
          <BuildIcon />
        </IconButton>
      );

    if (column.id === "open") {
      if (row["pagetype"] === "subpage") return null;
      if (row["pagetype"] === "private" && !row["test_user"]) return null;

      let href = `/${row["url"] === "/" ? "" : row["url"]}`;
      if (row["pagetype"] === "private") href += `/${row["test_user"]}`;
      return (
        <IconButton
          component="a"
          href={href}
          target="_blank"
          rel="noreferrer">
          <OndemandVideoIcon />
        </IconButton>
      );
    }

    return null;
  }

  render() {
    const columns = this.getColumns();

    return (
      <DataTable
        title="Pages"
        singular="Page"
        columns={columns}
        list={this.props.pagesList}
        getCustomField={(row, column) => this.getCustomField(row, column)}
        listName="pagesList"
        crud="pages"
        searchFields={["url"]}
        deletable
      />
    );
  }
}
Pages.defaultProps = {
  pagesList: []
};

const mapStateToProps = state => {
  return {
    pagesList: state.pagesList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
