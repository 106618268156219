import React, { useState, useRef } from "react";
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import QRCode from "qrcode";

export default function SetupQR() {
  const [command, setCommand] = useState("");
  const [commandTxt, setCommandTxt] = useState("");
  const [isPostData, setIsPostData] = useState(false);
  const [imgFile, setImgFile] = useState("/files/img/scanner.png");

  const canvasRef = useRef();

  const cmdList = [
    { code: "checkin", descr: "Check In" },
    { code: "gate", descr: "Check In/Out" },
    { code: "exit", descr: "Check Out All" },
    { code: "other", descr: "Other" },
  ];

  let cmd = "";
  if (command === "other") {
    cmd = commandTxt;
  } else {
    cmd = command;
    if (commandTxt) cmd += `_${commandTxt}`;
  }

  cmd += isPostData ? "@" : "/";

  const exportQR = () => {
    const domain = window.location.origin;
    const qrString = `SETUP->>${domain}/api/exec/${cmd}->>${domain}${imgFile}->>apg:0n3Z3r0!`;
    console.log(qrString);
    QRCode.toCanvas(
      canvasRef.current,
      qrString,
      (error) => error && console.error(error)
    );
  };

  const printQR = () => {
    exportQR();
    const domain = window.location.origin.split("://")[1];
    let cmdDescr = "";
    if (command === "other") {
      cmdDescr = commandTxt;
    } else {
      cmdDescr = cmdList.find((c) => c.code === command)?.descr;
      if (commandTxt) cmdDescr += ` (${commandTxt})`;
    }

    const source = `<html><head><script>function step1(){setTimeout('step2()', 10);}    
    function step2(){window.print();window.close()}
    </script></head><body onload='step1()' style="text-align:center">
    <p><b>${domain}</b></p>
    <img src='${canvasRef.current.toDataURL()}' />
    <p><b>${cmdDescr}</b></p>    
    </body></html>`;
    var pwa = window.open("about:blank", "_new");
    pwa.document.open();
    pwa.document.write(source);
    pwa.document.close();
  };

  return (
    <Grid container spacing={2} style={{ maxWidth: "700px" }}>
      <Grid item xs={5}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Command</InputLabel>
          <Select
            label={`Command`}
            value={command}
            onChange={(event) => setCommand(event.target.value)}
          >
            {cmdList.map(({ code, descr }) => (
              <MenuItem key={`listitem_${code}`} value={code}>
                {descr}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <TextField
          value={commandTxt}
          onChange={(e) => {
            setCommandTxt(e.target.value);
          }}
          label={command === "other" ? "Command Text" : "Room"}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={imgFile}
          onChange={(e) => {
            setImgFile(e.target.value);
          }}
          label="Image File"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={isPostData}
              onChange={(e) => setIsPostData(e.target.checked)}
              color="primary"
            />
          }
          label="Post data on request"
        />
      </Grid>

      {cmd && (
        <Grid item xs={4}>
          <Button
            onClick={exportQR}
            size="large"
            variant="contained"
            color="primary"
            style={{ marginRight: "10px" }}
          >
            Export
          </Button>
          <Button
            onClick={printQR}
            size="large"
            variant="contained"
            color="primary"
          >
            Print
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <canvas key={cmd} ref={canvasRef} />
      </Grid>
    </Grid>
  );
}
