import { useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/analytics';

export default function GoogleAnalytics({ cmpProps }) {
    const { log, options } = cmpProps;
    const optionObj = JSON.parse(options);
    useEffect(() => {
        firebase.analytics().logEvent(log, optionObj);
    }, [log, optionObj]);
    return null;
}