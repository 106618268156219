import React, { useEffect, useState } from "react";
import { Box, Paper, Button } from "@material-ui/core";
import SetupEmail from "./SetupEmail";
import { loadData } from "../../lib/api";
import SetupSettings from "./SetupSettings";
import SetupBackups from "./SetupBackups";
import SetupQR from "./SetupQR";
import { useSelector } from "react-redux";
import SetupLoadBackup from "./SetupLoadBackup";

export default function Setup() {
    const options = ["settings", "email", "file manager", "backups", "QR Setup"];
    const [selOption, setSelOption] = useState(0);
    const [dataLoaded, setDataLoaded] = useState(false);
    const setupData = useSelector(({ setupData }) => setupData);

    useEffect(() => {
        loadData("setup", "setupData").then(() => { setDataLoaded(true) });
    }, []);

    if(!dataLoaded)return null;

    return <Paper style={{ display: "flex", flexDirection: "column", flexGrow: 1, margin: "20px", overflow: "hidden" }}>
        <h2 style={{ padding: "10px 20px 0px" }}>
            Project Setup
        </h2>
        <Box p={2}>
            {options.map((o, idx) =>
                <Button
                    style={{ marginRight: "5px" }}
                    variant={idx === selOption && "contained"}
                    color={idx === selOption ? "primary" : "default"}
                    onClick={() => setSelOption(idx)} key={idx}
                >{o}</Button>
            )}
        </Box>
        <Box p={2}>
            {selOption === 0 && <SetupSettings />}
            {selOption === 1 && <SetupEmail />}
            {selOption === 3 && setupData?.emptyProject &&  <SetupLoadBackup />}
            {selOption === 3 && !setupData?.emptyProject &&  <SetupBackups />}
            {selOption === 4 &&  <SetupQR />}

        </Box>
    </Paper>
}